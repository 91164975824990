import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PasswordResetConfirmMessageComponent } from './password-reset-confirm-message/password-reset-confirm-message.component';
import { PasswordResetConfirmComponent } from './password-reset-confirm/password-reset-confirm.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'reestablecer-clave',
    component: PasswordResetComponent,
  },
  {
    path: 'reestablecer-clave-confirmado',
    component: PasswordResetConfirmMessageComponent,
  },
  {
    path: 'reestablecer-clave-confirmar',
    component: PasswordResetConfirmComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
