export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCGeWFe05aZ0noFbr-EARrVklKtlg3smWU",
    authDomain: "walking-way-464a6.firebaseapp.com",
    projectId: "walking-way-464a6",
    storageBucket: "walking-way-464a6.appspot.com",
    messagingSenderId: "773227268325",
    appId: "1:773227268325:web:78bbfc46a8bb79cec6ec0c",
    measurementId: "G-KK45NYBMLZ"
  },
  googleMapConfig:{
    apiKey: "AIzaSyCGeWFe05aZ0noFbr-EARrVklKtlg3smWU",
  },
  cloud: {
    path: 'https://us-central1-walking-way-464a6.cloudfunctions.net/',
    newUser: 'users-newUser',
    createOrder: 'orders-create',
  }
};
