import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { base64ToFile, ImageCroppedEvent, ImageTransform, LoadedImage } from 'ngx-image-cropper';
import { USER_UPLOAD_IMAGE_NOT_FORMAT } from '../../../constants/user_messages';
import { notifyMessage } from '../../../notifications/notifications';
import { ImageCropperService } from '../../../services/image-cropper.service';

@Component({
  selector: 'app-image-drag-and-drog',
  templateUrl: './image-drag-and-drog.component.html',
  styleUrls: ['./image-drag-and-drog.component.scss']
})

export class ImageDragAndDrogComponent implements OnInit {
  @Input() key: string = '';
  @Input() title: string = '';
  @Input() roundCropper: boolean = true;
  @Input() cropperStaticWidth: number;
  @Input() cropperStaticHeight: number;


  @Output() sendCropped = new EventEmitter<any>();

  croppedImage: any = '';
  error: string;
  dragAreaClass: string;
  imageChangedEvent: any = '';
  file: FileList;
  transform: ImageTransform = {};
  scale = 1;
  viewZoom: Boolean = false;

  constructor(private cropperService: ImageCropperService) { }

  ngOnInit() {
    this.dragAreaClass = "dragarea";
  }

  onFileChange(event: any) {
    this.file = event.target.files;
    this.saveFiles(this.file);
    this.viewZoom = true;
  }
  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
      this.viewZoom = true
    }
  }

  saveFiles(files: FileList) {
    if (files.length > 1) this.error = "Only one file at time allow";
    else {
      this.error = "";
      //console.log(files[0].size, files[0].name, files[0].type);
    }
  }
  allowDrop(event: any) {
    event.preventDefault();
  }
  dropHandler(event: any) {
    this.imageChangedEvent = { target: { files: [event.dataTransfer.files[0]] } }
    this.viewZoom = true
  }
  fileChangeEvent(event: any): void {
    this.cropperService.userCroppedImage = true;
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    let obj = {};
    let date = Date.now();
    this.croppedImage = event.base64;
    this.cropperService.userImageCropped = base64ToFile(this.croppedImage)
    obj[this.key] = {img:this.croppedImage, date}
    this.sendCropped.emit(obj);
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  resetImage() {
    this.scale = 1;
    this.transform = {};
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    notifyMessage('error', USER_UPLOAD_IMAGE_NOT_FORMAT, 'top-right')
    this.cropperService.userCroppedImage = false
  }

}
