import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';


export function dateValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }

        let today = new Date().getFullYear()
        let birthday = new Date(value).getFullYear()
        let age = today - birthday
        if (age < 18) {
            return { "dateValid": true };
        }

        return null;
    }
}
