export const TYPE_VEHICLES = {
  truck: {
    name: "Camión",
    index: "truck",
  },
  auto: {
    name: "Auto",
    index: "auto",
    types: [
      { index: "sedan", value: "Sedan", checked: true },
      { index: "suv", value: "SUV", checked: false },
      { index: "pickup", value: "Pickup", checked: false },
    ],
  },
  motocycle: {
    name: "Moto",
    index: "motocycle",
    types: [{ index: "motocycle", value: "Moto", checked: true }],
  },
};

export const TYPE_PHOTO_CAR = {
  frontVehicle: { index: 'frontVehicle', value: 'Foto frontal del vehículo' },
  frontLicensePlate: { index: 'frontLicensePlate', value: 'Foto frontal de la matrícula' },
  backLicensePlate: { index: 'backLicensePlate', value: 'Foto posterior de la matrícula' },
}
