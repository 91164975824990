export interface PaymentTypes{
  name: string,
  value: string,

}

export const PAYMENT_TYPE: PaymentTypes[] = [
  {
    name: 'CASH',
    value: 'Efectivo'
  },
  {
    name: 'TC',
    value: 'Tarjeta de Crédito'
  },
  {
    name: 'WALLET',
    value: 'Way Wallet'
  }
]

export const PAYMENT_TYPE_ = {
  cash: { index: 'cash', value: 'Efectivo' },
  tc: { index: 'tc', value: 'Tarjeta de Crédito' },
  wallet: { index: 'wallet', value: 'Way Wallet' },
  transfer: { index: 'transfer', value: 'Transferencia' }
}

export const STATUS_PAYMENTS = {
  pendingToApprove: {index: 'pendingToApprove', value: 'Pendiente por aprobar', color: 'warning'},
  approved: {index: 'approved', value: 'Aprobado', color: 'success'},
  rejected: {index: 'rejected', value: 'Rechazado', color: 'danger'},
}