import { Component } from '@angular/core';

import { OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { Order } from '../../../interfaces/order';
import { OrderService } from '../../../services/order.service';

interface Point {
  lat: number;
  lng: number;
}

@Component({
  selector: 'order-orders-map',
  templateUrl: './order-map.component.html'
})
export class OrdersMapComponent implements OnInit {


  orders$: Observable<Order[]>;
  constructor(public fs: AngularFireAuth, public orderService: OrderService) { }

  ngOnInit() {
    this.orders$ = this.orderService.ordersActive$;
    this.orders$.pipe(
      distinctUntilChanged(),

    ).subscribe()
    //this.orderService.orderCreate2();

    // interval(2000).pipe(
    //   tap(v => console.log("DATABASE CHECK ", v))
    // )
    // let i = 0;
    // interval(2000).pipe(
    //   distinctUntilChanged(),
    //   takeWhile((v) => i < this.tmpPointsFirst.length)
    // ).subscribe(() => {
    //   const pos = this.tmpPointsFirst[i];
    //   this.pointsOrderA.push(pos);
    //   this.currentPos = pos;
    //   i++;
    // })
  }

  currentPos: Point = {
    lat: -0.1051397,
    lng: -78.47303
  }
}
