import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserIndexComponent } from './user-index/user-index.component';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Usuarios' },
    children: [
      {
        path: '',
        data: { title: 'Tabla de usuarios' },
        component: UserIndexComponent,
      },
      {
        path: 'detalle/:uid',
        data: { title: 'Detalle usuario' },
        component: UserDetailComponent
      },
      {
        path: 'crear',
        data: { title: 'Nuevo usuario' },
        component: UserCreateComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule { }
