import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserGenericServiceService {

  constructor(private fs: AngularFirestore) { }

  async setObservation(uid: string, object:Object, document: string): Promise<any> {
    let accepted: boolean;
    let error: any;
    try {
      await this.fs
          .collection("users")
          .doc(uid)
          .collection("detail")
          .doc(document)
          .set({"observations": {[Date.now()]:object}}, { merge: true});

    } catch (e) {
      error = e;
    } finally {
      if (error === undefined) {
        accepted = true;
      } else {
        accepted = false;
      }
    }
    return {
      accepted,
      error
    };
  }
}
