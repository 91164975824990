import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { USER_UPLOAD_ERROR, USER_UPLOAD_SUCCESS } from '../../../constants/user_messages';
import { User } from '../../../interfaces/user';
import { notifyMessage } from '../../../notifications/notifications';
import { UserService } from '../../../services/user.service';
import { dateValid } from '../../validators/date';

@Component({
  selector: 'user-user-edit',
  templateUrl: './user-edit.component.html',
})
export class UserEditComponent implements OnInit {
  userEditForm: FormGroup;
  userEditLoading: boolean = false;
  user: User;

  constructor(public bsModalRef: BsModalRef,
    private formbuilder: FormBuilder,
    private userService: UserService) {
  }

  ngOnInit(): void {
    this.userEditForm = this.formbuilder.group({
      name: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      birthday: [new Date(1995, 11, 17).toISOString().substring(0, 10), [Validators.required, dateValid()]],
      gender: [true, [Validators.required]],
      identification: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
    }, { updateOn: 'blur' });
    this.userEditObtainData();
  }

  userEditObtainData() {
    this.userEditForm.setValue({
      name: this.user.name,
      lastname: this.user.lastname,
      identification: this.user.identification,
      email: this.user.email,
      birthday: this.user.birthday,
      phone: this.user.phone,
      gender: this.user.gender
    })
  }

  async userUpdate() {
    if (!this.userEditForm.touched) {
      notifyMessage('error', 'No se modificó campo alguno.', 'top-end');
      return false;
    }
    const { isConfirmed } = await Swal.fire({
      title: '¿Estás seguro de actualizar datos del usuario?',
      text: "No podrás revertir esta acción.!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro!',
      cancelButtonText: 'Cancelar'
    })
    if (!isConfirmed) return false;
    this.userEditLoading = true;
    if (this.userEditForm.invalid) return false;

    let userData = {
      name: this.userEditForm.get('name')?.value,
      lastname: this.userEditForm.get('lastname')?.value,
      birthday: this.userEditForm.get('birthday').value,
      phone: this.userEditForm.get('phone')?.value,
      gender: this.userEditForm.get('gender')?.value,
      active: true,
    }
    const { accepted } = await this.userService.userUpdate(this.user.uid, userData)
    if (!accepted) {
      notifyMessage('error', USER_UPLOAD_ERROR, 'top-end')
      return false;
    }
    this.userEditLoading = false;
    await notifyMessage('success', USER_UPLOAD_SUCCESS, 'top-end')
    this.bsModalRef.hide()
  }

  get name() { return this.userEditForm.get('name') };
  get lastname() { return this.userEditForm.get('lastname') };
  get email() { return this.userEditForm.get('email') };
  get phone() { return this.userEditForm.get('phone') };
  get identification() { return this.userEditForm.get('identification') };
  get birthday() { return this.userEditForm.get('birthday') };
  get gender() { return this.userEditForm.get('gender') };
}
