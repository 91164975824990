import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SupervisorRoutingModule } from './supervisor.routing.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SharedModule } from '../shared/shared.module';
import { SupervisorDetailIndex } from './detail/supervisor-detail-index/supervisor-table-index.component';

var components = [SupervisorDetailIndex]
@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    SharedModule,
    ModalModule.forRoot(),
    SupervisorRoutingModule
  ],
  exports: [components]
})
export class SupervisorModule { }
