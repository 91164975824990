import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'
import { ClientModel, User, UserModel } from '../interfaces/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { Client } from '../interfaces/client';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { OrderModel } from '../interfaces/order';
import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  users$: Observable<User[]>;
  userDetail$: Observable<User>;

  private user = new BehaviorSubject( {} as UserModel );
  userSelected = this.user.asObservable();

  private userCollection: AngularFirestoreCollection<User>;

  constructor(private fs: AngularFirestore, private auth: AuthenticationService, private http: HttpClient) {
    this.userCollection = fs.collection<User>('users');
    this.userAll();
  }

  userAll() {
    this.users$ = this.userCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        let data = a.payload.doc.data();
        return { ...data, uid: a.payload.doc.data().uid }
      })))
  }

  userObtainByUidObs(uid: string) {
    this.userDetail$ = this.userCollection.doc(uid).snapshotChanges().pipe(
      map(action => action.payload.data()))
  }

  async userCreate(user: UserModel) {
    let accepted: boolean = false;
    let error: string;
    try {
      // Create user credentials.
      // Dispatch error if credentials failed to create.
      // Service user create with credentials return object with data.
      let batch = this.fs.firestore.batch();
      batch.set(this.fs.firestore.collection('users').doc(user.uid), user);
      await batch.commit()

    } catch (e) {
      error = e
    } finally {
      if (error != undefined) {
        accepted = false;
      } else {
        accepted = true;
      }
    }
    return { accepted, error, uidUser: user.uid }
  }

  async userUpdate(uid: string, data): Promise<any> {
    let accepted: boolean;
    let error;
    try {
      const updated = Date.now();
      await this.userCollection.doc(uid).update({ ...data, updatedAt: updated });
    } catch (e) {
      error = e;
    } finally {
      if (error != undefined) {
        accepted = false;
      } else {
        accepted = true;
      }
    }
    return {
      accepted: accepted,
      error: error
    }
  }

  async userUpdateV2(user: Partial<UserModel>): Promise<any> {
    let accepted: boolean;
    let error;
    try {
      await this.userCollection.doc(user.uid).update(user);
    } catch (e) {
      error = e;
    } finally {
      if (error != undefined) {
        accepted = false;
      } else {
        accepted = true;
      }
    }
    return {
      accepted: accepted,
      error: error
    }
  }

  updateUser( user: Partial<UserModel>) { return this.userCollection.doc(user.uid).update(user); }

  async userObtainByUid(uid: string): Promise<any> {
    let error: string;
    let accepted: boolean = false;
    let user: User;
    try {
      const userQuery = await this.fs.collection<User>('users').doc(uid).get().toPromise();
      user = userQuery.data();
    } catch (e) {
      console.log(e)
      error = e
    } finally {
      if (error != undefined) {
        accepted = false;
      } else {
        accepted = true;
      }
    }
    return { accepted, error, user }
  }

  doGetUserUid(uid: string){ return this.fs.doc<UserModel>(`users/${uid}`).valueChanges(); }

  async userFilterByEqual(field: string, param: number | string | Object): Promise<any> {
    let error: any;
    let accepted: boolean;
    let user: User
    try {
      const result = await this.fs.collection<User>('users', ref => ref.where(field, '==', param).limit(1)).get().toPromise();
      if (result.docs.length != 0) {
        user = result.docs[0].data();
      }
    } catch (e) {
      error = e
    } finally {
      if (error != undefined) {
        accepted = false;
      } else {
        accepted = true;
      }
    }
    return {
      accepted: accepted,
      error: error,
      data: user
    }
  }

  goGetUserByPermission(permissions: any[]){
    return this.fs.collection<UserModel>('users', ref => ref.where('permissions','array-contains-any', permissions)).valueChanges()
  }

  goGetUserByCodeSupervisor(code: any[]){
    return this.fs.collection<UserModel>('users', ref => ref.where('codeSupervisor','array-contains-any', code)).valueChanges()
  }

  goGetUserBySupervisor(code: any[]){
    return this.fs.collection<UserModel>('users', ref => ref.where('supervisorL1','in', code)).valueChanges()
  }

  goGetUserBySupervisorCode(code: string){
    return this.fs.collection<UserModel>('users', ref => ref.where('supervisorL1.code','==', code)).valueChanges()
  }
  
  doGetUser(permission: string){
    return this.fs.collection<UserModel>('users', ref => ref.where('permissions','array-contains', permission)).valueChanges()
  }

  setUserSelected( user: UserModel ) { this.user.next( user ); }

  async createOrderInCloud(order: OrderModel) {
    let path = `${environment.cloud.path}${environment.cloud.createOrder}`
    let body = {
      order
    }
    let headersP = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'uiduser': order?.staff.uid,
      })
    }
    let conexion = await this.http.post(path, body, headersP).toPromise()
    return conexion
  }
}
