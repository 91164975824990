import { Component } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { navigation_modules, navItems } from '../../../_nav'

@Component({
  selector: 'app-default',
  templateUrl: './index.component.html',
})
export class NavigationIndexComponent {

  public sidebarMinimized = false;
  public navItems = navItems;
  public navigationModules = navigation_modules;

  constructor(private auth: AuthenticationService){
    this.auth.staffCurrent.subscribe(staff => {
      this.navItems = this.navItems.filter((a: any) => { return a?.roles && a?.roles.some((r) => { return staff?.permissions.includes(r) }) })
    })
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

}
