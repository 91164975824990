import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { ORDER_STATUS_, ORDER_TYPE_FILTER } from '../../../constants/order';
import { PERMISSION } from '../../../constants/permission';
import { Auctioneer } from '../../../interfaces/auctioneer';
import { Order, OrderModel } from '../../../interfaces/order';
import { UserModel } from '../../../interfaces/user';
import { AuthenticationService } from '../../../services/authentication.service';
import { OrderService } from '../../../services/order.service';
import { getOrderStatus, getPriceAuctioneer, objToArray } from '../../../utils/general.utils';
import { OrderCreateComponent } from '../order-create/order-create.component';

@Component({
  selector: 'order-order-table',
  templateUrl: './order-table.component.html',
})
export class OrderTableComponent implements OnInit {

  orders$: Observable<OrderModel[]>;
  searchOrder = new FormControl('');
  typeFilter = new FormControl(0);
  orderFilter = ORDER_TYPE_FILTER;
  orderCreateModalRef: BsModalRef;
  orderStatus = ORDER_STATUS_
  statusOrder: BehaviorSubject<string | null>;
  codeSupervisor: BehaviorSubject<string | null>;
  staff: UserModel;
  objToArray = objToArray

  constructor(private orderService: OrderService, private afs: AngularFirestore, private auth: AuthenticationService) {
    this.statusOrder = new BehaviorSubject(null);
    auth.staffCurrent.subscribe(staff => {
      this.staff = staff
      this.codeSupervisor = new BehaviorSubject(staff?.supervisorL1?.code);
      this.orders$ = combineLatest(
        this.statusOrder,
        this.codeSupervisor
      ).pipe(
          switchMap(([status, codeSupervisor]) =>
            afs.collection<OrderModel>('orders', ref => {
              if (status) {
                if (status == 'all') {
                  return ref.where('status', 'in', [ORDER_STATUS_.created.index, ORDER_STATUS_.canceled.index,ORDER_STATUS_.done.index,ORDER_STATUS_.pending.index,ORDER_STATUS_.inProcess.index]).limit(200).orderBy('createdAt', 'desc')
                } else {
                  return ref.where('status', '==', status).orderBy('createdAt', 'desc').limit(200)
                }
              } 
              else {
                if(staff.permissions.some(p => p === PERMISSION.admin.index)){
                  return ref.where('status', 'in', [ORDER_STATUS_.created.index, ORDER_STATUS_.canceled.index,ORDER_STATUS_.done.index,ORDER_STATUS_.pending.index,ORDER_STATUS_.inProcess.index,ORDER_STATUS_.bidding.index]).limit(200).orderBy('createdAt', 'desc')
                } else if(staff.permissions.some(p => p === PERMISSION.supervisorL1.index)){
                  return ref.where('status', 'in', [ORDER_STATUS_.created.index, ORDER_STATUS_.canceled.index,ORDER_STATUS_.done.index,ORDER_STATUS_.pending.index,ORDER_STATUS_.inProcess.index,ORDER_STATUS_.bidding.index]).where('codeSupervisor','==', codeSupervisor).limit(200).orderBy('createdAt', 'desc')
                } else if(staff.permissions.some(p => p === PERMISSION.supervisorL2.index)){
                  return ref.where('status', 'in', [ORDER_STATUS_.created.index, ORDER_STATUS_.canceled.index,ORDER_STATUS_.done.index,ORDER_STATUS_.pending.index,ORDER_STATUS_.inProcess.index,ORDER_STATUS_.bidding.index]).where('codeSupervisor','==', codeSupervisor).limit(200).orderBy('createdAt', 'desc')
                } else if(staff.permissions.some(p => p === PERMISSION.staff.index)){
                }
              }
            }).valueChanges())
        )
    } )
   }
   

  ngOnInit() {
    // this.orders$ = this.orderService.ordersActive$;
    this.searchValueChanges();
    // this.orderService.orderCreate2()
    // this.orderService.orderCreate3();
  }

  filterOrder(param: string) {
    this.checkSelect();
  }

  orderRefresh() {
    // this.orders$ = this.orderService.ordersActive$;
  }

  checkSelect() {
    if (this.typeFilter.value === 0) {
      this.typeFilter.setValue('user');
    }
  }

  changeQueryFilter(e) {
    this.typeFilter.setValue(e.target.value, { onlySelf: true })
  }

  searchValueChanges() {
    this.searchOrder.valueChanges.pipe(
      map(search => search?.toLowerCase().trim()),
      debounceTime(300),
      distinctUntilChanged(),
      filter(search => search != '' && search?.length > 3),
      tap(search => this.filterOrder(search))).subscribe()
  }

  getOrderStatus(status: string){ return getOrderStatus(status) }

  getPriceAuctioneer(last: boolean, auctioners: Auctioneer){  return getPriceAuctioneer(last, auctioners)}
}
