import { Injectable } from "@angular/core";
import {
  AngularFirestore,
} from "@angular/fire/firestore";
import { Supervisor } from "../interfaces/supervisor";

@Injectable({
  providedIn: "root",
})
export class supervisorService {

  constructor(
    private fs: AngularFirestore,
  ) {
  }

  async OnInit() {}

  async setSupervisor(uid: string, supervisor: Supervisor) {
    let accepted: boolean;
    let error: any;
    try {
      let batch = this.fs.firestore.batch()
      batch.update( this.fs.firestore .collection('users') .doc(uid),
        {
          'isSupervisor': true,
          'updated': Date.now()
        });
      batch.set(
        this.fs.firestore
          .collection('users')
          .doc(uid)
          .collection('detail')
          .doc('supervisor'), supervisor
      )
      await batch.commit();
    } catch (e) {
      error = e;
    } finally {
      if (error === undefined) {
        accepted = true;
      } else {
        accepted = false;
      }
    }
    return {
      accepted: accepted,
      error: error,
    };
  }

  async supervisorObtainByEqual(query:string, param:string) {
    let accepted: boolean;
    let supervisor: Supervisor;
    let error: any;
    try {

    } catch (e) {
      error = e;
    } finally {
      if (supervisor && !'undefined') {
        accepted = true;
      } else {
        accepted = false;
      }
    }
    return { accepted, supervisor, error }
  }

  async supervisorObtainByUid(uid: string):Promise<any> {
    let accepted: boolean;
    let supervisor: Supervisor;
    let error: any;
    try {
      let supervisorQuery = await this.fs
        .collection("users")
        .doc(uid)
        .collection<Supervisor>('detail')
        .doc('supervisor')
        .get()
        .toPromise();

      supervisor = supervisorQuery.data();
    } catch (e) {
      error = e;
    } finally {
      if (supervisor && !'undefined') {
        accepted = true;
      } else {
        accepted = false;
      }
    }
    return { accepted, supervisor, error }
  }

  async updateSupervisor(uid: string, supervisor: Object): Promise<any> {
    let accepted: boolean;
    let error: any;
    try {
      await this.fs
        .collection("users")
        .doc(uid)
        .collection("detail")
        .doc("supervisor")
        .update(supervisor);
    } catch (e) {
      error = e;
    } finally {
      if (error === undefined) {
        accepted = true;
      } else {
        accepted = false;
      }
    }
    return {
      accepted,
      error,
    };
  }
}
