import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';


export function notifyMessage(status: SweetAlertIcon, message: string, position: SweetAlertPosition) {
  return Swal.fire({
    position: position || 'top-end',
    icon: status,
    title: message,
    showConfirmButton: false,
    timer: 3000
  })
}

export function sendErrorMessage(text: string) {
  return Swal.fire({
    position: 'top-end',
    icon: 'error',
    showConfirmButton: false,
    timer: 2500,
    text: text
  })
}
