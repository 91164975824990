import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from '../../../interfaces/user';
import { AuthenticationService } from '../../../services/authentication.service';
import { UserService } from '../../../services/user.service';
import { isValid } from '../../../utils/general.utils';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
})
export class UserDetailComponent implements OnInit {

  userUid: string = '';
  userModel: UserModel
  staff: UserModel
  constructor(private route:ActivatedRoute, private userService: UserService, private auth: AuthenticationService) {
    this.auth.staffCurrent.subscribe(staff => this.staff = staff )
    this.userUid = this.route.snapshot.params['uid'];
    this.userService.doGetUserUid(this.userUid).subscribe(user => { this.userModel = user; userService.setUserSelected(user)})
   }

  ngOnInit(): void {
  }
  isValid(permission: string, permissions: Array<string>){ return isValid(permission, permissions) }
}
