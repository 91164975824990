import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { STATE_DRIVER_ } from '../../../constants/driver';
import { Driver } from '../../../interfaces/Driver';
import { Geopoint } from '../../../interfaces/geopoint';
import { Supervisor } from '../../../interfaces/supervisor';
import { User } from '../../../interfaces/user';
import { notifyMessage } from '../../../notifications/notifications';
import { DriverService } from '../../../services/driver.service';

@Component({
  selector: 'app-driver-create',
  templateUrl: './driver-create.component.html',
  styleUrls: ['./driver-create.component.scss']
})
export class DriverCreateComponent implements OnInit {

  formCreateDriver: FormGroup

  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private afs: AngularFirestore, private driveService: DriverService) { 
    this.iniFormCreateDrive()
  }


  ngOnInit(): void {
  }

  iniFormCreateDrive(){
    this.formCreateDriver = this.formBuilder.group({
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      urlRecordPolicial: ['', Validators.required],
      // driverUbication: ['', Validators.required],
      address: ['', Validators.required],
    })
  }

  async submitForm() {
    if (!this.formCreateDriver.valid) return false;
    const setDriver: Driver = {
      name: this.formCreateDriver.value.name,
      lastName: this.formCreateDriver.value.lastName,
      urlRecordPolicial: this.formCreateDriver.value.urlRecordPolicial,
      address: this.formCreateDriver.value.address,
      driverUbication: {} as Geopoint,
      idBanned: false,
      state: STATE_DRIVER_.pending.index,
      supervisorDetail: {supervisor: {} as Supervisor, user: {} as User},
      license: {},
      active: true,
      createdAt: new Date(),
      updatedAt: new Date(),
      uid: this.afs.createId()
    }
    this.driveService.setDriver(setDriver)
      .then( _ => { notifyMessage('success', 'Driver agregado', 'top-right'); })
      .catch( err => { notifyMessage('error', 'No se pudo subir información del vehículo', 'top-right'); })
  }

  

}
