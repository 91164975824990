import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { TYPE_PHOTO_CAR } from "../../../constants/vihecles";
import { UserModel } from "../../../interfaces/user";
import { PhotoCar, TypeVehicle, Vehicle } from '../../../interfaces/vehicle';
import { notifyMessage } from "../../../notifications/notifications";
import { DriverService } from "../../../services/driver.service";
import { UserService } from "../../../services/user.service";
import { License } from '../../../interfaces/license';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: "driver-vehicle-table",
  templateUrl: "./vehicle-table.component.html",
})
export class DriverVehicleTable implements OnInit {

  vehicles$: Observable<any>;
  modalRef?: BsModalRef;
  listImage: any;
  userModel: UserModel;
  vehicleSelect: Vehicle;
  typePhotoCar = TYPE_PHOTO_CAR;
  form: FormGroup;

  constructor(private driverService: DriverService, private modalService: BsModalService, private userService: UserService, private storage: AngularFireStorage, private formBuilder: FormBuilder) {
    userService.userSelected.subscribe(user => this.userModel = user)
    this.form = this.formBuilder.group({
      model: ['', [Validators.required]],
      color: ['', [Validators.required]],
      plate: ['', [Validators.required]],
      cylinder: ['', [Validators.required]],
      year: ['', [Validators.required]],
      enabled: [false, [Validators.required]],
    });
  }

  ngOnInit() {
  }

  openModal(modal: TemplateRef<any>, images: any) {
    if (images) this.listImage = [{ urlImage: images }]
    this.modalRef = this.modalService.show(modal);
  }

  showInfo(vehicle: Vehicle) { this.vehicleSelect = vehicle }
  closeInfo() { this.vehicleSelect = {} as Vehicle }

  async activePhoto(photo: PhotoCar, active: boolean) {



    const indexPhoto = this.vehicleSelect.photoCar.findIndex(p => p.timestamp === photo.timestamp)
    this.vehicleSelect.photoCar[indexPhoto].active = active
    const indexVehic = this.userModel.driver.vehicles.findIndex(v => v.plate === this.vehicleSelect.plate)
    this.userModel.driver.vehicles[indexVehic].photoCar = this.vehicleSelect.photoCar
    this.userModel.driver.vehicles[indexVehic].enabled = this.vehicleSelect.photoCar.every(p => p.active)
    const userData: Partial<UserModel> = {
      driver: this.userModel.driver,
      updatedAt: this.userModel.driver?.updatedAt || new Date(),
      uid: this.userModel.uid
    }
    const { accepted, error } = await this.userService.userUpdateV2(userData);
    if (accepted) {
      notifyMessage('success', `${active ? 'Foto aprobada' : 'Foto no aprobada'}`, 'top-right');
    } else {
      notifyMessage('error', 'No se pudo actualizar', 'top-right');
    }
  }

  openModalVehicleEdit(modal: TemplateRef<any>, images: any) {
    if (images) this.listImage = [{ urlImage: images }]
    console.log('image: ', images);
    this.modalRef = this.modalService.show(modal);
  }

  validPhotocar(photos: PhotoCar[], type: string) {

    return photos.findIndex((photo) => photo.type == type);

  }

  Object(value: any) {
    return Object.values(value);
  }

  async uploadImagePhotocar(event: Event, usermodel: UserModel, typephoteCar: string, vehicle: Vehicle): Promise<void> {
    if (confirm("confirma que desea subir la imagen del vehiculo")) {

      if (usermodel.driver != undefined) {

        const hi = event.target as HTMLInputElement

        const uid = usermodel.uid

        const url = await this.userUploadCloudStorage(uid, hi.files[0], 'photoCar');

        const indexphotocar = vehicle.photoCar.findIndex((value) => value.type == typephoteCar);

        if (indexphotocar != -1) {
          vehicle.photoCar[indexphotocar].urlImage = url;
        } else {
          const photoCar: PhotoCar = {
            active: false,
            timestamp: Date.now(),
            type: typephoteCar,
            urlImage: url
          }
          vehicle.photoCar.push(photoCar);
        }
        const indexvehicle = usermodel.driver.vehicles.findIndex((vehiclefinder) => vehiclefinder.plate = vehicle.plate);
        if (indexvehicle != -1) {
          usermodel.driver.vehicles[indexvehicle] = vehicle

          const userData: Partial<UserModel> = {
            driver: usermodel.driver,
            updatedAt: new Date(),
            uid: usermodel.uid
          }

          const { accepted, error } = await this.userService.userUpdateV2(userData);
          if (accepted) {
            alert('Cambio Realizado');
          } else {
            alert(`error: ${JSON.stringify(error)}`);
          }
        } else {
          alert('No se encontro el vehiculo ');
        }



      } else {
        alert('ussuario no es conductor');
      }


    }

  }

  async userUploadCloudStorage(uid: string, file: File, directory: string): Promise<string> {

    try {

      const fileString = file.name;
      const extension = fileString.substring(
        fileString.lastIndexOf(".") + 1,
        fileString.length
      );
      const date = Date.now();
      const filename = `${uid}_${date}_${directory}.${extension}`.trim();
      const urlStorage = `users/${uid}/${directory}/${filename}`;
      const ref = this.storage.ref(urlStorage);
      const task = await ref.put(file).snapshotChanges().toPromise()
      const url = await ref.getDownloadURL().toPromise();
      /*  alert(`url: ${url}`);
  */
      return url;


    } catch (error) {
      alert(`hubo un error ${JSON.stringify(error)}`);
    }

  }

  async saveVehicle(usermodel: UserModel):Promise<void> {

    if(confirm('Estas seguro que deseas crear un nuevo vehiculo')){

      if (this.form.valid) {
        const typeVehicle: TypeVehicle = {
          type: 'auto'
        };
  
        const vehicle: Vehicle = {
          color: this.form.get('color').value,
          enabled: this.form.get('enabled').value,
          model: this.form.get('model').value,
          plate: this.form.get('plate').value,
          year: this.form.get('year').value,
          typeVehicle: typeVehicle,
          photoCar:[]
  
        }
  
  
        if (usermodel.driver.vehicles != undefined) {
          usermodel.driver.vehicles.push(vehicle);
        } else {
          usermodel.driver['vehicles'] = [vehicle];
        }
       
        const userData: Partial<UserModel> = {
          driver: usermodel.driver,
          updatedAt: new Date(),
          uid: usermodel.uid
        }
  
        const { accepted, error } = await this.userService.userUpdateV2(userData);
        if (accepted) {
          alert('Cambio Realizado');
          this.form.reset();
        } else {
          alert(`error: ${JSON.stringify(error)}`);
        }
  
      }
      else {
        alert('Revise la Informacion\nfaltan datos');
      }
    }

  }
}
