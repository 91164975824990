import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
})
export class PasswordResetComponent {

  userPasswordResetPasswordForm: FormGroup;
  userPasswordResetLoading: boolean = false;
  errors: any[] = [];

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private userAuth: AuthenticationService) {
    this.userPasswordResetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  async userPasswordReset() {
    this.userPasswordResetLoading = true;
    if (!this.userPasswordResetPasswordForm.valid) return false;
    const email = this.userPasswordResetPasswordForm.get('email').value;
    const { sended, errorMessage } = await this.userAuth.userPasswordReset(email);
    if (!sended || errorMessage) {
      this.userPasswordResetLoading = false;
      this.errors.push({ message: errorMessage });
      return false;
    }
    this.userPasswordResetLoading = false;
    this.router.navigate(['/reestablecer-clave-confirmado'])
  }

  get email() { return this.userPasswordResetPasswordForm.get('email') }
}
