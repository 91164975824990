import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountPerfilComponent } from './account-perfil/account-perfil.component';
import { AccountRoutingModule } from './account-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountUserUpdateEmailComponent } from './account-user-update-email/account-user-update-email.component';
import { AccountChangePasswordComponent } from './account-change-password/account-change-password.component';
import { AlertModule } from 'ngx-bootstrap/alert';

const components = [
  AccountPerfilComponent,
  AccountChangePasswordComponent,
  AccountUserUpdateEmailComponent
]

@NgModule({
  declarations: [components,],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccountRoutingModule,
    AlertModule.forRoot(), 
  ],
  exports: [components]
})
export class AccountModule { }
