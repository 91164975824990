import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypeVehicle } from '../../../interfaces/vehicle';
import { ImageStorageService } from '../../../services/image-storage.service';

@Component({
  selector: 'app-modal-image-type-vehicle',
  templateUrl: './modal-image-type-vehicle.component.html',
  styleUrls: ['./modal-image-type-vehicle.component.scss']
})
export class ModalImageTypeVehicleComponent implements OnInit {

  @Input() modalRef: BsModalRef;
  @Input() typeVehicle: TypeVehicle;

  croppedImage: any = {};
  componentImage = [
    { key: 'imagen' , title:'Arrastra aquí foto la imagen'},
  ]
  
  constructor(private storage: ImageStorageService) { }

  ngOnInit(): void {
  }

  handleCropped(cropped: any) {
    this.croppedImage = { ...this.croppedImage, ...cropped }
  }

  uploadImages() {
    let arrImages = Object.values(this.croppedImage);
    let arrUrl = this.storage.uploadImageFilesTypeVehicle(arrImages);
    return arrUrl;
  }

}
