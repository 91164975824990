import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";

import * as firebase2 from "firebase/app";
import "firebase/auth";
import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { CredentialCreate } from "../interfaces/services/CredentialCreate";
import { Session } from "../interfaces/Session";
import { User, UserModel } from "../interfaces/user";
import { FirebaseErrors } from "../modules/firebase/HandlingErrors"
import firebase from 'firebase';
import { environment } from "../../environments/environment";
import { PERMISSION } from "../constants/permission";
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  staff$: Observable<UserModel>

  private staff = new BehaviorSubject( {} as UserModel );
  staffCurrent = this.staff.asObservable();

  constructor(private auth: AngularFireAuth, private fs: AngularFirestore) {
    this.staff$ = this.auth.authState.pipe(
      switchMap(admin => {
        if (admin) {
          const staff = this.fs.collection('users', ref => ref
          .where('uid','==',admin.uid)
          .where('permissions', 'array-contains-any',[
            PERMISSION.supervisorL1.index,
            PERMISSION.supervisorL2.index,
            PERMISSION.staff.index,
            PERMISSION.admin.index,
            PERMISSION.counter.index,
            PERMISSION.digitizers.index])
          .limit(1))
          .valueChanges()
          this.setStaff(staff)
          return staff
          // return this.fs.doc<UserModel>(`users/${admin.uid}`).valueChanges();
        } else { return of(null); }
      })
    );
   }

  async userIsActiveAccount(email: string) {
    let active: boolean = true;
    let accountErrorMsg: string;
    try {
      let userResult = await this.fs.collection<User>("users", (ref) => ref.where('email', "==", email).limit(1)).get().toPromise();
      if (userResult.docs.length != 0) {
        let user = await userResult.docs[0].data();
        if (user.active === false) {
          accountErrorMsg = "Cuenta desabilitada por el administrador.";
        }
      } else {
        active = true;
      }
    } catch (err) {
      accountErrorMsg = err;
    } finally {
      if (accountErrorMsg) {
        active = false;
      }
    }
    return { active, accountErrorMsg }
  }

  async userCheckAcccountSession(session: string): Promise<any> {
    let alreadySession: boolean = false;
    const { uid } = await this.auth.currentUser;
    const userQuery = await this.fs.collection<User>('users').doc(uid).get().toPromise();
    if (!userQuery.exists) return false;
    const user = userQuery.data();
    if (Object.keys(user.session).length != 0) {
      alreadySession = true;
    } else {
      alreadySession = false;
      this.userAccountCreateSession(session, user.uid);
    }
    return { alreadySession }
  }

  async userAccountCreateSession(session: string, uid: string) {
    const date = Date.now();
    let data = {}
    const sessionUser: Session = { active: true, timestamp: date, sessionId: session };
    data[date] = sessionUser
    await this.fs.collection('users').doc(uid).update({ session: data })
  }

  async userAccountUpdateSession(session: string, uid: string) {
    await this.fs.collection('users').doc(uid).update({ session: [1, 2] })
  }

  signInWithEmailAndPassword(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  async userLogin(email: string, password: string): Promise<any> {
    let logged: boolean = false;
    let loginErroMsg: string;
    let session: Object;
    try {
      session = await this.auth.signInWithEmailAndPassword(email, password);
      console.log('session: ', session);
    } catch (err) {
      loginErroMsg = FirebaseErrors.Parse(err.code);
    } finally {
      console.log('intoHere finally');
      if (loginErroMsg != undefined) {
        logged = false;
      } else {
        logged = true;
      }
    }
    return { session, logged, loginErroMsg };
  }

  async userPasswordChange(oldPassword: string, newPassword: string) {
    let accepted: boolean = false;
    let errorMessage;
    try {
      const user = await this.auth.currentUser;
      const credential = firebase2.default.auth.EmailAuthProvider.credential(
        user.email,
        oldPassword
      );
      const res = await user.reauthenticateWithCredential(credential);
      const changePassword = await user.updatePassword(newPassword);
    } catch (error) {
      errorMessage = FirebaseErrors.Parse(error.code)
    } finally {
      if (errorMessage != undefined) {
        accepted = false;
      } else {
        accepted = true;
      }
    }
    return { errorMessage, accepted };
  }

  async userEmailChange(newEmail: string, password: string) {
    let accepted: boolean = false;
    let errorMessage;
    try {
      const user = await this.auth.currentUser;
      const credential = firebase2.default.auth.EmailAuthProvider.credential(user.email, password);
      await user.reauthenticateWithCredential(credential);
      await user.updateEmail(newEmail);
    } catch (error) {
      console.log(error, " error de user email change")
      errorMessage = FirebaseErrors.Parse(error.code);
    } finally {
      if (errorMessage != undefined) {
        accepted = false;
      } else {
        accepted = true;
      }
    }
    return { errorMessage, accepted };
  }

  async userCreateWithEmailAndPassword(email: string): Promise<CredentialCreate> {
    let created: boolean = false;
    let error: string;
    let uidSession: string;
    try {
      const password = this.userGeneratePassword(email);
      const { user } = await this.auth.createUserWithEmailAndPassword(email, password);
      uidSession = user.uid;
      await this.auth.sendPasswordResetEmail(email);
    } catch (err) {
      error = FirebaseErrors.Parse(err.code);
    } finally {
      if (error != undefined) {
        created = false;
      } else {
        created = true;
      }
    }
    return { created, error, uidSession };
  }

  async userSignOut(): Promise<any> {
    return await this.auth.signOut();
  }

  async userAccountDeleteSession() {
    try {
      const { uid, email } = await this.auth.currentUser;
      const userQuery = await this.fs.collection<User>('users', ref => ref.where('email', '==', email)).get().toPromise();
      const user = userQuery.docs[0].data();
      const userSessionFiltered = Object.keys(user.session).filter(k => user.session[k].sessionId !== uid);
      const data = userSessionFiltered.reduce((a, v) => ({ ...a, [v]: v }), {});
      await this.fs.collection('users').doc(user.uid).update({ session: data });
    } catch (err) {

    } finally {

    }
    return {}
  }

  async userPasswordReset(email: string): Promise<any> {
    let errorMessage;
    let sended: boolean;
    try {
      await this.auth.sendPasswordResetEmail(email);
    } catch (err) {
      errorMessage = FirebaseErrors.Parse(err.code);
    } finally {
      if (errorMessage != undefined) {
        sended = false;
      } else {
        sended = true;
      }
    }
    return { sended, errorMessage };
  }

  async userPasswordResetConfirm(code: string, password: string) {
    let errorMessage;
    let sended: boolean;
    try {
      await this.auth.confirmPasswordReset(code, password);
    } catch (err) {
      errorMessage = FirebaseErrors.Parse(err.code);
    } finally {
      if (errorMessage != undefined) {
        sended = false;
      } else {
        sended = true;
      }
    }
    return { sended, errorMessage };
  }

  /**
   *
   * @param email string
   * @returns Password generate based in email.
   */
  userGeneratePassword(email: string): string {
    const MAX_LENGTH_PASSWORD: number = 15;
    let password = [];
    for (let i = 0; i <= MAX_LENGTH_PASSWORD; i++) {
      let character = Math.floor(Math.floor(Math.random() * email.length + 1));
      password.push(email.charAt(character));
    }
    return password.join("");
  }

  setStaff( staff: any ) { 
    staff.subscribe(s => {
      this.staff.next( s.length ? s[0] : this.userSignOut()); 
    })
  }

  async createUserCloud(email: string){
    const auth = {
      email,
      password: this.userGeneratePassword(email)
    }
    const addMenssage = await firebase.functions().httpsCallable(environment.cloud.newUser)
    return addMenssage(auth).then((result) => {
      return result
    }).catch((error) => {
      return error
    })
  }
}
