
export class FirebaseErrors {
  static Parse(errorCode: string): string {

    let message: string;

    switch (errorCode) {
      case 'auth/wrong-password':
        message = 'Credenciales proporcionadas no son correctas.';
        break;
      case 'auth/network-request-failed':
        message = 'Por favor, revisa tu conección a internet.';
        break;
      case 'auth/too-many-requests':
        message =
          'Se ha detectado que se han realizado muchas peticiones, inténtalo más tarde.';
        break;
      case 'auth/user-disabled':
        message =
          'Tu cuenta ha sido desabilitada temporalmente por administrador.';
        break;
      case 'auth/requires-recent-login':
        message = 'Por favor, cierra sesión e inténtalo de nuevo';
        break;
      case 'auth/email-already-in-use':
        message = 'La dirección electrónica proporcionada ya está en uso.';
        break;
      case 'auth/user-not-found':
        message =
          'La dirección de email proporcionado no corresponde a una cuenta.';
        break;
      case 'auth/phone-number-already-exists':
        message = 'El número telefónico está siendo utilizado por otro usuario.';
        break;
      case 'auth/invalid-phone-number':
        message = 'El número telefónico no está disponible.';
        break;
      case 'auth/invalid-email  ':
        message = 'La dirección electrónica no es válida.';
        break;
      case 'auth/cannot-delete-own-user-account':
        message = 'No puedes eliminar o desactivar tu cuenta.';
        break;
       default:
        message = 'Opss, lo sentimos inténtalo más tarde.';
        break;
    }

    return message;
  }
}
