import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountChangePasswordComponent } from './account-change-password/account-change-password.component';
import { AccountPerfilComponent } from './account-perfil/account-perfil.component';
import { AccountUserUpdateEmailComponent } from './account-user-update-email/account-user-update-email.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: AccountPerfilComponent,
      }
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'actualizar-email',
        component: AccountUserUpdateEmailComponent,
      }
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'actualizar-clave',
        component: AccountChangePasswordComponent,
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule { }