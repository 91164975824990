import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { notifyMessage } from '../../../notifications/notifications';
import { AuthenticationService } from '../../../services/authentication.service';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { notWhiteSpace, onlyLowerCase } from '../../validators/users';
import { UserService } from '../../../services/user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../../../interfaces/user';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-account-user-update-email',
  templateUrl: './account-user-update-email.component.html',
})
export class AccountUserUpdateEmailComponent {

  userUpdateEmailForm: FormGroup;
  userUpdateEmailLoading: boolean = false;
  errors: any[] = [];

  constructor(private router: Router,
    private userAuth: AuthenticationService,
    private userService: UserService,
    private auth: AngularFireAuth,
    private fs: AngularFirestore,
    private formBuilder: FormBuilder) {
    this.userUpdateEmailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, notWhiteSpace(), onlyLowerCase()]],
      password: ['', [Validators.required, notWhiteSpace(), onlyLowerCase()]],
    });
  }

  async userUpdateEmail() {
    this.userUpdateEmailLoading = true;
    const newEmail = this.userUpdateEmailForm.get('email').value;
    const password = this.userUpdateEmailForm.get('password').value;

    const { accepted, errorMessage } = await this.userAuth.userEmailChange(newEmail, password);
    if (!accepted) {
      this.errors.push({ message: errorMessage });
      this.userUpdateEmailLoading = false;
      return false;
    }
    const { uid } = await this.auth.currentUser;
    await this.userService.userUpdate(uid, { email: newEmail });
    notifyMessage('success', 'Correo electrónico actualizado.', 'top-right');
    this.router.navigate(['/cuenta']);
    this.userUpdateEmailLoading = false;
  }

  get email() { return this.userUpdateEmailForm.get('email') };
  get password() { return this.userUpdateEmailForm.get('password') };
}
