import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Staff } from '../../../../interfaces/Staff';
import { User } from '../../../../interfaces/user';
import { StaffService } from '../../../../services/staff.service';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'notification-today-index',
  templateUrl: './notification-today-index.component.html',
})
export class NotificationTodayIndexComponent implements OnInit {

  users$: Observable<User[]>;
  staff$: Observable<Staff[]>;

  constructor(
    private staffService: StaffService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.staff$ = this.staffService.staff$.pipe(
      map(values => values.filter(value => this.isToday(value))))

    // this.users$ = this.userService.users$.pipe(
    //   map(values => values.filter(value => this.isToday(value))))
  }

  isToday(value) {
    const dateUserCreated = new Date(value.timestamp).toISOString().substring(0, 10);
    const isSame = moment(dateUserCreated).isSame(new Date().toISOString().substring(0, 10))
    if (isSame) return value
  }

}
