import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  USER_REGISTERED_SUCCESS,
  USER_UPLOAD_ERROR,
  USER_UPLOAD_IMAGE_EMPTY,
  USER_UPLOAD_IMAGE_ERROR,
} from '../../../constants/user_messages';
import { PaymentMethod } from '../../../interfaces/payment';
import { UploadStorage } from '../../../interfaces/UploadStorage';
import { ClientModel, DriverModel, SupervisorModel, User, UserModel } from '../../../interfaces/user';
import { notifyMessage, sendErrorMessage } from '../../../notifications/notifications';
import { AuthenticationService } from '../../../services/authentication.service';
import { ImageCropperService } from '../../../services/image-cropper.service';
import { ImageStorageService } from '../../../services/image-storage.service';
import { UserService } from '../../../services/user.service';
import { DateHelper } from '../../../utils/date';
import { dateValid } from '../../validators/date';
import firebase from 'firebase';
import { STATE_DRIVER_ } from '../../../constants/driver';
/* import * as geofirex from 'geofirex'; */
import { MapsAPILoader } from '@agm/core';
import { SETTING_PERCENTAGE } from '../../../constants/general';
import { isValid } from '../../../utils/general.utils';
import { generateRandomString } from '../../validators/convertCode';
import { AngularFireAuth } from '@angular/fire/auth';

const geohash = require('ngeohash');

declare var google: any;

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
})
export class UserCreateComponent {

  userCreateLoading: boolean = false;

  userCreateForm: FormGroup;
  formCreateDriver: FormGroup
  formCreateClient: FormGroup
  formCreateSupervisorL1: FormGroup
  formCreateSupervisorL2: FormGroup
  lat: number = -2.160139169427665;
  lng: number = -79.9071973753834

  staff: UserModel;
  dateCode = generateRandomString(5)
  userAdmin: UserModel
  constructor(private afs: AngularFirestore, private formBuilder: FormBuilder, private userService: UserService, private cropperService: ImageCropperService,
    private storage: ImageStorageService, private auth: AuthenticationService, private auth2: AngularFireAuth) {
      this.auth.staffCurrent.subscribe(staff => this.staff = staff)
      this.iniFormCreateUser()
      this.userService.doGetUserUid('gz6xXrJzRkMu9fdr1sFM2Dhsioy2').subscribe( u => this.userAdmin = u)
      this.iniFormCreateDrive()
      this.iniFormCreateClient()
      this.iniFormCreateSupervisorL1()
      this.iniFormCreateSupervisorL2()

      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      })
  }

  iniFormCreateDrive(){
    this.formCreateDriver = this.formBuilder.group({
      urlRecordPolicial: [''],
      // driverUbication: [''],
    })
  }

  iniFormCreateClient(){
    this.formCreateClient = this.formBuilder.group({
      percentage: [SETTING_PERCENTAGE.percentage, [Validators.required]],
      description: [SETTING_PERCENTAGE.description, [Validators.required]]
    })
  }
  iniFormCreateSupervisorL1(){
    this.formCreateSupervisorL1 = this.formBuilder.group({
      codeL1: [this.dateCode],
      percentage: [SETTING_PERCENTAGE.percentage, [Validators.required]],
      description: [SETTING_PERCENTAGE.description, [Validators.required]]
    })
  }
  iniFormCreateSupervisorL2(){
    this.formCreateSupervisorL2 = this.formBuilder.group({
      codeL2: [this.dateCode],
      percentage: [SETTING_PERCENTAGE.percentage, [Validators.required]],
      description: [SETTING_PERCENTAGE.description, [Validators.required]]
    })
  }

  iniFormCreateUser(){
    this.userCreateForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      birthday: [new Date(1995, 11, 17).toISOString().substring(0, 10), [Validators.required, dateValid()]],
      gender: [true, [Validators.required]],
      identification: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      addresses: ['', [Validators.required]],
      driver: [false],
      client: [true],
      supervisorL1: [false],
      supervisorL2: [true],
      digitizers: [false],
      counter: [false],
    });
  }

  async userUpdateProfileWithNewImage(uid: string, file: Blob) {
    try {
      // Service with storgate that obtain public url Image
      //Save image with uid, file and directory avatar.
      const dataUpload: UploadStorage = {
        uid: uid,
        file: file,
        folder: 'users',
        directory: 'avatar'
      }
      const urlPublicImage = this.storage.uploadCloudStorage(dataUpload);
      let thumbnails = {};
      thumbnails[Date.now()] = { active: true, timestamp: Date.now(), urlImage: urlPublicImage }
      // Checking percentage uploading.
      // Observable emit number percentage upload.
      const percentage = await this.storage.percentage.toPromise();
      if (percentage === 100) {
        // Update with user avatar information.
        notifyMessage('success', USER_REGISTERED_SUCCESS, 'top-right')
        // this.userCreateLoading = false;
        return urlPublicImage
      } else {
        notifyMessage('error', USER_UPLOAD_IMAGE_ERROR, null)
        this.userCreateLoading = false;
      }
    } catch (error) {
      notifyMessage('error', USER_UPLOAD_IMAGE_ERROR, null)
    }

  }

  async userAndClientCreate(uid: string, urlImage: string) {
    const date = new Date()
    let permissions = {}

    if(this.userCreateForm.value.driver){ permissions = {...permissions,['driver']: 'driver'} }
    if(this.userCreateForm.value.client){ permissions = {...permissions,['client']: 'client'} }
    if(this.userCreateForm.value.supervisorL1){ permissions = {...permissions,['supervisorL1']: 'supervisorL1'} }
    if(this.userCreateForm.value.supervisorL2){ permissions = {...permissions,['supervisorL2']: 'supervisorL2'} }
    if(this.userCreateForm.value.digitizers){ permissions = {...permissions,['digitizers']: 'digitizers'} }
    if(this.userCreateForm.value.counter){ permissions = {...permissions,['counter']: 'counter'} }

    const client: ClientModel = {
      createdAt: date,
      isBanned: false,
      updatedAt: date,
      isNotification: true,
      percentage: {
        percentage: this.formCreateClient.value.percentage,
        description: this.formCreateClient.value.description,
      }
    }

    const driver: DriverModel = {
      createdAt: date,
      urlRecordPolicial: this.formCreateDriver.value.urlRecordPolicial,
      state: STATE_DRIVER_.pending.index,
      isBanned: false,
      active: true,
      updatedAt: date,
      codeSupervisor: this.staff?.supervisorL1?.code || this.userAdmin?.supervisorL1?.code
    }

    const supervisorL1: SupervisorModel = {
      createdAt: date,
      code: this.formCreateSupervisorL1.value.codeL1,
      state: '',
      isBanned: false,
      updatedAt: date,
      active: true,
      percentage: {
        percentage: this.formCreateSupervisorL1.value.percentage,
        description: this.formCreateSupervisorL1.value.description,
      }
    }
    
    const supervisorL2: SupervisorModel = {
      createdAt: date,
      code: this.formCreateSupervisorL2.value.codeL2,
      state: '',
      isBanned: false,
      updatedAt: date,
      active: true,
      percentage: {
        percentage: this.formCreateSupervisorL2.value.percentage,
        description: this.formCreateSupervisorL2.value.description,
      }
    }
    const imag = await this.storage.imageDownloadUrl(urlImage)
    const geoPoint = new firebase.firestore.GeoPoint(this.lat, this.lng)
    let user: UserModel = {
      uid,
      createdAt: date,
      name: this.userCreateForm.value.name,
      lastName: this.userCreateForm.value.lastName,
      urlImage: imag || '',
      identification: this.userCreateForm.value.identification,
      gender: this.userCreateForm.value.gender,
      birthday: new Date(this.userCreateForm.value.birthday),
      phone: this.userCreateForm.value.phone,
      email: this.userCreateForm.value.email,
      updatedAt: date,
      active: true,
      session: {},
      codeSupervisor: [this.staff?.supervisorL1?.code || this.userAdmin?.supervisorL1?.code],
      codeSupervisorL2: this.staff?.supervisorL2?.code || this.userAdmin?.supervisorL2?.code,
      permissions: Object.values(permissions),
      addresses: [ 
        {
          address: this.userCreateForm.value.addresses,
          geoPoint,
          geohash: geohash.encode(this.lat,this.lng),
        }
       ],
       dataDelete: {
         supervisor: `${this.staff.name} ${this.staff.lastName}`
       }
    }

    if(this.userCreateForm.value.driver){
      user.codeSupervisorL1 = this.staff?.supervisorL1?.code || this.userAdmin?.supervisorL1?.code
    }

    if(this.userCreateForm.value.driver){ user['driver'] = driver }
    if(this.userCreateForm.value.client){ user['client'] = client }
    if(this.userCreateForm.value.supervisorL1){ user['supervisorL1'] = supervisorL1 }
    if(this.userCreateForm.value.supervisorL2){ user['supervisorL2'] = supervisorL2 }

    const { accepted, uidUser, error } = await this.userService.userCreate(user);
    if(accepted) await this.auth2.sendPasswordResetEmail(user.email);
    if (!accepted) {
      sendErrorMessage(error)
      this.userCreateLoading = false;
      return false;
    }

    return uidUser
  }

  async userCreate() {
    // Validate image
    this.userCreateLoading = true;
    if (!this.cropperService.userCroppedImage) {
      this.userCreateLoading = false;
      sendErrorMessage(USER_UPLOAD_IMAGE_EMPTY)
      return false;
    }
    if (!this.userCreateForm.valid) return false;
    const auth = await this.auth.createUserCloud(this.userCreateForm.value.email);
    if(auth?.data && auth?.data?.uidUser){
      const image = this.cropperService.userImageCropped;
      const urlPublicImage = await this.userUpdateProfileWithNewImage(auth?.data?.uidUser, image);
      const data = await this.userAndClientCreate(auth?.data?.uidUser, urlPublicImage);
      if (!data) return false;
      this.userCreateLoading = false;
      this.iniFormCreateUser()
      this.iniFormCreateDrive()
      this.iniFormCreateClient()
      this.iniFormCreateSupervisorL1()
      this.iniFormCreateSupervisorL2()

    }
    // Upload image when a user create is success.

  }

  setValidatorsForm(form: string, active: boolean){
    switch (form) {
      case 'driver':
        if(!active){
          this.formCreateDriver.controls['urlRecordPolicial'].setValidators([Validators.required])
        } else {
          this.formCreateDriver.controls['urlRecordPolicial'].clearValidators()
        }
        break;
      case 'client':
        if(!active){
        } else {
        }
        break;
      case 'supervisorL1':
        if(!active){
          this.formCreateSupervisorL1.controls['codeL1'].setValidators([Validators.required])
        } else {
          this.formCreateSupervisorL1.controls['codeL1'].clearValidators()
        }
        break;
      case 'supervisorL2':
        if(!active){
          this.formCreateSupervisorL2.controls['codeL2'].setValidators([Validators.required])
        } else {
          this.formCreateSupervisorL2.controls['codeL2'].clearValidators()
        }
        break;
    
      default:
        break;
    }
  }

  updateMarker($event) {
    this.lat = $event.latLng.lat()
    this.lng = $event.latLng.lng()
  }

  get name() { return this.userCreateForm.get('name') };
  get lastName() { return this.userCreateForm.get('lastName') };
  get email() { return this.userCreateForm.get('email') };
  get phone() { return this.userCreateForm.get('phone') };
  get identification() { return this.userCreateForm.get('identification') };
  get birthday() { return this.userCreateForm.get('birthday') };
  get addresses() { return this.userCreateForm.get('addresses') };

  get urlRecordPolicial() { return this.formCreateDriver.get('urlRecordPolicial') };
  get codeL1() { return this.formCreateSupervisorL1.get('codeL1') };
  get codeL2() { return this.formCreateSupervisorL2.get('codeL2') };

  get percentageClient() { return this.formCreateClient.get('percentage') }
  get descriptionClient() { return this.formCreateClient.get('description') }
  
  get percentageL1() { return this.formCreateSupervisorL1.get('percentage') }
  get descriptionL1() { return this.formCreateSupervisorL1.get('description') }
  get percentageL2() { return this.formCreateSupervisorL2.get('percentage') }
  get descriptionL2() { return this.formCreateSupervisorL2.get('description') }

  isValid(permission: string, permissions: Array<string>){ return isValid(permission, permissions) }

}
