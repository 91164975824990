import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Notfound404Component } from './notfound404/notfound404.component';
import { NotPageFoundComponent } from './not-page-found/not-page-found.component';
import { FormBaseComponent } from './form-base/form-base.component';
import { UpdateThumbnailComponent } from './update-thumbnail/update-thumbnail.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CropperImageComponent } from './cropper-image/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';

const components = [
  NotPageFoundComponent,
  Notfound404Component,
  FormBaseComponent,
  UpdateThumbnailComponent,
  CropperImageComponent
]

@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    TabsModule,
    ImageCropperModule,
  ],
  exports: [components]
})
export class ComponentsModule { }
