import { Component, Input, OnInit } from '@angular/core';
import { base64ToFile, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { USER_UPLOAD_IMAGE_NOT_FORMAT } from '../../constants/user_messages';
import { notifyMessage } from '../../notifications/notifications';
import { ImageCropperService } from '../../services/image-cropper.service';

@Component({
  selector: 'cropper-image',
  templateUrl: './cropper-image.component.html',
})
export class CropperImageComponent implements OnInit {

  constructor(private imageCropper: ImageCropperService) { }

  //Custom properties
  @Input() roundCropper: boolean = true;
  @Input() cropperStaticWidth: number = 200;
  @Input() cropperStaticHeight: number = 200;

  ngOnInit() { }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageCropper.userCroppedImage = true;
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      this.imageCropper.userImageCropped = base64ToFile(this.croppedImage)
  }
  imageLoaded(image: LoadedImage) {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      notifyMessage('error', USER_UPLOAD_IMAGE_NOT_FORMAT, 'top-right')
      this.imageCropper.userCroppedImage = false
  }

}
