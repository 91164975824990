import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function onlyLowerCase(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;
    if (!value) {
      return null;
    }
    if (value.match(new RegExp("[A-Z]"))) {
      return { 'upperFormat': true }
    }
    return null;
  }
}


export function notWhiteSpace() {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;
    if (!value) {
      return null;
    }
    if (value.match(new RegExp("\\s"))) {
      return { 'notSpaceWhite': true };
    }
    return null;
  }
}
