import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, last, tap } from "rxjs/operators";
import { Geopoint } from "../../../../interfaces/geopoint";
import { Order } from "../../../../interfaces/order";
import { ImageStorageService } from "../../../../services/image-storage.service";
import { OrderService } from "../../../../services/order.service";

@Component({
  selector: "app-order-detail-map",
  templateUrl: "./order-detail-map.component.html",
})
export class OrderDetailMapComponent implements OnInit {

  orderDetail$: Observable<Order>;

  orderDetailUid: string;
  origin: Geopoint;
  originTime: number;

  destination: Geopoint;
  destinationTime: number;

  orderPolynine: Geopoint[] = [];
  userOrderAvatar: string;

  constructor(
    private storage: ImageStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.checkUidRoute();
    this.orderObtainDetail();
  }

  checkUidRoute() {
    this.orderDetailUid = this.route.snapshot.params["uid"];
    if (!this.orderDetailUid) this.router.navigate(["/ordenes"]);
  }

  orderObtainDetail() {
    this.orderService.orderDetail(this.orderDetailUid);
    this.orderDetail$ = this.orderService.orderDetail$.pipe(
      filter((value) => value != undefined),
      tap((value) => this.userAvatarObtainUrl(value.clientOrderDetail.user.urlImage)),
      tap((value) => this.orderDetailObtainOriginDestination(value.trips)),
      tap((value) => this.orderDetailObtainAllPolynine(value.route))
    );
  }

  orderDetailObtainOriginDestination(trips) {
    const keys = Object.keys(trips).sort();
    this.origin = { lat: trips[keys[0]]['geopoint']['lat'], lng: trips[keys[0]]['geopoint']['lng'] }
    this.originTime =  trips[keys[0]]['timestamp']

    this.destination = { lat: trips[keys[1]]['geopoint']['lat'], lng: trips[keys[1]]['geopoint']['lng'] }
    this.destinationTime = trips[keys[1]]['timestamp']
  }

  async userAvatarObtainUrl(image: Object) {
    const keys = Object.keys(image);
    for (let i = 0; i < keys.length; i++) {
      if (image[keys[i]].active != false) {
        const publicImage = await this.storage.imageDownloadUrl(
          image[keys[i]].urlImage
        );
        this.userOrderAvatar = publicImage;
      }
    }
  }

  orderDetailObtainAllPolynine(route) {
    const routeKey = Object.keys(route)[0];
    const routeIndex = Object.entries(route[routeKey]).sort();
    routeIndex.forEach((e) =>
      this.orderPolynine.push({
        lat: e[1]["geopoint"]["lat"],
        lng: e[1]["geopoint"]["lng"],
      })
    );
  }
}
