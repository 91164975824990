import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PaymentMethod } from "../../../interfaces/payment";
import { Wallet } from "../../../interfaces/wallet";
import { Observation } from "../../../interfaces/observation";
import {
  StateSupervisor,
  STATE_SUPERVISOR,
} from "../../../constants/state_supervisor";
import { convertCode } from "../../validators/convertCode";
import { Supervisor } from "../../../interfaces/supervisor";
import { SupervisorModel, User, UserModel } from "../../../interfaces/user";
import { supervisorService } from "../../../services/supervisor.service";
import { notifyMessage } from "../../../notifications/notifications";
import Swal from "sweetalert2";
import { UserService } from "../../../services/user.service";
import { PercentageProof } from "../../../interfaces/proof-payment";

@Component({
  selector: "supervisor-edit",
  templateUrl: "./supervisor-edit.component.html",
})
export class SupervisorEditComponent implements OnInit {
  @Input() uid: string;
  supervisorCreateForm: FormGroup;
  user: User;
  states: StateSupervisor[];
  // supervisor: Supervisor;
  objectUpdate: Object;
  userModel: UserModel

  constructor( private formbuilder: FormBuilder, private superService: supervisorService, private userService: UserService ) {
    userService.userSelected.subscribe(user => {
      this.userModel = user
      this.supervisorCreateForm = this.formbuilder.group({
        code: [user?.supervisorL1?.code ? user?.supervisorL1?.code : convertCode(Date.now(), 36)],
        state: [user?.supervisorL1?.state, [Validators.required]],
        isBanned: [user?.supervisorL1?.isBanned || false],
        active: [user?.supervisorL1?.active || false],
      });
    })
  }

  ngOnInit(): void {
    this.states = STATE_SUPERVISOR;
  }

  async supervisorCreate() {
    if (!this.supervisorCreateForm.valid) return false;
    const { isConfirmed } = await Swal.fire({
      title: '¿ Estás seguro de habilitar al usuario como supervisor?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro!',
      cancelButtonText: 'Cancelar'
    })
    if (!isConfirmed) return false;

    const date = new Date()
    const dataSupervisor: SupervisorModel = {
      createdAt: date,
      updatedAt: date,
      code: this.supervisorCreateForm.value.code,
      state: this.supervisorCreateForm.value.state,
      isBanned: this.supervisorCreateForm.value.isBanned,
      active: this.supervisorCreateForm.value.active,
      percentage: {} as PercentageProof
    }

    let permissions = {}
    for(const p of this.userModel?.permissions){ permissions = {...permissions,[p]: p} }
    permissions = {...permissions,['supervisorL1']: 'supervisorL1'}
    permissions = {...permissions,['supervisorL2']: 'supervisorL2'}

    const userData: Partial<UserModel> = {
      supervisorL1: dataSupervisor,
      updatedAt: dataSupervisor.updatedAt,
      uid: this.userModel.uid,
      permissions: Object.values(permissions)
    }
    const { accepted, error } = await this.userService.userUpdateV2(userData);
    console.log('error: ', error);
    if (accepted) {
      notifyMessage("success", "Supervisor creado", "top-right");
    } else {
      notifyMessage( "error", "Error mientras se creaba supervisor", "top-right" );
    }
  }

  async supervisorUpdate() {
    if (!this.supervisorCreateForm.dirty && !this.supervisorCreateForm.valid) return false;
    const { isConfirmed } = await Swal.fire({
      title: "¿Estás seguro de actualizar datos del supervisor?",
      text: "No podrás revertir esta acción.!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, estoy seguro!",
      cancelButtonText: "Cancelar",
      reverseButtons: true
    });

    const date = new Date()
    const dataSupervisor: Partial<SupervisorModel> = {
      updatedAt: date,
      state: this.supervisorCreateForm.value.state,
      isBanned: this.supervisorCreateForm.value.isBanned,
      active: this.supervisorCreateForm.value.active,
      percentage: {
        percentage: 5,
        description: 'Ganancia por carrera de los drivers'
      },
      code: this.userModel?.supervisorL1?.code,
      createdAt: this.userModel?.supervisorL1?.createdAt || date
    }

    if (!isConfirmed) return false;

    const userData: Partial<UserModel> = {
      supervisorL1: dataSupervisor,
      updatedAt: dataSupervisor.updatedAt,
      uid: this.userModel.uid
    }
    const { accepted, error } = await this.userService.userUpdateV2(userData);
    console.log('error: ', error);
    if (accepted) {
      notifyMessage("success", "Supervisor actualizado", "top-right");
    } else {
      notifyMessage("error", "Error mientras se actualiza información del supervisor.", "top-right");
    }
  }

  get code() { return this.supervisorCreateForm.get("code"); }
  get state() { return this.supervisorCreateForm.get("state"); }
  get isBanned() { return this.supervisorCreateForm.get("isBanned"); }
  get active() { return this.supervisorCreateForm.get("active"); }
}
