import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "supervisor-detail-index",
  templateUrl: "./supervisor-table-index.component.html",
})
export class SupervisorDetailIndex implements OnInit {

  userUid: string = "";
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.userUid = this.route.snapshot.params["uid"];
  }
}
