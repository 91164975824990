import { Component, Input, OnInit } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { UserModel } from "../../../../interfaces/user";
import { AuthenticationService } from "../../../../services/authentication.service";
import { DriverService } from "../../../../services/driver.service";
import { UserService } from "../../../../services/user.service";
import { VehicleCreateComponent } from "../../vehicle-create/vehicle-create.component";

@Component({
  selector: "driver-vehicle-table-index",
  templateUrl: "./vehicle-table-index.component.html",
})

export class VehiclesTableIndexComponent implements OnInit{

  @Input() uid: string;
  staff: UserModel
  userModel: UserModel
  constructor(
    private modalService: BsModalService, private auth: AuthenticationService, private userService: UserService) {
      this.auth.staffCurrent.subscribe(staff => this.staff = staff )
      userService.userSelected.subscribe(user => this.userModel = user)
     }
  tbody: any = []

  ngOnInit() {}

  driverAddVehicle() {
    this.modalService.show(VehicleCreateComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
    })
  }
}
