import { Injectable } from "@angular/core";
import { AngularFirestore, QuerySnapshot } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Driver } from "../interfaces/Driver";

@Injectable({
  providedIn: "root",
})
export class DriverService {
  driver$: Observable<any>;
  drivers$: Observable<any[]>;
  vehicles$: Observable<any>;

  constructor(
    private fs: AngularFirestore,
    private storage: AngularFireStorage
  ) { }

  getVehicles(uid: string) {
    this.vehicles$ = this.fs
      .collection("users")
      .doc(uid)
      .collection("detail")
      .doc("driver")
      .snapshotChanges()
      .pipe(map((action) => action.payload.data()));
  }

  async getDrivers(): Promise<any> {
    let driverget: QuerySnapshot<any>;
    let accepted: boolean;
    let drivers: Driver[] = [];
    let error: any;
    try {
      driverget = await this.fs.collection("users").get().toPromise();
    } catch (e) {
      error = e;
    } finally {
      if (error === undefined) {
        accepted = true;
        driverget.docs;
        if (driverget.docs.length > 0) {
          for (let driver of driverget.docs) {
            drivers.push(driver.data());
          }
        } else {
          accepted = false;
        }
      }
    }
    return {
      accepted: accepted,
      data: drivers,
      error: error,
    };
  }

  getDriverAll() {

  }

  async driverObtainByUid(uid: string): Promise<any> {
    let driver: Driver;
    let accepted: boolean = true;
    let error;
    try {
      // const driverQuery  = await this.fs.collection('users').doc(uid).collection<Driver>('detail').doc('driver').get().toPromise();
      // driver = driverQuery.data();
    } catch (err) {
      error = err;
    } finally {
      if (error != 'undefined') {
        accepted = false;
      }
    }
    return { driver, accepted }
  }

  async getDriversActive(): Promise<any> {
    let driverget: QuerySnapshot<any>;
    let accepted: boolean;
    let drivers: Driver[] = [];
    let error: any;
    try {
      driverget = await this.fs
        .collection<Driver>("drivers", (ref) =>
          ref.where("active", "==", true).orderBy("timestamp", "desc")
        )
        .get()
        .toPromise();
    } catch (e) {
      error = e;
    } finally {
      if (error === undefined) {
        accepted = true;
        driverget.docs;
        if (driverget.docs.length > 0) {
          for (let driver of driverget.docs) {
            drivers.push(driver.data());
          }
        }
      } else {
        accepted = false;
      }
    }
    return {
      accepted: accepted,
      data: drivers,
      error: error,
    };
  }

  async getDriver(uid: string) {
    let accepted: boolean;
    let driver: any;
    let error: any;
    try {
      let driverset = await this.fs
        .collection("users")
        .doc(uid)
        .collection("detail")
        .doc("driver")
        .get()
        .toPromise();

      driver = driverset.data();
    } catch (e) {
      error = e;
    } finally {
      if (driver && !error) {
        accepted = true;
      } else {
        accepted = false;
      }
    }
    return {
      accepted: accepted,
      data: driver,
      error: error,
    };
  }

  async driverOBtainByUidObs(uid: string) {
    this.driver$ = this.fs
      .collection("users")
      .doc(uid)
      .collection("detail")
      .doc("driver")
      .snapshotChanges()
      .pipe(map((action) => action.payload.data()));
  }

  /**
   * Update driver
   * @param uid unique to user
   * @param driver object to update in BD
   * @returns object with aceepted type boolean and error
   */
  async updateDriver(uid: string, driver: Object): Promise<any> {
    let accepted: boolean;
    let error: any;
    try {
      await this.fs
        .collection("users")
        .doc(uid)
        .collection("detail")
        .doc("driver")
        .update(driver);
    } catch (e) {
      error = e;
    } finally {
      if (error === undefined) {
        accepted = true;
      } else {
        accepted = false;
      }
    }
    return {
      accepted,
      error,
    };
  }

  async setDriver(driver: Driver) { return this.fs.doc(`drivers/${driver.uid}`).set(driver); }

  async setVehicles(uid: string, vehicles: Object): Promise<any> {
    let accepted: boolean;
    let error: any;
    try {
      await this.fs
        .collection("users")
        .doc(uid)
        .collection("detail")
        .doc("driver")
        .set({ vehicles: { [Date.now()]: vehicles } }, { merge: true });
    } catch (e) {
      error = e;
    } finally {
      if (error === undefined) {
        accepted = true;
      } else {
        accepted = false;
      }
    }
    return {
      accepted,
      error,
    };
  }

  userUploadCloudStorage(uid: string, file: File, directory: string): string {
    const fileString = file.name;
    const extension = fileString.substring(
      fileString.lastIndexOf(".") + 1,
      fileString.length
    );
    const date = Date.now();
    const filename = `${uid}_${date}.${extension}`.trim();
    const urlStorage = `users/${uid}/${directory}/${filename}`;
    this.storage.ref(urlStorage);
    const task = this.storage.upload(urlStorage, file);

    return urlStorage;
  }
}
