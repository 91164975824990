import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Auctioneer } from '../interfaces/auctioneer';
import { Calification } from '../interfaces/calification';
import { Chat } from '../interfaces/chat';
import { Client } from '../interfaces/client';
import { ClientOrderDetail } from '../interfaces/ClientOrderDetail';
import { Driver } from '../interfaces/Driver';
import { DriverOrderDetail } from '../interfaces/DriverOrderDetail';
import { Geopoint } from '../interfaces/geopoint';
import { Observation } from '../interfaces/observation';
import { Order, OrderModel } from '../interfaces/order';
import { PaymentMethod } from '../interfaces/payment';
import { Route } from '../interfaces/route';
import { Supervisor } from '../interfaces/supervisor';
import { SupervisorOrderDetail } from '../interfaces/SupervisorOrderDetail';
import { User } from '../interfaces/user';
import firebase from 'firebase';
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  ordersActive$: Observable<any[]>;
  orders$: Observable<any>;
  orderDetail$: Observable<Order>;

  public orderCollection: AngularFirestoreCollection<any>;

  constructor(private fs: AngularFirestore) {
    this.ordersActive();
    this.orderAll();
  }

  orderAll() {
    this.orders$ = this.fs.collection<any[]>('orders', ref => ref.where('active', '==', true))
      .snapshotChanges().pipe(
        map(actions => actions.map(a => a.payload.doc.data())));
  }

  orderDetail(orderUid: string) {
    this.orderDetail$ = this.fs.collection<Order>('orders').doc(orderUid).snapshotChanges().pipe(
      map(a => a.payload.data()))
  }

  doGetOrderId(orderId: string){ return this.fs.doc<OrderModel>(`orders/${orderId}`).valueChanges(); }

  ordersActive() {
    this.ordersActive$ = this.fs.collection<any[]>('orders', ref => ref.where('active', '==', true))
      .snapshotChanges().pipe(
        map(actions => actions.map(a => a.payload.doc.data())));
  }

  async orderCreate(order: Order): Promise<any> {
    let error;
    let accepted: boolean = false;
    try {
      const uid = this.fs.createId();
      await this.fs.firestore.collection('orders').doc(uid).set({ ...order, uid });

    } catch (err) {
      error = err
    } finally {
      if (error) {
        accepted = false;
      } else {
        accepted = true;
      }
    }
    return { accepted, error }
  }

  async orderCreate2() {
    const id = this.fs.createId();
    const timestamp = Date.now();
    const active = true;
    const status = 'pending';
    const chat = {} as Chat;
    const calification = 0;
    const observations = {};
    const payments = {};
    const driverOrderDetail = {} as DriverOrderDetail;
    const clientOrderDetail = {} as ClientOrderDetail;
    const supervisorOrderDetail = {} as SupervisorOrderDetail;
    const auctioners = {};
    const trips = {};
    const date = 1632401504021;
    const user: User = {
      name: 'Italo',
      birthday: "1999-12-06",
      lastname: 'Barzola García',
      active: true,
      email: 'italo1999@gmail.com',
      gender: true,
      identification: '0985055153',
      isClient: true,
      isDriver: false,
      isSupervisor: false,
      phone: '0958359624',
      session: {},
      urlImage: '',
      createdAt: date,
      uid: 'ain3UaaEfPeqUW9uFWYT3F8Odcb2',
      updatedAt: 1632401504021
    }

    const client: Client = {
      createdAt: Date.now(),
      wallet: {},
      isBanned: false,
      observations: {},
      codeSupervisor: '12121w',
      paymentMethods: {} as PaymentMethod,
      updated: Date.now(),
      isNotification: true
    }

    user.urlImage = 'users/yjO0tge8zPZ3u7y1VWwhaHih4m03/avatar/yjO0tge8zPZ3u7y1VWwhaHih4m03_1633354282666.png'

    trips[1632403804027] = { timestamp: 1632403804027, hash: 'hash', geopoint: { lat: -0.105552, lng: -78.477809, } }
    trips[1632403404031] = { timestamp: 1632403404031, hash: 'hash', geopoint: { lat: -0.105445, lng: -78.478019 } }

    const geohash: Route = {} as Route;
    geohash[1632403404021] = { timestamp: 1632403804027, hash: 'myhash', geopoint: { lat: -0.105552, lng: -78.477809, } };
    geohash[1632403604021] = { timestamp: 1632403404031, hash: 'myhash', geopoint: { lat: -0.105445, lng: -78.478019, } };
    const route = {};
    route[timestamp] = geohash;

    auctioners[1632401404021] = { uid: id, type: 'tipeAuctioner', amount: 5.50, active: true };
    auctioners[1632402404021] = { uid: id, type: 'tipeAuctioner', amount: 6.50, active: true };
    auctioners[1632403404021] = { uid: id, type: 'tipeAuctioner', amount: 4.80, active: true };
    auctioners[1632404404021] = { uid: id, type: 'tipeAuctioner', amount: 3.50, active: true };
    auctioners[1632405404021] = { uid: id, type: 'tipeAuctioner', amount: 7.20, active: true };
    auctioners[1632406404021] = { uid: id, type: 'tipeAuctioner', amount: 6.25, active: true };

    calification[date] = { timestamp: date, value: 4, idOrden: id }

    const orderObject: Order = {
      id,
      createdAt: timestamp,
      route,
      clientOrderDetail,
      driverOrderDetail,
      supervisorOrderDetail,
      status,
      observations,
      calification,
      trips,
      auctioners,
      payments,
      isDeleted: false,
      updatedAt: timestamp,
      isPet: false,
      typeOrder: '',
      bonus: {} as any,
      isChargeCustomer: false
    }
    orderObject.clientOrderDetail = {
      client: client,
      user: user
    }

    const user2: User = {
      name: 'Elian',
      birthday: "1999-12-06",
      lastname: 'Barzola García',
      active: true,
      email: 'italo1999@gmail.com',
      gender: true,
      identification: '0985055153',
      isClient: true,
      isDriver: false,
      isSupervisor: false,
      phone: '0958359624',
      session: {},
      urlImage: '',
      createdAt: date,
      uid: 'ain3UaaEfPeqUW9uFWYT3F8Odcb2',
      updatedAt: 1632401504021
    }

    const driver: Driver = {
      createdAt: new Date(),
      uid: '',
      address: 'Address driver',
      vehicles: {},
      driverUbication: {} as Geopoint,
      urlRecordPolicial: 'record',
      paymentIn: {} as PaymentMethod,
      paymentOut: {} as PaymentMethod,
      state: 'done',
      wallet: {},
      idBanned: false,
      observations: {},
      supervisorDetail: {
        supervisor: {
          active: true,
          address: 'Address supervisor',
          banned: false,
          code: 'w12121',
          observations: {},
          paymentIn: {} as PaymentMethod,
          paymentOut: {} as PaymentMethod,
          state: 'created',
          timestamp: Date.now(),
          updated: Date.now(),
          wallet: {}
        },
        user: {
          name: 'Juan Sebastián',
          birthday: "1999-12-06",
          lastname: 'Hernández Pérez',
          active: true,
          email: 'hern212@gmail.com',
          gender: true,
          identification: '0985055153',
          isClient: true,
          isDriver: false,
          isSupervisor: false,
          phone: '0958359624',
          session: {},
          urlImage: '',
          createdAt: 1632401504021,
          uid: 'ain3UaaEfPeqUW9uFWYT3F8Odcb2',
          updatedAt: 1632401504021
        }
      },
      license: {},
      active: true,
      updatedAt: Date.now(),
    }

    orderObject.driverOrderDetail = {
      driver: driver,
      user: user2
    }

    const user3: User = {
      name: 'Juan Sebastián',
      birthday: "1999-12-06",
      lastname: 'Hernández Pérez',
      active: true,
      email: 'hern212@gmail.com',
      gender: true,
      identification: '0985055153',
      isClient: true,
      isDriver: false,
      isSupervisor: false,
      phone: '0958359624',
      session: {},
      urlImage: '',
      createdAt: 1632401504021,
      uid: 'ain3UaaEfPeqUW9uFWYT3F8Odcb2',
      updatedAt: 1632401504021
    }

    const supervisor: Supervisor = {
      active: true,
      address: 'Address supervisor',
      banned: false,
      code: 'w12121',
      observations: {},
      paymentIn: {} as PaymentMethod,
      paymentOut: {} as PaymentMethod,
      state: 'created',
      timestamp: Date.now(),
      updated: Date.now(),
      wallet: {}
    }

    orderObject.supervisorOrderDetail = {
      supervisor: supervisor,
      user: user3
    }

    this.fs.collection('orders').doc(id).set({ ...orderObject });

  }

  async orderCreate3() {
    const id = this.fs.createId();
    const timestamp = Date.now();
    const active = true;
    const status = 'pending';
    const chat = {} as Chat;
    const calification = 0;
    const observations = {};
    const payments = {};
    const driverOrderDetail = {} as DriverOrderDetail;
    const clientOrderDetail = {} as ClientOrderDetail;
    const supervisorOrderDetail = {} as SupervisorOrderDetail;
    const auctioners = {};
    const trips = {};
    const date = Date.now()
    const user: User = {
      name: 'Jose',
      birthday: "1999-12-06",
      lastname: 'Antonio',
      active: true,
      email: 'joseantho@gmail.com',
      gender: true,
      identification: '0985055153',
      isClient: true,
      isDriver: false,
      isSupervisor: false,
      phone: '0958359624',
      session: {},
      urlImage: '',
      createdAt: 1632401504021,
      uid: 'ain3UaaEfPeqUW9uFWYT3F8Odcb2',
      updatedAt: 1632401504021
    }

    const client: Client = {
      createdAt: Date.now(),
      wallet: {},
      isBanned: false,
      observations: {},
      codeSupervisor: '12121w',
      paymentMethods: {} as PaymentMethod,
      updated: Date.now(),
      isNotification: true
    }

    user.urlImage = 'users/yjO0tge8zPZ3u7y1VWwhaHih4m03/avatar/yjO0tge8zPZ3u7y1VWwhaHih4m03_1633354282666.png'

    const geohash: Route = {} as Route;
    geohash[1632403404021] = { timestamp: 1632403404021, hash: 'myhash', geopoint: { lat: -0.105053, lng: -78.476193 } };
    geohash[1632403504021] = { timestamp: 1632403504021, hash: 'myhash', geopoint: { lat: -0.104865, lng: -78.476445 } };
    geohash[1632403604021] = { timestamp: 1632403604021, hash: 'myhash', geopoint: { lat: -0.104784, lng: -78.476553 } };
    geohash[1632403704021] = { timestamp: 1632403704021, hash: 'myhash', geopoint: { lat: -0.104956, lng: -78.476708 } };
    geohash[1632403804021] = { timestamp: 1632403804021, hash: 'myhash', geopoint: { lat: -0.105259, lng: -78.476911 } };
    geohash[1632403904021] = { timestamp: 1632403904021, hash: 'myhash', geopoint: { lat: -0.105399, lng: -78.477032 } };
    geohash[1632404404021] = { timestamp: 1632404404021, hash: 'myhash', geopoint: { lat: -0.105838, lng: -78.477361 } };
    geohash[1632405404021] = { timestamp: 1632405404021, hash: 'myhash', geopoint: { lat: -0.105873, lng: -78.477383 } };
    geohash[1632406404021] = { timestamp: 1632406404021, hash: 'myhash', geopoint: { lat: -0.105755, lng: -78.477536 } };
    geohash[1632407404021] = { timestamp: 1632407404021, hash: 'myhash', geopoint: { lat: -0.105664, lng: -78.477666 } };
    geohash[1632408404021] = { timestamp: 1632408404021, hash: 'myhash', geopoint: { lat: -0.105552, lng: -78.477809 } };

    const route = {};
    route[timestamp] = geohash;

    trips[1632403404021] = { timestamp: 1632403404021, hash: 'hash', geopoint: { lat: -0.105053, lng: -78.476193 } }
    trips[1632408404021] = { timestamp: 1632408404021, hash: 'hash', geopoint: { lat: -0.105552, lng: -78.477809 } }

    auctioners[timestamp] = { uid: id, type: 'tipeAuctioner', amount: 5.50, active: true };
    auctioners[timestamp] = { uid: id, type: 'tipeAuctioner', amount: 6.50, active: true };

    calification[date] = { timestamp: date, value: 4, idOrden: id }

    const orderObject: Order = {
      id,
      createdAt: timestamp,
      route,
      clientOrderDetail,
      driverOrderDetail,
      supervisorOrderDetail,
      status,
      observations,
      calification,
      trips,
      auctioners,
      payments,
      isDeleted: false,
      updatedAt: timestamp,
      isPet: false,
      typeOrder: '',
      bonus: {} as any,
      isChargeCustomer: false
    }

    orderObject.clientOrderDetail = {
      user: user,
      client: client
    }

    const user2: User = {
      name: 'Freddy',
      birthday: "1999-12-06",
      lastname: 'Castillo Pérez',
      active: true,
      email: 'joseantho@gmail.com',
      gender: true,
      identification: '0985055153',
      isClient: true,
      isDriver: false,
      isSupervisor: false,
      phone: '0958359624',
      session: {},
      urlImage: '',
      createdAt: 1632401504021,
      uid: 'ain3UaaEfPeqUW9uFWYT3F8Odcb2',
      updatedAt: 1632401504021
    }

    const driver: Driver = {
      createdAt: new Date(),
      uid: '',
      address: 'Address driver',
      vehicles: {},
      driverUbication: {} as Geopoint,
      urlRecordPolicial: 'record',
      paymentIn: {} as PaymentMethod,
      paymentOut: {} as PaymentMethod,
      state: 'done',
      wallet: {},
      idBanned: false,
      observations: {},
      supervisorDetail: {
        supervisor: {
          active: true,
          address: 'Address supervisor',
          banned: false,
          code: 'w12121',
          observations: {},
          paymentIn: {} as PaymentMethod,
          paymentOut: {} as PaymentMethod,
          state: 'created',
          timestamp: Date.now(),
          updated: Date.now(),
          wallet: {}
        },
        user: {
          name: 'Juan Sebastián',
          birthday: "1999-12-06",
          lastname: 'Hernández Pérez',
          active: true,
          email: 'hern212@gmail.com',
          gender: true,
          identification: '0985055153',
          isClient: true,
          isDriver: false,
          isSupervisor: false,
          phone: '0958359624',
          session: {},
          urlImage: '',
          createdAt: 1632401504021,
          uid: 'ain3UaaEfPeqUW9uFWYT3F8Odcb2',
          updatedAt: 1632401504021
        }
      },
      license: {},
      active: true,
      updatedAt: Date.now(),
    }

    orderObject.driverOrderDetail = {
      driver: driver,
      user: user2
    }

    const supervisor: Supervisor = {
      active: true,
      address: 'Address supervisor',
      banned: false,
      code: 'w12121',
      observations: {},
      paymentIn: {} as PaymentMethod,
      paymentOut: {} as PaymentMethod,
      state: 'created',
      timestamp: Date.now(),
      updated: Date.now(),
      wallet: {}
    }

    const user3: User = {
      name: 'Juan Sebastián',
      birthday: "1999-12-06",
      lastname: 'Hernández Pérez',
      active: true,
      email: 'hern212@gmail.com',
      gender: true,
      identification: '0985055153',
      isClient: true,
      isDriver: false,
      isSupervisor: false,
      phone: '0958359624',
      session: {},
      urlImage: '',
      createdAt: 1632401504021,
      uid: 'ain3UaaEfPeqUW9uFWYT3F8Odcb2',
      updatedAt: 1632401504021
    }

    orderObject.supervisorOrderDetail = {
      supervisor: supervisor,
      user: user3
    }

    this.fs.collection('orders').doc(id).set({ ...orderObject });

  }
}
