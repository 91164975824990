import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor( private auth: AuthenticationService, private router: Router ) {
  }
  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.staff$.pipe(
      take(1),
      map((user: any) => {
        let permissions = {}
        if(user.length && user[0] && user[0]?.permissions){ for(const p of user[0]?.permissions){ permissions = {...permissions,[p]: true} } }
        if (!next.data.roles.some((rol)=>permissions[rol]===true)) { this.router.navigateByUrl('/404'); }
        return user !== undefined
      })
    )

  }
}


