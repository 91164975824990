import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observation } from "../../../interfaces/observation";
import { UserGenericServiceService } from "../../../services/user-generic-service.service";

@Component({
  selector: "observation-edit",
  templateUrl: "./observation-edit-form.component.html",
})
export class ObservationEditFormComponent implements OnInit {
  @Input() uid: string="a9auaPVP1hzsllpUj2qN";
  @Input() document: string
  @Input() objeto: any;

  form: FormGroup;
  showForm: boolean = false;
  flat: Boolean;
  observations: Observation[];


  constructor(
    private formbuilder: FormBuilder,
    private genericService: UserGenericServiceService
  ) {
    this.form = this.formbuilder.group({
      description: ["", [Validators.required]],
    });
  }

  ngOnInit() {
  }

  async handleNewForm() {
    this.showForm = !this.showForm;
  }
  async saveNewObservation() {
    const {accepted, error}= await this.genericService.setObservation(this.uid, this.newObjectObservation(), this.document)
    if (accepted) alert("Observación guardada exitosamente")
    console.log(accepted,error)
  }

  newObjectObservation(){
    let date = Date.now();
    let obj:Observation={timestamp: date, description: this.form.get('description').value}
    return obj;
  }
}
