export interface TransactionType{
  name: string,
  value: string,

}

export const TRANSACTION_TYPE: TransactionType[] = [
  {
    name: 'career',
    value: 'Carrera'
  },
  {
    name: 'shipment',
    value: 'Flete'
  },
  {
    name: 'delivery',
    value: 'Envío'
  }
]

export const TRANSACTION_STATUS = {
  created: {index: 'created', value: 'Created'},
  toCheked: {index: 'toCheked', value: 'To Cheked'},
  done: {index: 'done', value: 'Done'},
  cancelled: {index: 'cancelled', value: 'Cancelled'},
}