import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  USER_NOT_SOUCH_FOUND,
  USER_UPLOAD_IMAGE_ERROR,
  USER_UPLOAD_IMAGE_SUCCESS,
} from "../../constants/user_messages";
import { UploadStorage } from "../../interfaces/UploadStorage";
import { notifyMessage, sendErrorMessage } from "../../notifications/notifications";
import { ImageCropperService } from "../../services/image-cropper.service";
import { ImageStorageService } from "../../services/image-storage.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-update-thumbnail",
  templateUrl: "./update-thumbnail.component.html",
})

export class UpdateThumbnailComponent implements OnInit {
  loading: boolean = false;
  public avatarDefault: string = "assets/img/avatars/no-image.png";
  public thumbnailsUrl: string[] = [];
  // Properties received component in initial state.
  thumbnails: Object;
  uid: string;

  constructor(
    public bsModalRef: BsModalRef,
    public userService: UserService,
    public cropperService: ImageCropperService,
    private storage: ImageStorageService
  ) { }

  ngOnInit(): void {
    this.userObtainImages();
  }

  userObtainImages() {
    const keys = Object.keys(this.thumbnails);
    keys.map(async (value) => {
      const image = await this.obtainRoute(this.thumbnails[value].urlImage);
      this.thumbnailsUrl.push(image);
    });
  }

  async obtainRoute(photo: string) {
    return await this.storage.imageDownloadUrl(photo);
  }

  async updateAvatar() {
    this.loading = true;
    //Obtain data detail user from array data
    try {
      const { user } = await this.userService.userObtainByUid(this.uid);
      if (!user) return false;
      // Upload cloudstore return url storage
      const uploadStorage: UploadStorage = {
        uid: this.uid,
        file: this.cropperService.userImageCropped,
        folder: "users",
        directory: "avatar",
      };
      const URLStorage = await this.storage.uploadCloudStorage(uploadStorage);
      // Deactive images
      const keys = Object.keys(user.thumbnails);
      for (let i = 0; i < keys.length; i++) {
        user.thumbnails[keys[i]].active = false;
      }
      // Creating object with new data and urlstorage
      const date = Date.now();
      user.thumbnails[date] = {
        active: true,
        timestamp: date,
        urlImage: URLStorage,
      };
      // Check percentage upload if image is uploaded
      const percentage = await this.storage.percentage.toPromise();
      if (percentage === 100) {
        this.cropperService.userCroppedImage = false;
        await this.userService.userUpdate(this.uid, { thumbnails: user.thumbnails });
        notifyMessage("success", USER_UPLOAD_IMAGE_SUCCESS, null);
        this.loading = false;
        this.bsModalRef.hide();
      } else {
        sendErrorMessage('No se pudo subir imagen')
      }
    } catch (error) {
      sendErrorMessage('No se pudo subir imagen')
    }
  }
}
