import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseAuth } from '@firebase/auth-types';
import { Client } from '../interfaces/client';
import { Supervisor } from '../interfaces/supervisor';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private fs: AngularFirestore) { }

  async clientObtainByUid(uid: string): Promise<any> {
    let client: Client;
    let accepted: boolean = true;
    let error;
    try {
      // const clientQuery = await this.fs.collection('users').doc(uid).collection<Client>('detail').doc('client').get().toPromise();
      // client = clientQuery.data();
    } catch (err) {
      error = err;
    } finally {
      if (error != 'undefined') {
        accepted = false;
      }
    }
    return { client, accepted }
  }
}
