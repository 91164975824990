import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {IconSetService } from '@coreui/icons-angular';

import { AppComponent } from './app.component';

// Import container

import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { UserModule } from './modules/user/user.module';
import { AuthenticationModule } from './modules/authentication/authentication.module'
import { DriverModule } from './modules/driver/driver.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SupervisorModule } from './modules/supervisor/supervisor.modules';
import { OrderModule } from './modules/order/order.module';
import { NavigationModule } from './modules/navigation/navigation.module';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ComponentsModule } from './components/components.module';
import { SharedModule } from './modules/shared/shared.module';
import { AccountModule } from './modules/account/account.module';
// import { GooglePlaceModule } from "ngx-google-places-autocomplete";
// import { MapsModule } from './modules/maps/maps.module';
import { FlashMessagesModule } from 'flash-messages-angular';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireStorageModule,
    ModalModule.forRoot(),
    ComponentsModule,
    UserModule,
    DriverModule,
    AuthenticationModule,
    OrderModule,
    DashboardModule,
    NavigationModule,
    SupervisorModule,
    AccountModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    // GooglePlaceModule,
    // MapsModule,
    AgmCoreModule.forRoot({ apiKey: environment.googleMapConfig.apiKey, libraries: ['places', 'drawing', 'geometry']}),
    HttpClientModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    IconSetService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
