import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Staff } from "../interfaces/Staff";
import { AuthenticationService } from "./authentication.service";

export interface StaffResponse {
  created: boolean;
  error: string;
}

@Injectable({
  providedIn: "root",
})
export class StaffService {
  staff$: Observable<Staff[]>;
  private userCollection: AngularFirestoreCollection<Staff>;

  constructor(
    private auth: AuthenticationService,
    private fs: AngularFirestore
  ) {
    this.userCollection = this.fs.collection<Staff>("staff");
    this.staffAll();
  }

  staffAll() {
    this.staff$ = this.userCollection
      .snapshotChanges()
      .pipe(map((values) => values.map((value) => value.payload.doc.data())));
  }

  async staffCreate(staff: Staff): Promise<StaffResponse> {
    let error: string;
    let created: boolean;
    try {
      const { created, error, uidSession } = await this.auth.userCreateWithEmailAndPassword(staff.email);
      if (!created) {
        return { created, error };
      }
      await this.fs.collection("staff").doc(uidSession).set({ ...staff, uid: uidSession });
    } catch (err) {
      error = err;
    } finally {
      if (!error) {
        created = true;
      } else {
        created = false;
      }
    }
    return { created, error };
  }

  async staffUpdate(uid: string, data: Object): Promise<any> {
    let error: Error;
    let accepted: boolean;
    try {
      const updated = Date.now();
      await this.fs
        .collection("staff")
        .doc(uid)
        .update({ ...data, updated });
    } catch (err) {
      error = err;
    } finally {
      if (!error) {
        accepted = true;
      } else {
        accepted = false;
      }
    }
    return { accepted, error };
  }
}
