import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UploadStorage } from '../../../interfaces/UploadStorage';
import { UserModel } from '../../../interfaces/user';
import { ImageStorageService } from '../../../services/image-storage.service';
import { UserService } from '../../../services/user.service';
import { notifyMessage, sendErrorMessage } from '../../../notifications/notifications';
import { License } from '../../../interfaces/license'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-license',
  templateUrl: './modal-license.component.html',
  styleUrls: ['./modal-license.component.scss']
})
export class ModalLicenseComponent implements OnInit  {

  @Input() modalRef: BsModalRef;
  @Input() listImages: any;
  auxImages: any = [];
  croppedImage: any;
  userModel: UserModel
  licenseCreateForm: FormGroup

  componentImage = [
    { key: 'img', title: 'Arrastra aquí la foto de la licencia' },
  ]

  constructor(private storage: ImageStorageService, private userService: UserService, private formbuilder: FormBuilder) {
    userService.userSelected.subscribe(user => this.userModel = user)
    this.iniForm()
   }

  ngOnInit() {
  }

  iniForm(){
    this.licenseCreateForm = this.formbuilder.group({
      expiration: ['', [Validators.required]]
    })
  }

  handleCropped(cropped: any) {
    this.croppedImage = { ...this.croppedImage, ...cropped }
  }

  async saveImgLicense(){
    try {
      if (!this.licenseCreateForm.valid) return false;
      let arrUrl = await this.storage.uploadImageFiles(this.userModel.uid, Object.values(this.croppedImage), 'license');
      const percentage = await this.storage.percentage.toPromise();
      if (percentage === 100) {
        const imag = await this.storage.imageDownloadUrl(arrUrl[0].img)
        const license: License = {
          createdAt: new Date(),
          active: true,
          urlImage: imag,
          expiration: new Date(this.licenseCreateForm.value.expiration)
        }

        this.userModel.driver['license'] = license
        this.userModel.driver.updatedAt = new Date()

        const userData: Partial<UserModel> = {
          driver: this.userModel.driver,
          updatedAt: this.userModel.driver.updatedAt,
          uid: this.userModel.uid
        }
    
        const { accepted, error } = await this.userService.userUpdateV2(userData);
        if (accepted) {
          notifyMessage('success', 'Licencia subida exitosamente', 'top-right')
        }
      } else {
        notifyMessage('error', 'Error al subir la licencia', null)
      }
    } catch (error) {
      notifyMessage('error', 'Error al subir la licencia', null)
    }
  }

}
