import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { UpdateThumbnailComponent } from '../../../components/update-thumbnail/update-thumbnail.component';
import { User } from '../../../interfaces/user';
import { notifyMessage } from '../../../notifications/notifications';
import { UserService } from '../../../services/user.service';
import { dateValid } from '../../validators/date';

@Component({
  selector: 'app-account-perfil',
  templateUrl: './account-perfil.component.html',
})
export class AccountPerfilComponent implements OnInit {
  userEditForm: FormGroup;
  user: User;

  constructor(private userService: UserService,
    private firestoreAuth: AngularFireAuth,
    private modalService: BsModalService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.userEditForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      birthday: [new Date(1995, 11, 17).toISOString().substring(0, 10), [Validators.required, dateValid()]],
      identification: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
    }, { updateOn: 'blur' });
    this.getUserDetail();
  }

  async userUpdate() {
    const { isConfirmed } = await Swal.fire({
      title: '¿Estás seguro de actualizar tu información?',
      text: "No podrás revertir esta acción.!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    })
    if (!isConfirmed) return false;

    const name = this.userEditForm.get('name').value;
    const lastname = this.userEditForm.get('lastname').value;
    const phone = this.userEditForm.get('phone').value;
    const birthday = this.userEditForm.get('birthday').value;

    const data = { name, lastname, phone, birthday };
    const { accepted } = await this.userService.userUpdate(this.user.uid, data);
    if (!accepted) {
      notifyMessage('error', 'No se ha podido actualizar información.', 'top-right');
      return false;
    }
    notifyMessage('success', 'Información actualizada correctamente.', 'top-right');
  }

  async getUserDetail() {
    const currentUser = await this.firestoreAuth.currentUser;
    console.log("current user --", currentUser)
    const { email, uid } = await this.firestoreAuth.currentUser;
    if (!email) return false;
    const { user } = await this.userService.userObtainByUid(uid);
    this.user = user;
    this.userEditForm.setValue({
      name: this.user?.name,
      lastname: this.user?.lastname,
      email: this.user?.email,
      identification: this.user?.identification,
      phone: this.user?.phone,
      birthday: this.user?.birthday
    })
  }

  userOpenModalUpdateAvatar() {
    const initialState = {
      thumbnails: this.user.urlImage,
      uid: this.user.uid,
    }
    this.modalService.show(UpdateThumbnailComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      initialState
    });
  }

  get name() { return this.userEditForm.get('name') };
  get lastname() { return this.userEditForm.get('lastname') };
  get email() { return this.userEditForm.get('email') };
  get phone() { return this.userEditForm.get('phone') };
  get identification() { return this.userEditForm.get('identification') };
  get birthday() { return this.userEditForm.get('birthday') };
  get gender() { return this.userEditForm.get('gender') };

}
