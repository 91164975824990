import { Component } from '@angular/core';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'app-order-index',
  templateUrl: './order-index.component.html',
})
export class OrderIndexComponent {

  orders$ = this.orderService.orders$;
  constructor(private orderService: OrderService) { }
}
