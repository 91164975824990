import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SETTING_PERCENTAGE } from '../../../constants/general';
import { TYPE_PHOTO_CAR, TYPE_VEHICLES } from '../../../constants/vihecles';
import { DriverModel, UserModel } from '../../../interfaces/user';
import { TypeVehicle, Vehicle } from '../../../interfaces/vehicle';
import { notifyMessage } from '../../../notifications/notifications';
import { DriverService } from '../../../services/driver.service';
import { ImageStorageService } from '../../../services/image-storage.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-vehicle-create',
  templateUrl: './vehicle-create.component.html',
})
export class VehicleCreateComponent implements OnInit {

  componentImage = [
    { key: TYPE_PHOTO_CAR.frontVehicle.index , title:'Arrastra aquí foto del frontal del vehículo'},
    { key: TYPE_PHOTO_CAR.frontLicensePlate.index, title:'Arrastra aquí foto frontal de la matrícula' },
    { key: TYPE_PHOTO_CAR.backLicensePlate.index, title:'Arrastra aquí foto trasera de la matrícula' }
  ]

  vehicleCreateForm: FormGroup;
  croppedImage: any = {};
  vehicle: Vehicle;
  userModel: UserModel
  typeVehiclesArray = Object.values(TYPE_VEHICLES)
  typeVehicles = TYPE_VEHICLES

  constructor( public bsModalRef: BsModalRef, private formbuilder: FormBuilder, private storage: ImageStorageService, private driverService: DriverService,
    private userService: UserService) {
      userService.userSelected.subscribe(user => this.userModel = user)
    this.vehicleCreateForm = this.formbuilder.group({
      model: ["", [Validators.required]],
      color: ["", [Validators.required]],
      plate: ["", [Validators.required]],
      year: ["", [Validators.required]],
      cylinder: ["", [Validators.required]],
      typeVehicle: ['', [Validators.required]],
      motocycle: [''],
      auto: [''],
      truckName: [''],
      truckWeght: [''],
      truckVolume: [''],
      truckAxis: [''],
      truckHigh: [],
      truckWidth: [],
      truckLong: [],
      percentage: [SETTING_PERCENTAGE.percentage, [Validators.required]],
      description: [SETTING_PERCENTAGE.description, [Validators.required]]
    })
  }

  ngOnInit(): void {
  }

  get model() { return this.vehicleCreateForm.get('model') }
  get plate() { return this.vehicleCreateForm.get('plate') }
  get color() { return this.vehicleCreateForm.get('color') }
  get year() { return this.vehicleCreateForm.get('year') }
  get typeVehicle() { return this.vehicleCreateForm.get('typeVehicle') }
  get cylinder() { return this.vehicleCreateForm.get('cylinder') }
  get truckName() { return this.vehicleCreateForm.get('truckName') }
  get truckWeght() { return this.vehicleCreateForm.get('truckWeght') }
  get truckVolume() { return this.vehicleCreateForm.get('truckVolume') }
  get truckAxis() { return this.vehicleCreateForm.get('truckAxis') }
  get truckHigh() { return this.vehicleCreateForm.get('truckHigh') }
  get truckWidth() { return this.vehicleCreateForm.get('truckWidth') }
  get truckLong() { return this.vehicleCreateForm.get('truckLong') }
  get percentage() { return this.vehicleCreateForm.get('percentage') }
  get description() { return this.vehicleCreateForm.get('description') }


  handleCropped(cropped: any) {
    this.croppedImage = { ...this.croppedImage, ...cropped }
  }


  async addVehicle() {
    if (!this.vehicleCreateForm.valid) return false;

    let typeVehicle: TypeVehicle = {} as TypeVehicle
    switch (this.vehicleCreateForm.value.typeVehicle.index) {
      case this.typeVehicles?.auto?.index:
        typeVehicle['type'] = this.vehicleCreateForm.value.typeVehicle.index
        typeVehicle['name'] = this.vehicleCreateForm.value.auto
        break;
      case this.typeVehicles?.motocycle?.index:
        typeVehicle['type'] = this.vehicleCreateForm.value.typeVehicle.index
        typeVehicle['name'] = this.vehicleCreateForm.value.motocycle
        break;
      case this.typeVehicles?.truck?.index:
        typeVehicle['type'] = this.vehicleCreateForm.value.typeVehicle.index
        typeVehicle['name'] = this.vehicleCreateForm.value.truckName
        typeVehicle['weight'] = this.vehicleCreateForm.value.truckWeght
        typeVehicle['axis'] = this.vehicleCreateForm.value.truckAxis
        typeVehicle['measures'] = {
          high: this.vehicleCreateForm.value.truckHigh,
          width: this.vehicleCreateForm.value.truckWidth,
          long: this.vehicleCreateForm.value.truckLong,
        }
        break;
    
      default:
        break;
    }

    const url: any = await this.uploadImages();
    console.log('url: ', url);
    const percentage = await this.storage.percentage.toPromise();
    let img: any;
    if (percentage === 100) {
      for (let item in url) {
        img = {
          ...img,[url[item].date]:{
          timestamp: url[item].date, 
          urlImage: await this.storage.imageDownloadUrl(url[item].img), 
          active: false,
          type: item
          }
        }
      }
    }

    const newVehicle: Vehicle = {
      year: this.vehicleCreateForm.value.year,
      photoCar: Object.values(img),
      plate: this.vehicleCreateForm.value.plate,
      model: this.vehicleCreateForm.value.model,
      color: this.vehicleCreateForm.value.color,
      cylinder: this.vehicleCreateForm.value.cylinder,
      typeVehicle,
      enabled: true,
      percentage: {
        percentage: this.vehicleCreateForm.value.percentage,
        description: this.vehicleCreateForm.value.description,
      }
    }

    let vehiclesActual = this.userModel?.driver?.vehicles || {}
    vehiclesActual = {...vehiclesActual, [Date.now()]: newVehicle}
    /* const driverData: Partial<DriverModel> = {
      vehicles: Object.values(vehiclesActual),
      updatedAt: new Date()
    } */
    this.userModel.driver['vehicles'] = Object.values(vehiclesActual)
    this.userModel.driver.updatedAt = new Date()

    const userData: Partial<UserModel> = {
      driver: this.userModel.driver,
      updatedAt: this.userModel.driver.updatedAt,
      uid: this.userModel.uid
    }

    const { accepted, error } = await this.userService.userUpdateV2(userData);
    if (accepted) {
      notifyMessage('success', 'Vehículo agregado', 'top-right');
    } else {
      notifyMessage('error', 'No se pudo subir información del vehículo', 'top-right');
    }
  }

  uploadImages() {
    let arrImages = this.croppedImage;
    let arrUrl = this.storage.uploadImageFiles(this.userModel.uid, arrImages, 'photoCar');
    return arrUrl;
  }

  onChangeTypeVihecle(typeVehicle: any){
    switch (typeVehicle.index) {
      case this.typeVehicles.auto.index:
        this.vehicleCreateForm.controls['truckName'].clearValidators()
        this.vehicleCreateForm.controls['truckWeght'].clearValidators()
        this.vehicleCreateForm.controls['truckVolume'].clearValidators()
        this.vehicleCreateForm.controls['truckAxis'].clearValidators()
        this.vehicleCreateForm.controls['truckHigh'].clearValidators()
        this.vehicleCreateForm.controls['truckWidth'].clearValidators()
        this.vehicleCreateForm.controls['truckLong'].clearValidators()

        this.vehicleCreateForm.controls['truckName'].setValue('')
        this.vehicleCreateForm.controls['truckWeght'].setValue('')
        this.vehicleCreateForm.controls['truckVolume'].setValue('')
        this.vehicleCreateForm.controls['truckAxis'].setValue('')
        this.vehicleCreateForm.controls['truckHigh'].setValue('')
        this.vehicleCreateForm.controls['truckWidth'].setValue('')
        this.vehicleCreateForm.controls['truckLong'].setValue('')

        this.vehicleCreateForm.controls['motocycle'].clearValidators()
        // this.vehicleCreateForm.controls['auto'].setValidators([Validators.required])
        break;
      case this.typeVehicles.motocycle.index:
        this.vehicleCreateForm.controls['truckName'].clearValidators()
        this.vehicleCreateForm.controls['truckWeght'].clearValidators()
        this.vehicleCreateForm.controls['truckVolume'].clearValidators()
        this.vehicleCreateForm.controls['truckAxis'].clearValidators()
        this.vehicleCreateForm.controls['truckHigh'].clearValidators()
        this.vehicleCreateForm.controls['truckWidth'].clearValidators()
        this.vehicleCreateForm.controls['truckLong'].clearValidators()

        this.vehicleCreateForm.controls['truckName'].setValue('')
        this.vehicleCreateForm.controls['truckWeght'].setValue('')
        this.vehicleCreateForm.controls['truckVolume'].setValue('')
        this.vehicleCreateForm.controls['truckAxis'].setValue('')
        this.vehicleCreateForm.controls['truckHigh'].setValue('')
        this.vehicleCreateForm.controls['truckWidth'].setValue('')
        this.vehicleCreateForm.controls['truckLong'].setValue('')

        this.vehicleCreateForm.controls['auto'].clearValidators()
        // this.vehicleCreateForm.controls['motocycle'].setValidators([Validators.required])
        
        break;
      case this.typeVehicles.truck.index:
        this.vehicleCreateForm.controls['truckName'].setValidators([Validators.required])
        this.vehicleCreateForm.controls['truckWeght'].setValidators([Validators.required])
        this.vehicleCreateForm.controls['truckVolume'].setValidators([Validators.required])
        this.vehicleCreateForm.controls['truckAxis'].setValidators([Validators.required])
        this.vehicleCreateForm.controls['truckHigh'].setValidators([Validators.required])
        this.vehicleCreateForm.controls['truckWidth'].setValidators([Validators.required])
        this.vehicleCreateForm.controls['truckLong'].setValidators([Validators.required])
        this.vehicleCreateForm.controls['auto'].clearValidators()
        this.vehicleCreateForm.controls['motocycle'].clearValidators()

        break;
    }

  }
}
