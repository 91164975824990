import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Staff } from '../../../interfaces/Staff';
import { User, UserModel } from '../../../interfaces/user';
import { AuthenticationService } from '../../../services/authentication.service';
import { StaffService } from '../../../services/staff.service';
import { UserService } from '../../../services/user.service';
import { isValid } from '../../../utils/general.utils';

@Component({
  selector: 'dashboard-index',
  templateUrl: './index.component.html',
})
export class DashboardIndexComponent implements OnInit {
  staff: UserModel

  constructor(private auth: AuthenticationService
  ) { 
    auth.staffCurrent.subscribe(staff => this.staff = staff )
  }

  ngOnInit(): void {

  }

  isValid(permission: string, permissions: Array<string>){ return isValid(permission, permissions) }

}
