import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'staff-today',
  templateUrl: './staff-today.component.html',
})
export class StaffTodayComponent implements OnInit {
  @Input() name: string;
  @Input() lastname: string;
  @Input() timestamp: number;
  @Input() active: number;
  constructor() { }

  ngOnInit(): void {
  }

}
