export interface StateSupervisor{
  name: string,
  value: string,
}

export const STATE_SUPERVISOR: StateSupervisor[]=[{
  name: 'CREADO',
  value: 'create'
},
{
  name: 'PENDIENTE',
  value: 'pending'
},
{
  name: 'CANCELADO',
  value: 'canceled'
},
{
  name: 'APROBADO',
  value: 'aprobated'
}]
