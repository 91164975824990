import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderRouting } from './user-routing.module';
import { OrdersMapComponent } from './orders-map/orders-map.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { OrderTableComponent } from './order-table/order-table.component';
import { environment } from '../../../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderMapIndexComponent } from './index/order-map-index/order-map-index.component';
import { OrderTableIndexComponent } from './index/order-table-index/order-table-index.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { OrderIndexComponent } from './index/order-index/order-index.component';
import { OrderDetailMapComponent } from './detail/order-detail-map/order-detail-map.component';
import { OrderDetailComponent } from './detail/order-detail-table/order-detail.component';
import { OrderCreateComponent } from './order-create/order-create.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { ComponentsModule } from '../../components/components.module';
import { MapsModule } from '../maps/maps.module';
import { AlertModule } from 'ngx-bootstrap/alert';

const components = [
  OrdersMapComponent,
  OrderTableComponent,
  OrderMapIndexComponent,
  OrderTableIndexComponent,
  OrderDetailComponent,
  OrderDetailMapComponent,
  OrderIndexComponent,
  OrderCreateComponent
]

@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    TooltipModule,
    ComponentsModule,
    OrderRouting,
    MapsModule,
    AgmCoreModule,
    AutocompleteLibModule,
    GooglePlaceModule,
    AlertModule.forRoot(),
  ],
  exports: [components]
})
export class OrderModule { }
