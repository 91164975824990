import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationIndexComponent } from './navigation-index/index.component';
import { NavigationRoutingModule } from './navigation-routing.module';
import { TabsModule } from 'ngx-bootstrap/tabs';

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UserOptionsComponent } from './user-options/user-options.component';
import { UserNotificationsComponent } from './user-notifications/user-notifications.component';
import { UsersTodayComponent } from './notification-today/users-today/users-today.component';
import { NotificationTodayIndexComponent } from './notification-today/notification-today-index/notification-today-index.component';
import { StaffTodayComponent } from './notification-today/staff-today/staff-today.component';

const components = [
  NavigationIndexComponent,
  UserOptionsComponent,
  UserNotificationsComponent,
  UsersTodayComponent,
  NotificationTodayIndexComponent,
  StaffTodayComponent
]

@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    AppSidebarModule,
    AppBreadcrumbModule.forRoot(),
    AppHeaderModule,
    TabsModule,
    BsDropdownModule,
    PerfectScrollbarModule,
    AppFooterModule,
    AppAsideModule,
    NavigationRoutingModule,
  ],
  exports: [components]
})
export class NavigationModule { }
