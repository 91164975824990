import { INavData } from "@coreui/angular";

export const navigation_modules: any[] = [
  {
    name: "Usuarios",
    roles: ['staff', 'admin','supervisorL1'],
    url: "/usuarios",
    icon: "fa fa-users",
    children: [
      {
        name: "Usuario",
        url: "/usuarios",
        icon: "fa fa-users",
        roles: ['staff', 'admin','supervisorL1'],
      },
      {
        name: "Drivers",
        url: "/drivers",
        icon: "fa fa-car",
        roles: ['staff', 'admin','supervisorL1'],
      },
    ],
  },
  {
    name: "Conductores",
    url: "/conductores",
    icon: "fa fa-user-circle",
  },
  {
    title: true,
    name: "Transacciones",
  },
  {
    name: "Tabla de transacciones",
    url: "/transacciones",
    icon: "fa fa-money",
    roles: ['staff', 'admin','supervisorL1'],
  },
  {
    name: "Pagos",
    url: "/pagos",
    icon: "fa fa-id-card-o",
    roles: ['staff', 'admin','supervisorL1','digitizers'],
  },
  {
    title: true,
    name: "Órdenes",
    roles: ['staff', 'admin','supervisorL1'],
  },
  {
    name: "Tabla de órdenes",
    url: "/ordenes/tabla",
    icon: "fa fa-table",
    roles: ['staff', 'admin','supervisorL1'],
  },
  {
    name: "Órdenes tiempo real",
    url: "/ordenes/mapa",
    icon: "fa fa-map-marker",
  },
  {
    title: true,
    name: "Financiero",
    roles: ['admin','counter'],
  },
  {
    name: "Cierres",
    url: "/finance",
    icon: "fa fa-money fa-lg",
    roles: ['admin','counter'],
    children: [
      {
        name: "Listado",
        url: "/finance/list",
        icon: "fa fa-list-ul",
        roles: ['admin','counter'],
      },
    ],
  },
  {
    title: true,
    name: "Sistema",
    roles: ['admin'],
  },
  {
    name: "Sistema",
    url: "/sistema",
    icon: "fa fa-desktop",
    roles: ['admin'],
    children: [
      // {
      //   name: "Aplicación",
      //   url: "/sistema/aplicacion",
      //   icon: "fa fa-map-marker",
      //   roles: [],
      // },
      // {
      //   name: "General",
      //   url: "/sistema/general",
      //   icon: "fa fa-map-marker",
      //   roles: [],
      // },
      // {
      //   name: "Landing Page",
      //   url: "/sistema/landing",
      //   icon: "fa fa-map-marker",
      //   roles: [],
      // },
      {
        name: "Tipos de Vehiculos",
        url: "/sistema/tipo-vehiculo",
        icon: "fa fa-car",
        roles: ['admin'],
      },
    ],
  },
];

export const navItems: INavData[] = [
  {
    name: "Administración",
    url: "",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "Ver",
    },
    roles: ['staff', 'admin','supervisorL1','supervisorL2','counter','digitizers']
  },
  ...navigation_modules,
];
