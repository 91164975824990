import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DriverRoutingModule } from './driver.routing.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { VehicleCreateComponent } from './vehicle-create/vehicle-create.component';
import { VehiclesTableIndexComponent } from './index/vehicle-table-index/vehicle-table-index.component';
import { SharedModule } from '../shared/shared.module';
import { DriverVehicleTable } from './vehicle-table/vehicle-table.component';
import { DriverEditComponent } from './driver-edit/driver-edit.component';
import { DriverTableComponent } from './driver-table/driver-table.component';
import { DriverCreateComponent } from './driver-create/driver-create.component';

const components = [ 
  // VehicleCreateComponent, 
  // DriverVehicleTable, 
  // VehiclesTableIndexComponent, 
  // DriverEditComponent,
  // DriverTableComponent,
  DriverCreateComponent,
  
]
@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    DriverRoutingModule,
    SharedModule,
    ModalModule.forRoot()
  ],
  exports: [components]
})
export class DriverModule { }
