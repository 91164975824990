import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { USER_FILTER_TYPE } from '../../../constants/user'
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'user-user-search',
  templateUrl: './user-search.component.html',
})
export class UserSearchComponent implements OnInit {

  ngOnInit() { }

  constructor(private userService: UserService) { this.searchValueChanges() }

  filters = USER_FILTER_TYPE;

  search = new FormControl('');
  typeFilter = new FormControl(0);

  usersFilter(param: string) {
    // set default filter email when a user not selected a option.
    // With this param set the filter.
    if (this.typeFilter.value == 0) {
      this.typeFilter.setValue('email')
    }

  }

  searchValueChanges() {
    this.search.valueChanges.pipe(
      map(search =>
        search?.toLowerCase().trim()),
      debounceTime(300),
      distinctUntilChanged(),
      filter(search => search != '' && search?.length > 2),
      tap(search => this.usersFilter(search))
    ).subscribe()
  }

  changeQueryFilter(e) {
    this.typeFilter.setValue(e.target.value, { onlySelf: true })
  }
}
