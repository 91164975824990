import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageStorageService } from '../../../services/image-storage.service';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss']
})
export class ModalImageComponent implements OnInit {

  @Input() modalRef: BsModalRef;
  @Input() listImages: any;
  auxImages: any = [];
  croppedImage: any;

  componentImage = [
    { key: 'img', title: 'Arrastra aquí foto del vehículo' },
  ]

  constructor(private storage: ImageStorageService) { }

  ngOnInit() {
    this.urlChange()
  }

  async urlChange() {
    for (let element of this.listImages) {
      // let urlImage = await this.storage.imageDownloadUrl(element.urlImage)
      this.auxImages.push({ ...element, urlImage: element.urlImage })
    }
  }

  handleCropped(cropped: any) {
    this.croppedImage = { ...this.croppedImage, ...cropped }
    console.log(this.croppedImage)
  }

}
