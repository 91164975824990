import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageCropperService {

  public userCroppedImage: boolean = false;
  public userImageCropped: Blob

}
