import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderDetailMapComponent } from './detail/order-detail-map/order-detail-map.component';
import { OrderDetailComponent } from './detail/order-detail-table/order-detail.component';
import { OrderMapIndexComponent } from './index/order-map-index/order-map-index.component';
import { OrderTableIndexComponent } from './index/order-table-index/order-table-index.component';
import { OrderCreateComponent } from './order-create/order-create.component';
import { OrderTableComponent } from './order-table/order-table.component';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Órdenes' },
    children: [
      {
        path: '',
        data: { title: 'Órdenes' },
        component: OrderTableComponent,
      },
      {
        path: 'tabla',
        data: { title: 'Tabla de órdenes' },
        component: OrderTableIndexComponent,
      },
      {
        path: 'crear',
        data: { title: 'Crear una orden' },
        component: OrderCreateComponent,
      },
      {
        path: 'mapa',
        data: { title: 'Mapa de órdenes' },
        component: OrderMapIndexComponent,
      },
      {
        path: 'detalle/:uid',
        data: { title: 'Detalle orden' },
        component: OrderDetailComponent,
      },

      {
        path: 'detalle-mapa/:uid',
        data: { title: 'Detalle órden mapa' },
        component: OrderDetailMapComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderRouting { }
