export const localeFunctionTimeAgo = (number: number, index: number, totalSec: number): any=> {
  return [
    ['Justamente ahora', 'justamente ahora'],
    ['%s segundos atrás', 'en %s seconds'],
    ['1 minuto atrás', 'en 1 minute'],
    ['%s minuto atrás', 'en %s minutes'],
    ['1 hora atrás', 'en 1 hora'],
    ['%s horas atrás', 'en %s horas'],
    ['1 día atrás', 'en 1 día'],
    ['%s días atrás', 'en %s días'],
    ['1 semana atrás', 'en 1 semana'],
    ['%s semanas atrás', 'en %s semanas'],
    ['1 mes atrás', 'en 1 mes'],
    ['%s semana atrás', 'en %s meses'],
    ['1 año atrás', 'en 1 año'],
    ['%s años atrás', 'en %s años']][index];
};
