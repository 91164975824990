import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { STATUS_PAYMENTS } from "../../../constants/payment_types";
import { USER_PAYMENT_TYPE } from "../../../constants/user";
import { OrderPayment, ProofPayment } from "../../../interfaces/proof-payment";
import { ProofPaymentService } from "../../../services/proof-payment.service";
import {
  whatsApp,
  getBalanceDriver,
  objToArray,
  getBalanceConsumedDriver,
} from "../../../utils/general.utils";

@Component({
  selector: "app-order-consumption-detail",
  templateUrl: "./order-consumption-detail.component.html",
  styleUrls: ["./order-consumption-detail.component.scss"],
})
export class OrderConsumptionDetailComponent implements OnInit {
  balanceDriver: number = 0;
  proofPay: ProofPayment;
  proofPayment: ProofPayment;
  proofPaymentData: ProofPayment;
  whatsApp = whatsApp;
  objToArray = objToArray;
  getBalanceConsumedDriver = getBalanceConsumedDriver
  doGetAmountAll = [];

  constructor(
    public bsModalRef: BsModalRef,
    private proofPaymentService: ProofPaymentService
  ) {}

  ngOnInit(): void {
    this.proofPaymentService
      .doGetProofPaymentToPromise(this.proofPaymentData.id)
      .then((p) => {
        this.proofPayment = p;
        if (p.user?.driver) {
          this.getBalanceDriver(p.user);
        }
      });
  }

  async getBalanceDriver(driver: any) {
    this.proofPay = (await this.proofPaymentService.getBalanceDriverEnabledActive(driver?.user?.uid, USER_PAYMENT_TYPE.driver.index, STATUS_PAYMENTS.approved.index)) as ProofPayment;
    const proof = this.proofPaymentData.id === this.proofPay.id ? this.proofPay : this.proofPaymentData
    this.balanceDriver = getBalanceDriver(proof);
    const residue = proof?.residue?.residue || 0
    const bonus = proof?.bonus?.amount || 0;
    const balanceTotal = proof.amount + residue + bonus;
    const orders = objToArray(proof?.orders)
    for (const o of orders) {
      const comission: number = (o?.amount * o?.driver?.vehicle?.percentage?.percentage) / 100;
      if (!this.doGetAmountAll.length) {
        const obj = {
          after: balanceTotal - comission,
          before: balanceTotal,
          idOrder: o?.idOrder,
          comission
        }
        this.doGetAmountAll.push(obj);
      } else {
        const lastIndex = this.doGetAmountAll.length === 1 ? 0 : this.doGetAmountAll.length - 1
        const lastOrder = this.doGetAmountAll[lastIndex]
        const obj = {
          after: lastOrder?.after - comission,
          before: lastOrder?.after,
          idOrder: o?.idOrder,
          comission
        }
        this.doGetAmountAll.push(obj);
      }
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }
  getStatusName(status: string) {
    return STATUS_PAYMENTS[status].value;
  }

  doGetOrder(id: string){ 
    return this.doGetAmountAll.filter( o => o.idOrder === id)[0]
   }

   orderSort(orders: any[]){
    // return objToArray(orders).sort((a, b) => ((a.createdAt?.seconds < b.createdAt?.seconds) ? -1 : ((a.createdAt?.seconds > b.createdAt?.seconds) ? 1 : 0)))
    return objToArray(orders).sort((a, b) => ((a.createdAt?.seconds < b.createdAt?.seconds) ? 1 : ((a.createdAt?.seconds > b.createdAt?.seconds) ? -1 : 0)))
   }
}
