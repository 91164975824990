import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { ProofPayment } from "../interfaces/proof-payment";

@Injectable({
  providedIn: "root",
})
export class ProofPaymentService {
  private proofPaymentCollection: AngularFirestoreCollection<ProofPayment>;
  constructor(private fs: AngularFirestore) {
    this.proofPaymentCollection = fs.collection<ProofPayment>('proofPayments');
  }

  async setProofPayment(proofPayment: ProofPayment) {
    let accepted: boolean;
    let error: any;
    try {
      await this.fs
        .collection<ProofPayment>("proofPayments")
        .doc(proofPayment.id)
        .set(proofPayment);
    } catch (e) {
      error = e;
    } finally {
      if (error === undefined) {
        accepted = true;
      } else {
        accepted = false;
      }
    }
    return {
      accepted: accepted,
      error: error,
    };
  }

  async getBalanceDriverEnabledActive(
    uidUser: string,
    userPaymentType: string,
    status: string
  ) {
    let proofPay = await this.fs
      .collection("proofPayments", (ref) =>
        ref
          .where("userPaymentType", "==", userPaymentType)
          .where("status", "==", status)
          .where("enabled", "==", true)
          .where('user.user.uid','==',uidUser)
          .orderBy('createdAt','desc')
          .limit(1)
      ).get().toPromise()
      if(!proofPay.empty){
        return proofPay.docs[0]?.data()
      } else {
        return {} as ProofPayment
      }

  }

  async updateProofPayment(proofPayment: Partial<ProofPayment>): Promise<any> {
    let accepted: boolean;
    let error;
    try {
      await this.proofPaymentCollection.doc(proofPayment.id).update(proofPayment);
    } catch (e) {
      error = e;
    } finally {
      if (error != undefined) {
        accepted = false;
      } else {
        accepted = true;
      }
    }
    return {
      accepted: accepted,
      error: error
    }
  }

  doGetProofPayment(id: string){ return this.fs.doc<ProofPayment>(`proofPayments/${id}`).valueChanges(); }

  async doGetProofPaymentToPromise(id: string){ 
    return (await this.fs.doc<ProofPayment>(`proofPayments/${id}`).get().toPromise()).data()
   }
}
