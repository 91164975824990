import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../../services/authentication.service';
import { FirebaseErrors } from '../../firebase/HandlingErrors';
import { notWhiteSpace, onlyLowerCase } from '../../validators/users';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {

  public userLoginForm: FormGroup;
  public visibilityPassword: boolean = false;
  errors: any[] = [];
  userLoading: boolean = false;
  
  constructor(private router: Router, private formBuilder: FormBuilder, private userAuth: AuthenticationService) {
    this.userAuth.staff$.subscribe( auth => {	if(auth){	this.router.navigateByUrl('');	}	})
    this.userLoginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, onlyLowerCase(), notWhiteSpace()]],
      password: ['', [Validators.required, onlyLowerCase(), notWhiteSpace()]]
    })
  }

  async userLogin() {
    this.userLoading = true;
    if (!this.userLoginForm.valid) return false;
    const email: string = this.email.value;
    const password: string = this.password.value;
    // Check if user has account active.
    /* const { active, accountErrorMsg } = await this.userAuth.userIsActiveAccount(email);
    if (!active) {
      this.errors.push({ message: accountErrorMsg });
      this.userLoading = false;
      return false;
    } */
    // User login with credentials.
    this.userAuth.signInWithEmailAndPassword(email?.toLowerCase().trim(), password?.toLowerCase().trim()).then( success => {
      this.router.navigate(['']);
      this.userLoading = false;
    }).catch( err => {
      this.errors.push({ message: FirebaseErrors.Parse(err.code) });
      this.userLoading = false;
      return false
    })
    /* const { logged, loginErroMsg, session } = await this.userAuth.userLogin(email?.toLowerCase().trim(), password?.toLowerCase().trim());
    if (!logged) {
      this.errors.push({ message: loginErroMsg });
      this.userLoading = false;
      return false;
    } */
  }

  get email() { return this.userLoginForm.get('email') }
  get password() { return this.userLoginForm.get('password') }

}
