import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../../services/authentication.service';
import { ImageStorageService } from '../../../services/image-storage.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'user-user-options',
  templateUrl: './user-options.component.html',
})
export class UserOptionsComponent implements OnInit {

  userPhoto: string;

  constructor(
    private userService: UserService,
    private userAuth: AuthenticationService,
    private storage: ImageStorageService,
    private auth: AngularFireAuth, private router: Router) { }

  ngOnInit() { this.renderDataUser(); }

  async renderDataUser() {
    const userAuth = await this.auth.currentUser;
    if (!userAuth) return false;
    //If user not contain a image in profile is added, default the image is fetched.
    const { user } = await this.userService.userObtainByUid(userAuth.uid);
    this.userPhoto = user?.urlImage ? user?.urlImage : null;
    // if (!userAuth.photoURL) {
    //   await userAuth.updateProfile({ photoURL: user.urlImage });
    // }
  }
  async userSignOutAlert() {
    const { isConfirmed } = await Swal.fire({
      title: '¿Estás seguro de cerrar sesión?',
      text: "No podrás revertir esta acción.!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí, estoy seguro!',
      reverseButtons: true
    });
    if (!isConfirmed) {
      return false;
    }
    //await this.userAuth.userAccountDeleteSession()
    const logged = await this.userAuth.userSignOut();
    this.router.navigate(['/login']);
  }
}
