import { Component, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ORDER_STATUS, ORDER_STATUS_, TYPE_ORDER } from "../../../constants/order";
import { Chat } from "../../../interfaces/chat";
import { Client } from "../../../interfaces/client";
import { ClientOrderDetail } from "../../../interfaces/ClientOrderDetail";
import { Driver } from "../../../interfaces/Driver";
import { DriverOrderDetail } from "../../../interfaces/DriverOrderDetail";
import { Geopoint } from "../../../interfaces/geopoint";
import { Order, OrderModel } from "../../../interfaces/order";
import { Supervisor } from "../../../interfaces/supervisor";
import { SupervisorOrderDetail } from "../../../interfaces/SupervisorOrderDetail";
import { ClientOrderDetailModel, DriverModel, DriverOrderDetailModel, SupervisorModel, SupervisorOrderDetailModel, User, UserModel } from "../../../interfaces/user";
import { notifyMessage, sendErrorMessage } from '../../../notifications/notifications';
import { ClientService } from "../../../services/client.service";
import { DriverService } from "../../../services/driver.service";
import { OrderService } from "../../../services/order.service";
import { supervisorService } from "../../../services/supervisor.service";
import { UserService } from "../../../services/user.service";
import firebase from "firebase/app";
import { PERMISSION } from "../../../constants/permission";
import { Transaction } from "../../../interfaces/Transaction";
import { Staff } from "../../../interfaces/Staff";
import { TRANSACTION_STATUS } from "../../../constants/transaction";
import { Tax } from "../../../interfaces/Tax";
import { ORDERS_MESSAGENS } from "../../../constants/messages";
import { ProofPaymentService } from "../../../services/proof-payment.service";
import { USER_PAYMENT_TYPE } from "../../../constants/user";
import { STATUS_PAYMENTS } from "../../../constants/payment_types";
import { getAmountPercenge, getBalanceDriver, validateAmount } from "../../../utils/general.utils";
import { BonusProof, OrderPayment, PercentageProof, ProofPayment } from "../../../interfaces/proof-payment";
import { Bonus } from "../../../interfaces/Bonus";
import { Vehicle } from "../../../interfaces/vehicle";
import { AuthenticationService } from "../../../services/authentication.service";
import { Auctioneer } from "../../../interfaces/auctioneer";
import * as firebase2 from 'firebase';
import { AgmMap, MapsAPILoader, AgmCircle } from '@agm/core';
import { TYPE_VEHICLES } from "../../../constants/vihecles";

const geohash = require('ngeohash');

@Component({
  selector: "app-order-create",
  templateUrl: "./order-create.component.html",
  styleUrls: ["./order-create.component.scss"],
})
export class OrderCreateComponent implements OnInit {
  @ViewChild('agmMapStore') agmMap: AgmMap;
  @ViewChild(AgmCircle) child

  users$: Observable<User[]>;
  drivers$: Observable<User[]>;
  
  driversV2: UserModel[]
  clients: UserModel[]
  supervisorV2: UserModel[]

  orderStatus = ORDER_STATUS;
  orderCreateForm: FormGroup;
  user: User;
  userModel: UserModel;
  staff: UserModel;
  driver: User;
  driverModel: UserModel;
  supervisor: UserModel
  vehicleSelect: Vehicle

  origin: firebase.firestore.GeoPoint;
  destination: firebase.firestore.GeoPoint;

  latOrigin: number = -2.160139169427665
  lngOrigin: number = -79.9071973753834
  latDestination: number = -2.160139169427665
  lngDestination: number = -79.9071973753834
  gettingBalanse: boolean = false
  balanceDriver: number = 0
  proofPay: ProofPayment

  showBonusClient: boolean = false
  showBonusVehicle: boolean = false

  isAuctioner: boolean = false
  geocoder: google.maps.Geocoder;

  firestore = firebase.firestore();

  typeVehicles = Object.values(TYPE_VEHICLES)

  orderStatusV2 = ORDER_STATUS_
  userAdmin: UserModel
  addressOrigin: string = ''
  addressDestination: string = ''
  constructor( private auth: AuthenticationService,  private userService: UserService, private fs: AngularFirestore, private orderService: OrderService, private driverService: DriverService,
    private supervisorService: supervisorService, private clientService: ClientService, private formbuilder: FormBuilder, private proofPaymentService: ProofPaymentService,
    private mapsAPILoader: MapsAPILoader
    ) {
      this.auth.staffCurrent.subscribe(staff => {
        this.staff = staff
        this.doGetUserByPermission(staff)
      })
      this.initFormCreate()
      navigator.geolocation.getCurrentPosition(position => {
        this.latOrigin = position.coords.latitude;
        this.lngOrigin = position.coords.longitude;
        this.latDestination = position.coords.latitude;
        this.lngDestination = position.coords.longitude;
        this.mapsAPILoader.load().then(() => {
          const latlng = new google.maps.LatLng(this.latOrigin, this.lngOrigin);
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ location: latlng }, (result, status) => {
            if (status === google.maps.GeocoderStatus.OK && result.length > 0) {
              this.addressOrigin = result[0].formatted_address;
              this.addressDestination = result[0].formatted_address;
            }
          });
        });
      })
  }

  ngOnInit() {
    this.obtainCurrentLocation();
    this.obtainUsers();
    this.obtainDrivers();
  }

  initFormCreate(){
    this.orderCreateForm = this.formbuilder.group({
      user: ["", Validators.required],
      driver: [""],
      // supervisor: ["", Validators.required],
      active: [true, Validators.required],
      isPet: [false, Validators.required],
      isChargeCustomer: [true, Validators.required],
      amount: ['', [Validators.required]],
      vehicle: [''],
      typeVehicle: [''],
      amountBonuVehicle: [''],
      descriptionBonuVehicle: [''],
      amountBonuClient: [''],
      descriptionBonuClient: [''],
      observations: [''],
      isAuctioner: [false]
    });
  }
  async obtainCurrentLocation() {
    this.origin = { latitude: -2.2200295, longitude: -79.8917438 } as any;
  }

  obtainUsers() {
    // this.users$ = this.userService.users$;
  }

  obtainDrivers() {
    this.drivers$ = this.userService.users$.pipe(
      map(data => data.filter(value => value.isDriver != false))
    )
  }

  async submitCreateOrder() {
    if (!this.orderCreateForm.valid) return false;
    try {
      const date = new Date()
      let userDriver: UserModel
      let driverOrderDetail: DriverOrderDetailModel
      const user: UserModel = {
        uid: this.userModel.uid,
        createdAt: new Date(this.userModel.createdAt['seconds'] * 1000),
        name: this.userModel.name,
        lastName: this.userModel.lastName,
        urlImage: this.userModel.urlImage,
        identification: this.userModel.identification,
        gender: this.userModel.gender,
        birthday: this.userModel.birthday,
        phone: this.userModel.phone,
        email: this.userModel.email,
        updatedAt: new Date(this.userModel.updatedAt['seconds'] * 1000),
        active: this.userModel.active,
        session: this.userModel?.session || {},
        permissions: this.userModel.permissions,
        fcmToken: this.userModel.fcmToken || null
      }
      if(!this.isAuctioner){
        userDriver = {
          uid: this.driverModel.uid,
          createdAt: new Date(this.driverModel.createdAt['seconds'] * 1000),
          name: this.driverModel.name,
          lastName: this.driverModel.lastName,
          urlImage: this.driverModel.urlImage,
          identification: this.driverModel.identification,
          gender: this.driverModel.gender,
          birthday: this.driverModel.birthday,
          phone: this.driverModel.phone,
          email: this.driverModel.email,
          updatedAt: new Date(this.driverModel.updatedAt['seconds'] * 1000),
          active: this.driverModel.active,
          session: this.driverModel?.session || {},
          permissions: this.driverModel.permissions,
          fcmToken: this.driverModel.fcmToken || null
        }
        driverOrderDetail = {
          user: userDriver?.uid ? userDriver : {} as UserModel,
          driver: this.driverModel?.driver || {} as DriverModel
        }
  
        driverOrderDetail.driver.createdAt = new Date(driverOrderDetail.driver.createdAt['seconds'] * 1000)
        driverOrderDetail.driver.updatedAt = new Date(driverOrderDetail.driver.updatedAt['seconds'] * 1000)
      }
      const clientOrderDetail: ClientOrderDetailModel = {
        user,
        client: this.userModel.client
      }
      
      const originDate = Date.now();
      let trips: any
      trips = {
        ['originPosition']: {
          address: this.addressOrigin,
          geohash: geohash.encode(this.latOrigin,this.lngOrigin),
          geopoint: new firebase.firestore.GeoPoint(this.latOrigin, this.lngOrigin)
        },
        ['destinationPosition']: {
          address: this.addressDestination,
          geohash: geohash.encode(this.latDestination,this.lngDestination),
          geopoint: new firebase.firestore.GeoPoint(this.latDestination, this.lngDestination)
        }
      };
  
      const orderId = this.fs.createId()
  
      const orderPayment: OrderPayment = {
        idOrder: orderId,
        amount: this.orderCreateForm.value.amount,
        driver: {
          id: driverOrderDetail?.user?.uid || '',
          vehicle: {
            percentage: this.vehicleSelect?.percentage || {} as PercentageProof,
            typeVehicle: this.vehicleSelect?.typeVehicle?.type || '',
            bonus: {
              amount: this.orderCreateForm.value.amountBonuVehicle || 0,
              description: this.orderCreateForm.value.descriptionBonuVehicle || '',
            }
          }
        },
        client: {
          id: clientOrderDetail.user.uid,
          percentage: clientOrderDetail?.client?.percentage || {} as PercentageProof ,
          bonus: {
            amount: this.orderCreateForm.value.amountBonuClient || 0,
            description: this.orderCreateForm.value.descriptionBonuClient || '',
          }
        },
        supervisor: {
          id: this.userAdmin?.uid,
          percentage: this.userAdmin?.supervisorL1?.percentage || {} as PercentageProof
        }
      }
      clientOrderDetail.client.createdAt = new Date(clientOrderDetail.client.createdAt['seconds'] * 1000)
      clientOrderDetail.client.updatedAt = new Date(clientOrderDetail.client.updatedAt['seconds'] * 1000)
  
      const order: OrderModel = {
        id: orderId,
        createdAt: date,
        route: '',
        clientOrderDetail,
        driverOrderDetail,
        // supervisorOrderDetail,
        status: this.isAuctioner ? ORDER_STATUS_.created.index : ORDER_STATUS_.pending.index,
        observations: this.orderCreateForm.value.observations,
        trips,
        isDeleted: false,
        updatedAt: date,
        typeOrder: TYPE_ORDER.career.index,
        isPet: this.orderCreateForm.value.isPet,
        isChargeCustomer: this.orderCreateForm.value.isChargeCustomer,
        createdBy: PERMISSION.supervisorL1.index,
        staff: this.staff,
        amount: this.orderCreateForm.value.amount,
        proofPaymentId: this.proofPay?.id || '',
        orderPayment,
        codeSupervisor: this.userModel?.codeSupervisor[0] || this.userAdmin?.supervisorL1?.code || this.userAdmin?.supervisorL2?.code,
        typeVehicle: this.orderCreateForm.value.typeVehicle || 'truck'
      }
      
      const auctioners: Auctioneer = {
        uid: user.uid,
        userType: USER_PAYMENT_TYPE.client.index, //conductor, cliente, supervisor
        amount: this.orderCreateForm.value.amount,
        active: false,
        created: Date.now(),
        auctioneerName: `${user?.name} ${user?.lastName}`,
        isCancelled: false,
        rating: this.userModel?.driver?.rating || 0
      }
      const orderAuctioner: OrderModel = {
        id: orderId,
        createdAt: date,
        route: '',
        clientOrderDetail,
        // supervisorOrderDetail,
        status: this.isAuctioner ? ORDER_STATUS_.created.index : ORDER_STATUS_.pending.index,
        observations: this.orderCreateForm.value.observations,
        trips,
        isDeleted: false,
        updatedAt: date,
        typeOrder: TYPE_ORDER.career.index,
        isPet: this.orderCreateForm.value.isPet,
        isChargeCustomer: this.orderCreateForm.value.isChargeCustomer,
        createdBy: PERMISSION.supervisorL1.index,
        staff: this.staff,
        proofPaymentId: this.proofPay?.id || '',
        // orderPayment,
        codeSupervisor: this.userModel?.codeSupervisor[0] || this.userAdmin?.supervisorL1?.code || this.userAdmin?.supervisorL2?.code,
        auctioners: [auctioners],
        auctionerAmount: this.orderCreateForm.value.amount,
        typeVehicle: this.orderCreateForm.value.typeVehicle || 'truck'
      }
  
      const transactionDriver: Transaction = {
        id: this.fs.createId(),
        typeTransaction: order.typeOrder,
        createdAt: date,
        isCutoff: false,
        status: TRANSACTION_STATUS.created.index,
        order: !this.isAuctioner ? order : orderAuctioner,
        staff: this.staff,
        tax: {} as Tax,
        observation: {},
        isDeleted: false,
        payTo: USER_PAYMENT_TYPE.driver.index
      }
      const transactionSupervisor: Transaction = {
        id: this.fs.createId(),
        typeTransaction: order.typeOrder,
        createdAt: date,
        isCutoff: false,
        status: TRANSACTION_STATUS.created.index,
        order: !this.isAuctioner ? order : orderAuctioner,
        staff: this.staff,
        tax: {} as Tax,
        observation: {},
        isDeleted: false,
        payTo: USER_PAYMENT_TYPE.supervisor.index
      }
      // console.log('transactionSupervisor: ', transactionSupervisor);
  
      let orderPaymentActual = this.proofPay?.orders || {}
      
      orderPaymentActual = {...orderPaymentActual,[order.id]: orderPayment}
      if(!this.isAuctioner) this.proofPay['orders'] = orderPaymentActual
      if(this.isAuctioner){
        // console.log('orderAuctioner: ', orderAuctioner);
      } else {
        // console.log('order: ', order);
      }
      // console.log('this.proofPay: ', this.proofPay);
      // const orderCloud = await this.userService.createOrderInCloud(order);
      // console.log('orderCloud: ', orderCloud);
      let batch = this.fs.firestore.batch();
      batch.set(this.fs.firestore.collection('orders').doc(order.id), this.isAuctioner ? orderAuctioner : order);
      if(!this.isAuctioner) batch.set(this.fs.firestore.collection('transactions').doc(transactionDriver.id), transactionDriver);
      if(!this.isAuctioner) batch.set(this.fs.firestore.collection('transactions').doc(transactionSupervisor.id), transactionSupervisor);
      if(!this.isAuctioner) batch.update(this.fs.firestore.collection('proofPayments').doc(this.proofPay?.id), this.proofPay);
      const resp = await batch.commit()
      console.log('resp: ', resp);
      if(resp === undefined){
        notifyMessage('success', ORDERS_MESSAGENS.create.success, 'top-right')
        this.initFormCreate()
      } else {
        notifyMessage('error', ORDERS_MESSAGENS.create.error, 'top-right')
      }
      
    } catch (error) {
      console.log('error: ', error);
    }

  }
  //Events in autocomplete
  userSelected($event) {
    this.orderCreateForm.get('user').setValue($event)
    this.user = $event;
    this.userModel = $event;
    this.userService.goGetUserBySupervisorCode(this.userModel?.codeSupervisor[0]).subscribe( u => {
      if(u.length){
        this.userAdmin = u[0]
      }
    })
  }
  userInputCleared($event) {
    this.orderCreateForm.get('user').reset()
    this.user = null;
    this.userModel = null;
  }

  driverSelected($event) {
    this.orderCreateForm.get('driver').setValue($event);
    this.driver = $event;
    this.driverModel = $event;
    this.gettingBalanse = true
    this.getBalanceDriver(this.driverModel)
  }

  driverInputCleared($event) {
    this.orderCreateForm.get('driver').reset()
    this.driver = null;
    this.driverModel = null;
  }

  vehicleInputCleared($event) {
    this.orderCreateForm.get('vehicle').reset()
    this.vehicleSelect = null;
  }

  vehicleSelected($event) {
    this.orderCreateForm.get('vehicle').setValue($event);
    this.vehicleSelect = $event;
  }

  
  
  supervisorSelected($event) {
    this.orderCreateForm.get('supervisor').setValue($event);
    this.supervisor = $event;
  }

  supervisorInputCleared($event) {
    this.orderCreateForm.get('supervisor').reset()
    this.supervisor = null;
  }

  //Event when access marker map
  dragEndMarker($event: any) {
    console.log("lat nueva", $event.latLng.lat()); //to see the latitude in the console
    console.log("lng nueva", $event.latLng.lng()); // to see the longitude in the console
  }

  doGetUserByPermission(staff: UserModel){
    if(staff.permissions.some(p => p === PERMISSION.admin.index)){
      this.userService.goGetUserByPermission([PERMISSION.client.index]).subscribe(c => this.clients = c)
      this.userService.goGetUserByPermission([PERMISSION.driver.index]).subscribe(c => this.driversV2 = c)
      this.userService.goGetUserByPermission([PERMISSION.supervisorL1.index]).subscribe(c => {
        this.supervisorV2 = c.filter(s => s?.supervisorL1?.code)
      })
    } else if(staff.permissions.some(p => p === PERMISSION.supervisorL1.index)){
      const users = this.userService.goGetUserByCodeSupervisor([staff?.supervisorL1?.code])
      users.subscribe(user => {
        this.clients = user.filter(u => u.permissions.includes(PERMISSION.client.index) )
        this.driversV2 = user.filter(u => u.permissions.includes(PERMISSION.driver.index) )
        this.supervisorV2 = user.filter(u => u.permissions.includes(PERMISSION.supervisorL1.index || PERMISSION.supervisorL2.index) )
       })
      } else if(staff.permissions.some(p => p === PERMISSION.supervisorL2.index)){
      const users = this.userService.goGetUserByCodeSupervisor([staff?.supervisorL1?.code])
      users.subscribe(user => {
        this.clients = user.filter(u => u.permissions.includes(PERMISSION.client.index) )
        this.driversV2 = user.filter(u => u.permissions.includes(PERMISSION.driver.index) )
        this.supervisorV2 = user.filter(u => u.permissions.includes(PERMISSION.supervisorL1.index || PERMISSION.supervisorL2.index) )
       })
    } else if(staff.permissions.some(p => p === PERMISSION.staff.index)){
    } else if(staff.permissions.some(p => p === PERMISSION.digitizers.index)){
    }
  }

  updateMarkerOrigin($event) {
    this.latOrigin = $event.latLng.lat()
    this.lngOrigin = $event.latLng.lng()
    this.mapsAPILoader.load().then(() => {
      const latlng = new google.maps.LatLng(this.latOrigin, this.lngOrigin);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: latlng }, (result, status) => {
        if (status === google.maps.GeocoderStatus.OK && result.length > 0) {
          this.addressOrigin = result[0].formatted_address;
        }
      });
    });
  }

  updateMarkerDestination($event) {
    this.latDestination = $event.latLng.lat()
    this.lngDestination = $event.latLng.lng()
    this.mapsAPILoader.load().then(() => {
      const latlng = new google.maps.LatLng(this.latDestination, this.lngDestination);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: latlng }, (result, status) => {
        if (status === google.maps.GeocoderStatus.OK && result.length > 0) {
          this.addressDestination = result[0].formatted_address;
        }
      });
    });
  }

  async getBalanceDriver(driver: UserModel){
    this.proofPay = await this.proofPaymentService.getBalanceDriverEnabledActive(driver.uid, USER_PAYMENT_TYPE.driver.index, STATUS_PAYMENTS.approved.index) as ProofPayment
    this.gettingBalanse = false
    this.balanceDriver = getBalanceDriver(this.proofPay)
  }

  validateAmount(){
    if(this.isAuctioner) return true
    return validateAmount(this.orderCreateForm.value.amount, this.balanceDriver, this.vehicleSelect) 
  }
  getAmountPercenge(){ return getAmountPercenge(this.orderCreateForm.value.amount, this.balanceDriver, this.vehicleSelect) }

  showInputBonus(type: string, show: boolean){
    switch (type) {
      case 'client':
        this.showBonusClient = show
        break;
      case 'vehicle':
        this.showBonusVehicle = show
        break;
    }
  }

  setDriverAuctioner(isAuctioner: boolean){
    this.isAuctioner = isAuctioner
    if(isAuctioner){
      this.orderCreateForm.controls['driver'].setValidators([Validators.required])
      this.orderCreateForm.controls['vehicle'].setValidators([Validators.required])
      this.orderCreateForm.controls['typeVehicle'].setValidators([Validators.required])
    } else {
      this.orderCreateForm.controls['driver'].clearValidators()
      this.orderCreateForm.controls['vehicle'].clearValidators()
      this.orderCreateForm.controls['typeVehicle'].clearValidators()
    }
  }
}
