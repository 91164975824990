export const ORDER_TYPE_FILTER = [
  { name: 'SUPERVISORES', value: 'supervisor' },
  { name: 'USUARIO', value: 'user' },
  { name: 'CONDUCTORES', value: 'driver' },
  { name: 'IDENTIFICADOR DE ORDEN', value: 'uid' }
]

export const ORDER_STATUS = [
  { name: 'created', value: 'Creado' },
  { name: 'canceled', value: 'Pagado' },
  { name: 'bidding', value: 'Subasta' },
  { name: 'pending', value: 'Pendiente' },
  { name: 'process', value: 'Proceso' },
  { name: 'done', value: 'Terminado' },
  { name: 'alert', value: 'Emergencia' }
]

export const ORDER_STATUS_ = {
  created: { index: 'created', value: 'Creado' }, // es cuando el cliente crea la orden y esta esperando que inicie la subasta
  canceled: { index: 'canceled', value: 'Cancelado' }, // cuando la carrera esta en proceso ya no se puede cancelar
  bidding: { index: 'bidding', value: 'Subasta' }, // es cuando el 1er conductor inicia la subasta
  pending: { index: 'pending', value: 'Pendiente' }, // es cuando el driver esta asignado a la orden
  inProcess: { index: 'inProcess', value: 'Proceso' }, // cuando ya el conductor va en camino al destino del cliente
  done: { index: 'done', value: 'Terminado' }, // cuando ya se finalizo la carrera
  alert: { index: 'alert', value: 'Emergencia' } // 
}

export const TYPE_ORDER = {
  career: {index: 'career', value: 'Career'}, //Taxi, 
  shipment: {index: 'shipment', value: 'Shipment'}, // envio => lo hace la moto
  delivery: {index: 'delivery', value: 'Delivery'}, // 
}
