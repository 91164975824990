import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { base64ToFile } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { UploadStorage } from '../interfaces/UploadStorage';

@Injectable({
  providedIn: 'root'
})
export class ImageStorageService {

  public percentage: Observable<number>;
  constructor(private storage: AngularFireStorage) { }

  uploadCloudStorage(upload: UploadStorage): string {

    const {uid, file, directory, folder } = upload;
    const fileString = file.type;
    const extension = fileString.substring(fileString.lastIndexOf('/') + 1, fileString.length)
    const date = Date.now()
    const filename = `${uid}_${date}.${extension}`.trim()
    const urlStorage = `${folder}/${uid}/${directory}/${filename}`
    this.storage.ref(urlStorage);
    const task = this.storage.upload(urlStorage, file);
    this.percentage = task.percentageChanges();

    return urlStorage
  }

  async imageDownloadUrl(urlStorage) {
    return await this.storage.ref(urlStorage).getDownloadURL().toPromise()
  }

 async uploadImageFiles(uid: string, arrayImage: any, directory: string) {
    let index = 0;
    for (let file in arrayImage) {
      let image = base64ToFile(arrayImage[file].img)
      try {
        let fileString = image.type;
        let extension = fileString.substring(fileString.lastIndexOf('/') + 1, fileString.length)
        let filename = `${uid}_${arrayImage[file].date}.${extension}`.trim()
        let urlStorage = `users/${uid}/${directory}/${filename}`
        this.storage.ref(urlStorage);
        let task = this.storage.upload(urlStorage, image);
        this.percentage = task.percentageChanges();
        arrayImage[file].img = urlStorage
        index += 1;
      } catch (error) {
        console.log('err', error)
      }
    }
    return arrayImage;
  }

  async uploadImageFilesTypeVehicle(arrayImage: any) {
    let index = 0;
    for (let file of arrayImage) {
      let image = base64ToFile(file.img)
      try {
        let fileString = image.type;
        let extension = fileString.substring(fileString.lastIndexOf('/') + 1, fileString.length)
        let filename = `${file.date}.${extension}`.trim()
        let urlStorage = `app/typevehicle/${filename}`
        this.storage.ref(urlStorage);
        let task = this.storage.upload(urlStorage, image);
        this.percentage = task.percentageChanges();
        arrayImage[index].img = urlStorage
        index += 1;
      } catch (error) {
        console.log('err', error)
      }
    }
    return arrayImage;
  }
}
