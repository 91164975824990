export interface StateDriver{
  name: string,
  value: string,
}

export const STATE_DRIVER: StateDriver[] = [
  // { name: 'CREADO', value: 'create' },
  { name: 'PENDIENTE', value: 'pending' },
  { name: 'CANCELADO', value: 'canceled' },
  { name: 'APROBADO', value: 'aprobated' }
]

export const STATE_DRIVER_ = {
  // pending: {index: 'create', value: 'CREADO', color: 'warning'},
  pending: { index: 'pending', value: 'PENDIENTE', color: 'warning' },
  canceled: { index: 'canceled', value: 'CANCELADO', color: 'danger' },
  aprobated: { index: 'aprobated', value: 'APROBADO', color: 'success' },
}