import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { notifyMessage } from '../../../notifications/notifications';
import { AuthenticationService } from '../../../services/authentication.service';
import { notWhiteSpace, onlyLowerCase } from '../../validators/users';

@Component({
  selector: 'app-password-reset-confirm',
  templateUrl: './password-reset-confirm.component.html',
})
export class PasswordResetConfirmComponent {

  userResetPasswordConfirmForm: FormGroup;
  userResetPasswordConfirmLoading: boolean = false;
  visibilityPassword: boolean = false;

  mode = this.activatedRoute.snapshot.queryParams['mode'];

  errors: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService) {
    this.userResetPasswordConfirmForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), onlyLowerCase(), notWhiteSpace()]],
      passwordConfirm: ['', [Validators.required, Validators.minLength(8), onlyLowerCase(), notWhiteSpace()]]
    })
  }

  async userResetPasswordConfirCredentials() {
    this.userResetPasswordConfirmLoading = true;
    const password = this.userResetPasswordConfirmForm.get('password').value;
    const newPassword = this.userResetPasswordConfirmForm.get('passwordConfirm').value;
    const code = this.activatedRoute.snapshot.queryParams['oobCode'];

    if (password != newPassword) {
      this.errors.push({ message: 'Contraseñas proporcionadas no coinciden.' });
      this.userResetPasswordConfirmLoading = false;
      return false;
    }
    const { errorMessage, sended } = await this.authService.userPasswordResetConfirm(code, password);
    if (errorMessage) {
      this.errors.push({ message: errorMessage });
      this.userResetPasswordConfirmLoading = false;
      return false;
    }
    this.userResetPasswordConfirmLoading = false;
    notifyMessage('success', 'Contraseña reestablecida', 'top-right');
    this.router.navigate(['/login']);
  }

  get password() { return this.userResetPasswordConfirmForm.get('password') }
  get passwordConfirm() { return this.userResetPasswordConfirmForm.get('passwordConfirm') }

}
