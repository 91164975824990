import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import Swal from "sweetalert2";
import { User, UserModel } from "../../../interfaces/user";
import { UserService } from "../../../services/user.service";
import * as moment from 'moment';
import { isValid } from "../../../utils/general.utils";

@Component({
  selector: "app-personal-edit-form",
  templateUrl: "./personal-edit-form.component.html",
})
export class PersonalEditFormComponent implements OnInit {

  @Input() uid: string;
  userModel: UserModel
  user: User;
  photoSelected: ArrayBuffer | string;
  form: FormGroup;
  bsModalRef: BsModalRef;
  public USER_AVATAR_DEFAULT: string = 'assets/img/avatars/no-image.png'
  user$: Observable<User>;

  constructor(private formbuilder: FormBuilder, private userService: UserService, private modalService: BsModalService
  ) {
    userService.userSelected.subscribe(user => {
      const birthday = moment(user?.birthday?.seconds * 1000).format("YYYY-MM-DD")
      this.userModel = user
      // console.log('user: ', user);
      this.form = this.formbuilder.group({
        identification: [{ value: user?.identification || '', disabled: true }],
        name: [user?.name || '', [Validators.required]],
        lastName: [user?.lastName || '', [Validators.required]],
        birthday: [birthday, [Validators.required]],
        phone: [user?.phone || '', [Validators.required]],
        email: [{ value: user?.email || '', disabled: true }],
      });
    })
  }

  ngOnInit() {
  }

  async userUpdate(): Promise<void> {
    if (confirm('Estas seguro que desea actualizar')) {
      if (this.form.valid) {


        const date  = new Date(this.form.value.birthday);
        date.setDate(date.getDate()+1);
        const userData: Partial<UserModel> = {
          name: this.form.value.name,
          lastName: this.form.value.lastName,
          birthday: new Date(/* date.getFullYear(), date.getDate(),date.getMonth()+1 */date.toUTCString()),
          phone: this.form.value.phone,
          uid: this.userModel.uid
        }

        alert(`fecha: ${new Date(this.form.value.birthday)}`);
        const { accepted, error } = await this.userService.userUpdateV2(userData);

        if (accepted) {

          alert('cambio exitoso');
        } else {
          alert(`error: ${JSON.stringify(error)}`);

        }
      } else {
        alert('los datos son incorrectos');
      }

    }
    /* if (!this.form.valid) return false;

    const { isConfirmed } = await Swal.fire({
      title: '¿Estás seguro de actualizar datos del usuario?',
      text: "No podrás revertir esta acción.!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro!',
      reverseButtons: true,
      cancelButtonText: 'Cancelar'
    }) */




    /*  if (!isConfirmed) return false;
     
     if (accepted) {
       Swal.fire({
         position: 'top-end',
         icon: 'success',
         title: 'Información actualizada con éxito.',
         showConfirmButton: false,
         timer: 3000
       })
     } else {
       Swal.fire({
         position: 'top-end',
         icon: 'error',
         title: 'Información no pudo ser actualizada.',
         showConfirmButton: false,
         timer: 3000
       })
     } */
  }

  isValid(permission: string, permissions: Array<string>) { return isValid(permission, permissions) }
}
