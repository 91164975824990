import { Component, OnInit, TemplateRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { STATE_DRIVER_ } from '../../../constants/driver';
import { PAYMENT_TYPE, PAYMENT_TYPE_, STATUS_PAYMENTS } from '../../../constants/payment_types';
import { PERMISSION } from '../../../constants/permission';
import { USER_PAYMENT_TYPE } from '../../../constants/user';
import { ProofPayment } from '../../../interfaces/proof-payment';
import { UserModel } from '../../../interfaces/user';
import { notifyMessage } from '../../../notifications/notifications';
import { AuthenticationService } from '../../../services/authentication.service';
import { UserService } from '../../../services/user.service';
import { isValid } from '../../../utils/general.utils';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  userModel: UserModel
  staff: UserModel

  dataByValid: {}
  modalRef?: BsModalRef;
  validateAllVarDrive: boolean = false

  PERMISSION = PERMISSION

  constructor(private userService: UserService, private auth: AuthenticationService, private modalService: BsModalService,
    private afs: AngularFirestore) {
    this.auth.staffCurrent.subscribe(staff => this.staff = staff )
    userService.userSelected.subscribe(user => {
      this.userModel = user;
      this.validateAllVar()
    })
    
   }

  ngOnInit(): void {
  }

  async rPermission(permission: string, option: boolean){
    let permissions = {}
    let userData: Partial<UserModel>
    let batch = this.afs.firestore.batch();
    for(const p of this.userModel?.permissions){ permissions = {...permissions,[p]: p} }
    if(!option) delete permissions[permission]
    else permissions = {...permissions,[permission]: permission}
    if(this.userModel.driver){
      this.userModel.driver.state = (option && permission === PERMISSION.driver.index && this.userModel.driver?.state === STATE_DRIVER_.pending.index) ? STATE_DRIVER_.aprobated.index : this.userModel.driver?.state
        userData = {
        uid: this.userModel.uid,
        driver: this.userModel.driver,
        permissions: Object.values(permissions),
      }
      if(!this.userModel?.isBonusDriver){
        userData.isBonusDriver = true
        const date = new Date()
        const user = {
          user: {
            uid: this.userModel.uid,
            name: this.userModel.name,
            lastName: this.userModel.lastName,
            urlImage: this.userModel.urlImage,
            identification: this.userModel.identification,
            phone: this.userModel.phone,
            email: this.userModel.email,
          },
          driver: this.userModel.driver
        }
        const proofPayment: ProofPayment = {
          id: this.afs.createId(),
          createdAt: date,
          updatedAt: date,
          userPaymentType:  USER_PAYMENT_TYPE.driver.index,
          user,
          enabled: true,
          urlTransfer: '',
          typePayment: PAYMENT_TYPE_.cash.index,
          amount: 0,
          status: STATUS_PAYMENTS.approved.index,
          bankReference: 'bonus',
          codeSupervisor: this.staff?.supervisorL1?.code || '',
          bonus: {
            amount: 5,
            description: 'Por habilitarse como driver la 1era vez'
          }
        }
        batch.set(this.afs.firestore.collection('proofPayments').doc(proofPayment.id), proofPayment);
      }
    } else {
      userData = {
      uid: this.userModel.uid,
      permissions: Object.values(permissions)
      }
    }
    batch.update(this.afs.firestore.collection('users').doc(userData.uid), userData);
    const resp = await batch.commit()
    if (resp === undefined) {
      notifyMessage('success', 'Permiso actualizado', 'top-right')
    } else {
      notifyMessage('error', 'Error mientras se actualizaba los permisos.', 'top-right')
    }

  }


  isValid(permission: string, permissions: Array<string>){ return isValid(permission, permissions) }

  validateAllVar(){
    if(!this.userModel?.driver?.license?.active){ this.dataByValid = {...this.dataByValid,['license']: true}} else { if(this.dataByValid && this.dataByValid['license']) delete this.dataByValid['license'] }
    if(!this.userModel?.driver?.urlRecordPolicialActive){ this.dataByValid = {...this.dataByValid,['urlRecordPolicial']: true}} else { if(this.dataByValid && this.dataByValid['urlRecordPolicial']) delete this.dataByValid['urlRecordPolicial'] }
    if(!this.userModel?.urlImageIdentificationActive){ this.dataByValid = {...this.dataByValid,['urlImageIdentification']: true}} else { if(this.dataByValid && this.dataByValid['urlImageIdentification']) delete this.dataByValid['urlImageIdentification'] }
    if(!this.userModel?.driver?.vehicles?.some( v => v.enabled)){ this.dataByValid = {...this.dataByValid,['vehicle']: true}} else { if(this.dataByValid && this.dataByValid['vehicle']) delete this.dataByValid['vehicle'] }
    this.validateAllVarDrive = 
      this.userModel?.driver?.license?.active && 
      this.userModel?.driver?.urlRecordPolicialActive && 
      this.userModel?.urlImageIdentificationActive && 
      this.userModel?.driver?.vehicles?.some( v => v.enabled)
  }

  openModal(modal: TemplateRef<any>, dataByValid?: any) {
    this.modalRef = this.modalService.show(modal);
  }
}
