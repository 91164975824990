import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { tap } from "rxjs/operators";
import Swal from "sweetalert2";
import { StateDriver, STATE_DRIVER } from "../../../constants/driver";
import { HELPS } from "../../../constants/general";
import { Driver } from "../../../interfaces/Driver";
import { Geopoint } from "../../../interfaces/geopoint";
import { License } from '../../../interfaces/license';
import { Observation } from "../../../interfaces/observation";
import { PaymentMethod } from "../../../interfaces/payment";
import { Supervisor } from "../../../interfaces/supervisor";
import { DriverModel, User, UserModel } from "../../../interfaces/user";
import { Wallet } from "../../../interfaces/wallet";
import { notifyMessage } from "../../../notifications/notifications";
import { AuthenticationService } from "../../../services/authentication.service";
import { DriverService } from "../../../services/driver.service";
import { UserService } from "../../../services/user.service";
import { getCodeSupervisor, isValid } from "../../../utils/general.utils";
import { AngularFireStorage } from "@angular/fire/storage";
import { UploadStorage } from '../../../interfaces/UploadStorage';
import { AngularFireAuth } from '@angular/fire/auth';




@Component({
  selector: "driver-edit",
  templateUrl: "./driver-edit.component.html",
})
export class DriverEditComponent implements OnInit {
  selectedFile: File = null;

  driverFormCreate: FormGroup;
  userModel: UserModel
  states: StateDriver[];
  modalRef?: BsModalRef;
  listImage: any
  staff: UserModel
  getCodeSupervisor = getCodeSupervisor
  helps = HELPS
  constructor(private router: Router, private fs: AngularFirestore, private formBuilder: FormBuilder, private driverService: DriverService,
    private userService: UserService, private modalService: BsModalService, private auth: AuthenticationService, private ds: DriverService,
    private storage: AngularFireStorage, private fsauth: AngularFireAuth
  ) {
    userService.userSelected.subscribe(user => {
      auth.staffCurrent.subscribe(staff => {
        this.staff = staff
        this.userModel = user
        this.driverFormCreate = this.formBuilder.group({
          // codeSupervisor: [ getCodeSupervisor(staff?.codeSupervisorL1, user, isValid('admin', this.staff.permissions)) || 'KX4U9920', [Validators.required]],
          codeSupervisor: [user?.codeSupervisorL1 || ''],
          state: [user?.driver?.state || '', Validators.required],
          isBanned: [user?.driver?.isBanned || false],
          active: [user?.driver?.active || false],
          // urlRecordPolicial: [user?.driver?.urlRecordPolicial || '', [Validators.required]],

        })
      })
    })
  }

  ngOnInit(): void {
    this.states = STATE_DRIVER;
  }

  async saveNewDriver() {
    if (!this.driverFormCreate.valid) return false;
    let date = new Date();
    const { isConfirmed } = await Swal.fire({
      title: '¿Estás seguro de habilitar al cliente como conductor?',
      text: "No podrás revertir esta acción.!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro!',
      cancelButtonText: 'Cancelar'
    })
    if (!isConfirmed) return false;

    const data: Partial<DriverModel> = {
      active: this.driverFormCreate.get('active').value,
      state: 'pending',
      isBanned: this.driverFormCreate.get('isBanned').value,
      updatedAt: date,
      // urlRecordPolicial: this.driverFormCreate.get('urlRecordPolicial').value,
      createdAt: this.userModel?.driver?.createdAt || date,
      vehicles: this.userModel?.driver?.vehicles || [],
      codeSupervisor: this.driverFormCreate.get('codeSupervisor').value
    }

    let permissions = {}
    for (const p of this.userModel?.permissions) { permissions = { ...permissions, [p]: p } }
    permissions = { ...permissions, ['driver']: 'driver' }

    const userData: Partial<UserModel> = {
      driver: data,
      updatedAt: data.updatedAt,
      uid: this.userModel.uid,
      permissions: Object.values(permissions)
    }
    const { accepted, error } = await this.userService.userUpdateV2(userData);
    console.log('error: ', error);
    if (accepted) {
      notifyMessage('success', 'Conductor creado.', 'top-right')
    } else {
      notifyMessage('error', 'Error mientras se creaba datos del conductor.', 'top-right')
    }
  }

  async updateDriver() {
    if (!this.driverFormCreate.dirty && !this.driverFormCreate.valid) return false;
    const { isConfirmed } = await Swal.fire({
      title: '¿ Estás seguro de actualizar información del conductor?',
      text: "No podrás revertir esta acción.!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro!',
      cancelButtonText: 'Cancelar'
    })
    if (!isConfirmed) return false;
    const date = new Date()
    const data: Partial<DriverModel> = {
      active: this.driverFormCreate.get('active').value,
      // state: this.driverFormCreate.get('state').value,
      isBanned: this.driverFormCreate.get('isBanned').value,
      updatedAt: date,
      // urlRecordPolicial: this.driverFormCreate.get('urlRecordPolicial').value,
      createdAt: this.userModel?.driver?.createdAt || date,
      vehicles: this.userModel?.driver?.vehicles || []
    }
    const userData: Partial<UserModel> = {
      driver: data,
      updatedAt: data.updatedAt,
      uid: this.userModel.uid
    }
    const { accepted, error } = await this.userService.userUpdateV2(userData);
    console.log('error: ', error);
    if (accepted) {
      notifyMessage('success', 'Conductor actualizado.', 'top-right')
    } else {
      notifyMessage('error', 'Error mientras se actualizaba datos del conductor.', 'top-right')
    }
  }

  openModal(modal: TemplateRef<any>, images?: any) {
    if (images) this.listImage = [{ urlImage: images }]
    this.modalRef = this.modalService.show(modal);
  }

  async validateLicense(license: License, active: boolean) {
    this.userModel.driver.license.active = active
    const userData: Partial<UserModel> = {
      driver: this.userModel.driver,
      updatedAt: this.userModel.driver?.updatedAt || new Date(),
      uid: this.userModel.uid
    }
    const { accepted, error } = await this.userService.userUpdateV2(userData);
    if (accepted) {
      notifyMessage('success', `${active ? 'Licencia aprobada' : 'Licencia no aprobada'}`, 'top-right');
    } else {
      notifyMessage('error', 'No se pudo actualizar', 'top-right');
    }
  }
  async validateRecordPolicial(active: boolean) {
    this.userModel.driver.urlRecordPolicialActive = active
    const userData: Partial<UserModel> = {
      driver: this.userModel.driver,
      updatedAt: this.userModel.driver?.updatedAt || new Date(),
      uid: this.userModel.uid
    }
    const { accepted, error } = await this.userService.userUpdateV2(userData);
    if (accepted) {
      notifyMessage('success', `${active ? 'Record Policial aprobada' : 'Record Policial no aprobada'}`, 'top-right');
    } else {
      notifyMessage('error', 'No se pudo actualizar', 'top-right');
    }
  }

  async validateIdentification(urlImageIdentificationActive: boolean) {
    const userData: Partial<UserModel> = {
      urlImageIdentificationActive,
      updatedAt: this.userModel.driver?.updatedAt || new Date(),
      uid: this.userModel.uid
    }
    const { accepted, error } = await this.userService.userUpdateV2(userData);
    if (accepted) {
      notifyMessage('success', `${urlImageIdentificationActive ? 'Identificación aprobada' : 'Identificación no aprobada'}`, 'top-right');
    } else {
      notifyMessage('error', 'No se pudo actualizar', 'top-right');
    }

  }

  async changeActive(type: string, valid?: boolean) {
    const isBanned = this.driverFormCreate.get('isBanned').value
    this.userModel.driver.isBanned = !isBanned
    this.userModel.driver.active = isBanned
    const date = new Date()
    this.userModel.driver.updatedAt = date
    const userData: Partial<UserModel> = {
      driver: this.userModel.driver,
      updatedAt: date,
      uid: this.userModel.uid
    }
    const { accepted, error } = await this.userService.userUpdateV2(userData);
    if (accepted) {
      this.driverFormCreate.get('active').setValue(isBanned)
      notifyMessage('success', `Conductor ${isBanned ? 'desbloqueado' : 'bloqueado'}`, 'top-right')
    } else {
      notifyMessage('error', 'Error en actualizar conductor.', 'top-right')
    }
  }
  async changeActive2() {
    const active = !this.driverFormCreate.get('active').value
    const date = new Date()
    this.userModel.driver.active = active
    this.userModel.driver.updatedAt = date
    const userData: Partial<UserModel> = {
      driver: this.userModel.driver,
      updatedAt: date,
      uid: this.userModel.uid
    }
    const { accepted, error } = await this.userService.userUpdateV2(userData);
    if (accepted) {
      notifyMessage('success', `Conductor ${active ? 'activado' : 'desactivado'}`, 'top-right')
    } else {
      notifyMessage('error', 'Error en actualizar conductor.', 'top-right')
    }
  }


  async uploadImagelicenseAdverse(event: Event, usermodel: UserModel) {
    if (confirm("confirma que desea subir el adverso de la licencia")) {
      const hi = event.target as HTMLInputElement



      const uid = usermodel.uid

      const url = await this.userUploadCloudStorage(uid, hi.files[0], 'license');



      if (usermodel.driver.license != null) {

        usermodel.driver.license.urlImage = url;
      } else {
        const license: License = {
          active: false,
          createdAt: new Date(),
          urlImage: url,
          
        }
        usermodel.driver.license = license;
      }




      /*  usermodel.driver.urlRecordPolicialActive = true; */
      const userData: Partial<UserModel> = {
        driver: usermodel.driver,

        uid: usermodel.uid
      }
      const { accepted, error } = await this.userService.userUpdateV2(userData);
      if (accepted) {
        alert('cambio realizado');
      } else {
        alert(`error: ${JSON.stringify(error)}`);
      }



    }


  }
  async uploadImagelicenseReverse(event: Event, usermodel: UserModel) {
    if (confirm("confirma que desea subir el reverso de la licencia")) {


      const hi = event.target as HTMLInputElement

      const uid = usermodel.uid

      const url = await this.userUploadCloudStorage(uid, hi.files[0], 'recordpolice');

      if (usermodel.driver.license != null) {

        usermodel.driver.license.urlImagereverse = url;
      } else {
        const license: License = {
          active: false,
          createdAt:new Date(),
          urlImagereverse: url,
          


        }
        usermodel.driver.license = license;
      }

     /*  usermodel.driver.license.urlImagereverse = url; */

      /*  usermodel.driver.urlRecordPolicialActive = true; */
      const userData: Partial<UserModel> = {
        driver: usermodel.driver,
        uid: usermodel.uid
      }
      const { accepted, error } = await this.userService.userUpdateV2(userData);
      if (accepted) {
        alert('cambio realizado');
      } else {
        alert(`error: ${JSON.stringify(error)}`);
      }
    }

  }
  async uploadImageidentificationAdverse(event: Event, usermodel: UserModel): Promise<void> {
    if (confirm("confirma que desea subir el adverse de la identificacion")) {

      const hi = event.target as HTMLInputElement

      const uid = usermodel.uid

      const url = await this.userUploadCloudStorage(uid, hi.files[0], 'recordpolice');


      /*  usermodel.driver.urlRecordPolicialActive = true; */
      const userData: Partial<UserModel> = {
        urlImageIdentification: url,
        uid: usermodel.uid
      }
      const { accepted, error } = await this.userService.userUpdateV2(userData);
      if (accepted) {
        alert('cambio realizado');
      } else {
        alert(`error: ${JSON.stringify(error)}`);
      }

    }


  }
  async uploadImageIdentificationReverse(event: Event, usermodel: UserModel) {
    if (confirm("confirma que desea subir el reverso de la identificacion")) {
      const hi = event.target as HTMLInputElement

      const uid = usermodel.uid

      const url = await this.userUploadCloudStorage(uid, hi.files[0], 'recordpolice');


      /*  usermodel.driver.urlRecordPolicialActive = true; */
      const userData: Partial<UserModel> = {
        urlImageIdentificationreverse: url,
        uid: usermodel.uid
      }
      const { accepted, error } = await this.userService.userUpdateV2(userData);
      if (accepted) {
        alert('cambio realizado');
      } else {
        alert(`error: ${JSON.stringify(error)}`);
      }
    }
  }
  async uploadImageRecordPolicial(event: Event, usermodel: UserModel) {


    if (confirm("confirma que desea subir el Record Policial")) {

      const hi = event.target as HTMLInputElement



      const uid = usermodel.uid

      const url = await this.userUploadCloudStorage(uid, hi.files[0], 'recordpolice');


      const date = new Date()

      usermodel.driver.updatedAt = date;
      usermodel.driver.urlRecordPolicial = url;
      usermodel.driver.urlRecordPolicialActive = true;

      /*  usermodel.driver.urlRecordPolicialActive = true; */
      const userData: Partial<UserModel> = {
        driver: usermodel.driver,
        updatedAt: date,
        uid: usermodel.uid

      }
      const { accepted, error } = await this.userService.userUpdateV2(userData);
      if (accepted) {
        alert('cambio realizado');
      } else {
        alert(`error: ${JSON.stringify(error)}`);
      }






    }
  }






  async userUploadCloudStorage(uid: string, file: File, directory: string): Promise<string> {

    try {

      const fileString = file.name;
      const extension = fileString.substring(
        fileString.lastIndexOf(".") + 1,
        fileString.length
      );
      const date = Date.now();
      const filename = `${uid}_${date}_${directory}.${extension}`.trim();
      const urlStorage = `users/${uid}/${directory}/${filename}`;
      const ref = this.storage.ref(urlStorage);
      const task = await ref.put(file).snapshotChanges().toPromise()
      const url = await ref.getDownloadURL().toPromise();
      /*  alert(`url: ${url}`);
  */
      return url;


    } catch (error) {
      alert(`hubo un error ${JSON.stringify(error)}`);
    }

  }
  isValid(permission: string, permissions: Array<string>) { return isValid(permission, permissions) }

  get state() { return this.driverFormCreate.get('state') }
  get active() { return this.driverFormCreate.get('active') }
  get isBanned() { return this.driverFormCreate.get('isBanned') }
  get address() { return this.driverFormCreate.get('address') }



  
}
