import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalEditFormComponent } from './personal-edit-form/personal-edit-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageDragAndDrogComponent } from './image-drag-and-drog/image-drag-and-drog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ObservationEditFormComponent } from './observation-edit-form/observation-edit-form.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalImageComponent } from './modal-image/modal-image.component';
import { ModalLicenseComponent } from './modal-license/modal-license.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { ModalImageTypeVehicleComponent } from './modal-image-type-vehicle/modal-image-type-vehicle.component';
import { DriverEditComponent } from '../driver/driver-edit/driver-edit.component';
import { VehiclesTableIndexComponent } from '../driver/index/vehicle-table-index/vehicle-table-index.component';
import { DriverVehicleTable } from '../driver/vehicle-table/vehicle-table.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NotifyValidDriverComponent } from './notify-valid-driver/notify-valid-driver.component';
import { OrderConsumptionDetailComponent } from './order-consumption-detail/order-consumption-detail.component';
import { AlertModule } from 'ngx-bootstrap/alert';

var components = [
  PersonalEditFormComponent,
  ImageDragAndDrogComponent,
  ObservationEditFormComponent,
  ModalImageComponent,
  ModalLicenseComponent,
  PermissionsComponent,
  ModalImageTypeVehicleComponent,
  DriverEditComponent,
  VehiclesTableIndexComponent,
  DriverVehicleTable,
  NotifyValidDriverComponent
]

@NgModule({
  declarations: [components, OrderConsumptionDetailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    TabsModule,
    TooltipModule,
    AlertModule.forRoot(),
  ],
  exports: [components]
})
export class SharedModule { }
