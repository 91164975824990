import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardIndexComponent } from './dashboard-index/index.component';
import { DashboardRoutingModule } from './user-routing.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DashboardStaffComponent } from './dashboard-staff/dashboard-staff.component';
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
import { DashboardDigitizerComponent } from './dashboard-digitizer/dashboard-digitizer.component';
import { DashboardSupervisorComponent } from './dashboard-supervisor/dashboard-supervisor.component';


@NgModule({
  declarations: [
    DashboardIndexComponent,
    DashboardStaffComponent,
    DashboardAdminComponent,
    DashboardDigitizerComponent,
    DashboardSupervisorComponent
  ],
  imports: [
    CommonModule,
    BsDropdownModule,
    TabsModule,
    DashboardRoutingModule
  ],
  exports:[
    DashboardIndexComponent
  ]
})
export class DashboardModule { }
