import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { NavigationIndexComponent } from './modules/navigation/navigation-index/index.component';
import { canActivate } from '@angular/fire/auth-guard';
import { redirectUnauthorizedToLogin, redirectLoggedAdmin } from './guards/authenticated';
import { Notfound404Component } from './components/notfound404/notfound404.component';
import { LoginComponent } from './modules/authentication/login/login.component';
import { PasswordResetComponent } from './modules/authentication/password-reset/password-reset.component';
import { PasswordResetConfirmComponent } from './modules/authentication/password-reset-confirm/password-reset-confirm.component';
import { PasswordResetConfirmMessageComponent } from './modules/authentication/password-reset-confirm-message/password-reset-confirm-message.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    ...canActivate(redirectLoggedAdmin),
  },
  {
    path: 'reestablecer-clave',
    component: PasswordResetComponent,
    ...canActivate(redirectLoggedAdmin),
  },
  {
    path: 'reestablecer-clave-confirmar',
    component: PasswordResetConfirmComponent,
  },
  {
    path: 'reestablecer-clave-confirmado',
    component: PasswordResetConfirmMessageComponent,
  },
  {
    path: '', component: NavigationIndexComponent,
    canActivate: [ AuthGuard ],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { roles: ['supervisorL1', 'supervisorL2','admin','staff','counter','digitizers'] },
        canActivate: [AdminGuard]
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
        data: { roles: ['supervisorL1', 'supervisorL2','admin'] },
        canActivate: [AdminGuard]
      },
      // {
      //   path: 'personal',
      //   loadChildren: () => import('./modules/staff/staff.module').then(m => m.StaffModule),
      //   data: { roles: ['staff', 'admin'] },
      //   canActivate: [AdminGuard]
      // },
      {
        path: 'drivers',
        loadChildren: () => import('./modules/driver/driver.module').then(m => m.DriverModule),
        data: { roles: ['staff', 'admin'] },
        canActivate: [AdminGuard]
      },
      {
        path: 'supervisores',
        loadChildren: () => import('./modules/supervisor/supervisor.modules').then(m => m.SupervisorModule),
        ...canActivate(redirectUnauthorizedToLogin),
      },
      {
        path: 'pagos',
        loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule),
        data: { roles: ['supervisorL1', 'supervisorL2', 'admin','digitizers'] },
        canActivate: [AdminGuard]
      },
      {
        path: 'transacciones',
        loadChildren: () => import('./modules/transaction/transaction.module').then(m => m.TransactionModule),
        data: { roles: ['supervisorL1', 'supervisorL2', 'admin'] },
        canActivate: [AdminGuard]
      },
      {
        path: 'ordenes',
        loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule),
        data: { roles: ['supervisorL1', 'supervisorL2', 'admin'] },
        canActivate: [AdminGuard]
      },
      {
        path: 'finance',
        loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule),
        data: { roles: ['admin','counter'] },
        canActivate: [AdminGuard]
      },
      {
        path: 'cuenta',
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
        ...canActivate(redirectUnauthorizedToLogin),
      },
      {
        path: 'sistema',
        loadChildren: () => import('./modules/system/system.module').then(m => m.SystemModule),
        data: { roles: ['admin'] },
        canActivate: [AdminGuard]
      },
    ]
  },
  { path: '**', component: Notfound404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
