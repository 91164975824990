import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'users-today',
  templateUrl: './users-today.component.html',
})
export class UsersTodayComponent implements OnInit {

  @Input() name: string;
  @Input() lastname: string;
  @Input() timestamp: number;
  @Input() active: number;
  constructor() { }

  ngOnInit(): void {
  }

}
