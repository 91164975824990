import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './user-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { UserIndexComponent } from './user-index/user-index.component';
import { UserTableComponent } from './user-table/user-table.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { ComponentsModule } from '../../components/components.module';
import { UserSearchComponent } from './user-search/user-search.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { DriverModule } from '../driver/driver.module';
import { SharedModule } from '../shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../environments/environment';
import { VehiclesTableIndexComponent } from '../driver/index/vehicle-table-index/vehicle-table-index.component';
import { DriverEditComponent } from '../driver/driver-edit/driver-edit.component';
import { DriverTableComponent } from '../driver/driver-table/driver-table.component';
import { VehicleCreateComponent } from '../driver/vehicle-create/vehicle-create.component';
import { DriverVehicleTable } from '../driver/vehicle-table/vehicle-table.component';
import { SupervisorEditComponent } from '../supervisor/supervisor-edit/supervisor-edit.component';

const components = [
  UserIndexComponent,
  UserSearchComponent,
  UserTableComponent,
  UserCreateComponent,
  UserEditComponent,
  UserDetailComponent,
  DriverTableComponent, 
  // DriverEditComponent,
  VehicleCreateComponent, 
  // DriverVehicleTable, 
  // VehiclesTableIndexComponent,
  SupervisorEditComponent
]

@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    ComponentsModule,
    ModalModule,
    BsDropdownModule,
    ProgressbarModule,
    TabsModule,
    BsDropdownModule,
    UsersRoutingModule,
    AgmCoreModule,
  ],
  exports: [components],
})
export class UserModule { }
