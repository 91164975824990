import { Route } from "@angular/compiler/src/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { format, register } from "timeago.js";
import { Order, OrderModel } from "../../../../interfaces/order";
import { ImageStorageService } from "../../../../services/image-storage.service";
import { OrderService } from "../../../../services/order.service";
import { localeFunctionTimeAgo } from "../../../validators/translation_ago";
import firebase from 'firebase'
import { AGM_CONFIG } from "../../../../constants/agm"
import { objToArray } from "../../../../utils/general.utils";
// import { AgmMap, MapsAPILoader, AgmCircle } from '@agm/core';
@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
})
export class OrderDetailComponent implements OnInit {

  uid: string;
  origin: number;
  destination: number;
  userOrderTimeDuration: number;
  orderDetail$: Observable<OrderModel>;
  userOrderLastSession: string;
  userOrderAvatar: string;
  auctioners: number;
  originGeoPoint: any
  destinationGeoPoint: any

  AGM_CONFIG = AGM_CONFIG
  start_end_mark = [];
  latLgn = [];

  objToArray = objToArray

  constructor(
    private storage: ImageStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
    register("SPANISH", localeFunctionTimeAgo);
    this.checkUid();
    this.orderObtainDetail();
  }

  orderObtainDetail() {
    this.orderService.orderDetail(this.uid);
    this.orderDetail$ = this.orderService.doGetOrderId(this.uid)
    this.orderDetail$.subscribe((order: OrderModel) => {
      Object.values(order.trips).map(s => this.latLgn.push([s.geopoint.latitude, s.geopoint.longitude]))
    })
    this.orderDetailObtainOriginDestination()
  }

  orderUserAuctioner(auctioners) {
    this.auctioners = Object.keys(auctioners).length
  }

  userOrderMetaData(route) {
    const routeKey = Object.keys(route)[0];
    const routeIndex = Object.entries(route[routeKey]).sort();
    const firstRouteMap = routeIndex[0][1]["timestamp"];
    const lastRouteMap = routeIndex[routeIndex.length - 1][1]["timestamp"];
    this.origin = firstRouteMap;
    this.destination = lastRouteMap;
    this.userOrderLastSession = format(lastRouteMap, "SPANISH");

    //Obtain time in minutes between the first route and last.
    var diff = (firstRouteMap - lastRouteMap) / 1000 / 60;
    this.userOrderTimeDuration = Math.abs(Math.round(diff));
  }

  async userAvatarObtainUrl(image: Object) {
    const keys = Object.keys(image);
    for (let i = 0; i < keys.length; i++) {
      if (image[keys[i]].active != false) {
        const publicImage = await this.storage.imageDownloadUrl(
          image[keys[i]].urlImage
        );
        this.userOrderAvatar = publicImage;
      }
    }
  }

  orderDetailObtainOriginDestination() {
    this.orderDetail$.subscribe((order: OrderModel) => {
      this.originGeoPoint = Object.values(order.trips)[0].geopoint
      this.destinationGeoPoint = Object.values(order.trips)[0].geopoint
    })
  }

  checkUid() {
    this.uid = this.route.snapshot.params["uid"];
    if (!this.uid) this.router.navigate(["/ordenes"]);
  }
}
