import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-notify-valid-driver',
  templateUrl: './notify-valid-driver.component.html',
  styleUrls: ['./notify-valid-driver.component.scss']
})
export class NotifyValidDriverComponent implements OnInit {

  @Input() dataByValid: {}
  @Input() modalRef: BsModalRef;
  constructor() { }

  ngOnInit(): void {
  }

}
