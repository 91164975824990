import { Component, Input, OnInit } from '@angular/core';
import { Geopoint } from '../../../interfaces/geopoint';

@Component({
  selector: 'maps-polynine',
  templateUrl: './polynine.component.html',
})
export class PolynineComponent implements OnInit {

  @Input() polynine: Geopoint[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
