export const convertCode = (num:number, base:number)=>{
  let values=[]
  let arr=["0","1","2","3","4","5","6","7","8","9",
           "A","B","C","D","E","F","G","H","I","J",
           "K","L","M","N","O","P","Q","R","S",
           "T","U","V","W","X","Y","Z"]

  while(true){
      values.push(num%base)
      num = Math.floor(num/base)
      if(num<base){
          values.push(num)
          break;
      }
  }
  values.reverse()
  let code = values.map(element => {
      return arr[element]
  })
  return code.join('').toLowerCase().substring(0);
}
export const  generateRandomString = (num) => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1= '';
    const charactersLength = characters.length;
    for ( let i = 0; i < num; i++ ) { result1 += characters.charAt(Math.floor(Math.random() * charactersLength)); }
    return result1.toLowerCase();
}
