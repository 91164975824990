import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AuthenticationRoutingModule } from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ComponentsModule } from '../../components/components.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PasswordResetConfirmComponent } from './password-reset-confirm/password-reset-confirm.component';
import { PasswordResetConfirmMessageComponent } from './password-reset-confirm-message/password-reset-confirm-message.component';
import { CheckAccountConfirmComponent } from './check-account-confirm/check-account-confirm.component';

const components = [LoginComponent, CheckAccountConfirmComponent, PasswordResetComponent, PasswordResetConfirmComponent, PasswordResetConfirmMessageComponent];
@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    AuthenticationRoutingModule,
    AlertModule
  ],
  exports: [components]
})
export class AuthenticationModule { }
