import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UpdateThumbnailComponent } from '../../../components/update-thumbnail/update-thumbnail.component';
import { PERMISSION } from '../../../constants/permission';
import { TYPE_FILTER, USER_FILTER_TYPE } from '../../../constants/user';
import { User, UserModel } from '../../../interfaces/user';
import { notifyMessage } from '../../../notifications/notifications';
import { AuthenticationService } from '../../../services/authentication.service';
import { UserService } from '../../../services/user.service';
import { isValid } from '../../../utils/general.utils';
import { UserCreateComponent } from '../user-create/user-create.component';
import { UserEditComponent } from '../user-edit/user-edit.component';

@Component({
  selector: 'user-user-table',
  templateUrl: './user-table.component.html',
})
export class UserTableComponent implements OnInit {

  users$: Observable<UserModel[]>

  isSortingAscendent: boolean = true;
  staff: UserModel;
  stateUser: BehaviorSubject<string | null>;
  search$: BehaviorSubject<string | null>;
  typeFilter$: BehaviorSubject<string | null>;
  constructor( public userService: UserService, private modalService: BsModalService, private auth: AuthenticationService,
    private afs: AngularFirestore) {
    this.stateUser = new BehaviorSubject(null);
    this.search$ = new BehaviorSubject(null);
    this.typeFilter$ = new BehaviorSubject(null);
      this.auth.staffCurrent.subscribe(staff => {
          this.staff = staff
          this.users$ = combineLatest(
            [
              this.stateUser,
              this.search$,
              this.typeFilter$
            ]
          ).pipe(
              switchMap(([state, search, typeFilter]) =>
                this.afs.collection<UserModel>('users', ref => {
                  if (state) {
                    if (state == 'all') {
                      return ref.where('codeSupervisor', 'array-contains-any', [staff?.supervisorL1?.code]).limit(200).orderBy('createdAt', 'desc')
                    } else {
                      // return ref.where('state', '==', state).orderBy('createdAt', 'desc').limit(200)
                    }
                  } else if (typeFilter){
                    return ref.orderBy(TYPE_FILTER[typeFilter].index).startAt(search).endAt(search + '\uf8ff')
                  } else {
                    if(staff.permissions.some(p => p === PERMISSION.admin.index)){
                      return ref.limit(200).orderBy('createdAt', 'desc')
                    } else if(staff.permissions.some(p => p === PERMISSION.supervisorL1.index)){
                      return ref.where('codeSupervisor','array-contains-any', [staff?.supervisorL1?.code || '']).limit(200).orderBy('createdAt', 'desc')
                    } else if(staff.permissions.some(p => p === PERMISSION.supervisorL2.index)){
                      return ref.where('codeSupervisor','array-contains-any', [staff?.supervisorL2?.code || '']).limit(200).orderBy('createdAt', 'desc')
                    } else if(staff.permissions.some(p => p === PERMISSION.staff.index)){
                    }
                  }
                }).valueChanges())
            )
        
        })

     }

  ngOnInit() {
  }


  // Methods modals
  //Open modals with data.
  bsModalRef: BsModalRef;

  userOpenModaUpdateProfile(user: User) {
    const initialState = { user: user };
    this.bsModalRef = this.modalService.show(UserEditComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      initialState
    });
  }

  sortUser(param: string) {
    this.isSortingAscendent = !this.isSortingAscendent;
    if (!this.isSortingAscendent) {
      this.users$ = this.users$.pipe(
        map(value => value.sort((a, b) => a[param] < b[param] ? -1 : 0)))
    } else {
      this.users$ = this.users$.pipe(
        map(value => value.sort((a, b) => a[param] > b[param] ? -1 : 0)))
    }
  }

  userOpenModalUpdateAvatar(user: User) {
    const initialState = {
      thumbnails: user.urlImage,
      uid: user.uid,
    }
    this.bsModalRef = this.modalService.show(UpdateThumbnailComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      initialState
    });
  }

  userOpenModalCreate() {
    // this.modalService.show(UserCreateComponent);
  }

  async userOpenModalDelete(user: UserModel) {
    const { isConfirmed } = await Swal.fire({
      title: `¿Estás seguro de ${user?.active ? 'deshabilitar' : 'habilitar' } usuario?`,
      text: `Ésta acción ${user?.active ? 'no' : '' } le permitirá al usuario ${user.name} ${user.lastName} el acceso a la plataforma.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    })
    if (!isConfirmed) return false;
    const userData: Partial<UserModel> = {
      updatedAt: new Date(),
      uid: user.uid,
      active: !user?.active
    }
    const { accepted, error } = await this.userService.userUpdateV2(userData);
    if (!accepted) return false;
    notifyMessage('success', `Cuenta ${user?.active ? 'deshabilitada' : 'habilitada' }.`, 'top-right');
  }

  // Login search
  // Input event changes search field.
  filters = USER_FILTER_TYPE;

  search = new FormControl('');
  typeFilter = new FormControl(0);

  changeQueryFilter(e) { this.typeFilter$.next(e) }

  getVehicles(user: UserModel){ return user?.driver && user?.driver?.vehicles.length ? user?.driver?.vehicles?.slice(0, 2) : [] }

  isValid(permission: string, permissions: Array<string>){ return isValid(permission, permissions) }

  filterBySearch() {
    if (String(this.search.value).length >= 3) {
      this.search$.next(this.search.value);
    } else {
      this.search$.next(null);
    }

  }
}
