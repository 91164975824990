import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserDetailComponent } from '../user/user-detail/user-detail.component';
import { DriverTableComponent } from './driver-table/driver-table.component';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Conductores' },
    children: [
      {
        path: '',
        data: { title: 'Conductores' },
        component: DriverTableComponent,
      },
      {
        path: 'detalle/:uid',
        data: { title: 'Detalle driver' },
        component: UserDetailComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DriverRoutingModule { }
