import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SupervisorDetailIndex } from './detail/supervisor-detail-index/supervisor-table-index.component';

const routes: Routes = [
  {
    path: '',
    children :[
      {
        path: 'detalle/:uid',
        component: SupervisorDetailIndex
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupervisorRoutingModule { }
