import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { STATE_DRIVER_ } from '../../../constants/driver';
import { PERMISSION } from '../../../constants/permission';
import { Driver } from '../../../interfaces/Driver';
import { UserModel } from '../../../interfaces/user';
import { objToArray } from '../../../utils/general.utils';
import { DriverCreateComponent } from '../driver-create/driver-create.component';

@Component({
  selector: 'app-driver-table',
  templateUrl: './driver-table.component.html',
  styleUrls: ['./driver-table.component.scss']
})
export class DriverTableComponent implements OnInit {

  modalRef?: BsModalRef
  drivers$: Observable<Driver[]>
  stateDriverFilter$: BehaviorSubject<string | null>;

  nombreForm: FormGroup
  dniFilter$: BehaviorSubject<string | null>;
  nombreEmail$: BehaviorSubject<string | null>;

  states = STATE_DRIVER_
  objToArray = objToArray
  constructor(private modalService: BsModalService, private afs: AngularFirestore, private formBuilder: FormBuilder) {
    this.initForm()
    this.stateDriverFilter$ = new BehaviorSubject(null);
    this.dniFilter$ = new BehaviorSubject(null);
    this.nombreEmail$ = new BehaviorSubject(null);
    this.drivers$ = combineLatest(
      [
        this.stateDriverFilter$,
        this.dniFilter$,
        this.nombreEmail$,
      ]
    ).pipe(
        switchMap(([state, dni, email]) =>
          this.afs.collection<Driver>('users', ref => {
            if (state) {
              if (state == 'all') {
                return ref.where('driver.state', 'in', [STATE_DRIVER_.pending.index]).limit(200).orderBy('driver.createdAt', 'desc')
              } else {
                return ref.where('driver.state', '==', state).orderBy('driver.createdAt', 'desc').limit(200)
              }
            } else if (dni) {
              return ref.where('driver.state', 'in',[STATE_DRIVER_.pending.index, STATE_DRIVER_.canceled.index, STATE_DRIVER_.aprobated.index]).orderBy('identification').startAt(dni).endAt(dni + '\uf8ff')
            } else if (email) {
              return ref.where('driver.state', 'in',[STATE_DRIVER_.pending.index, STATE_DRIVER_.canceled.index, STATE_DRIVER_.aprobated.index]).orderBy('email').startAt(email).endAt(email + '\uf8ff')
            } 
            else {
              return ref.where('driver.state', 'in', [STATE_DRIVER_.pending.index]).limit(200).orderBy('driver.createdAt', 'desc')
            }
          }).valueChanges())
      )
   }

  ngOnInit(): void {
  }

  /* Search Form */
  initForm(): FormGroup {
    return this.nombreForm = this.formBuilder.group({
      dni: '',
      email: ''
    })
  }

  driverAdd() {
    this.modalService.show(DriverCreateComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
    })
  }

  filterByNombre() {
    this.nombreForm.get('email').setValue('')

    if (String(this.nombreForm.value.dni).length >= 3) {
      this.dniFilter$.next(this.nombreForm.value.dni);
    } else if (String(this.nombreForm.value.dni).length == 1) {

    } else if (String(this.nombreForm.value.dni).length == 2) {

    } else {
      this.nombreEmail$.next(null);
      this.dniFilter$.next(null);
    }

  }

  filterByEmail() {
    this.nombreForm.get('dni').setValue('')

    if (String(this.nombreForm.value.email).length >= 6) {
      this.nombreEmail$.next(this.nombreForm.value.email);
    } else if (String(this.nombreForm.value.email).length == 1) {

    } else if (String(this.nombreForm.value.email).length == 2) {

    } else if (String(this.nombreForm.value.email).length == 3) {

    } else if (String(this.nombreForm.value.email).length == 4) {

    } else if (String(this.nombreForm.value.email).length == 5) {

    } else {
      this.nombreEmail$.next(null);
      this.dniFilter$.next(null);
    }

  }

  filterStatusDriver(status: string) { this.stateDriverFilter$.next(status) }
  getVehicles(user: UserModel){ return user?.driver && user?.driver?.vehicles.length ? user?.driver?.vehicles?.slice(0, 2) : [] }

}
