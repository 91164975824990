import { ORDER_STATUS_ } from "../constants/order";
import { STATUS_PAYMENTS } from "../constants/payment_types";
import { Auctioneer } from "../interfaces/auctioneer";
import { OrderPayment, ProofPayment } from "../interfaces/proof-payment";
import { UserModel } from "../interfaces/user";
import { Vehicle } from "../interfaces/vehicle";

export const getBalanceDriver = (proofPay: ProofPayment) => {
  const residue = proofPay && proofPay?.residue?.idProofPayment ? proofPay?.residue?.residue : 0
  const bonus = proofPay?.bonus ? proofPay?.bonus?.amount : 0
  const reduce = Object.values(proofPay?.orders || []).reduce((acc: number, order: OrderPayment) => acc + (order.amount * (order.driver?.vehicle?.percentage?.percentage/100)) || 0, 0)
  return proofPay?.orders ? (proofPay?.amount - reduce) + residue + bonus : proofPay?.amount + residue + bonus
}

export const getBalanceConsumedDriver = (proofPay: ProofPayment) => {
  const residue = proofPay && proofPay?.residue?.idProofPayment ? proofPay?.residue?.residue : 0
  const bonus = proofPay?.bonus ? proofPay?.bonus?.amount : 0
  const reduce = Object.values(proofPay?.orders || []).reduce((acc: number, order: OrderPayment) => acc + (order.amount * (order.driver?.vehicle?.percentage?.percentage/100)) || 0, 0)
  return proofPay?.orders ? reduce : 0
}

export const validateAmount = (amountInput: number, balance: number, vehicle: Vehicle) => {
  return (amountInput * (vehicle?.percentage?.percentage / 100 )) < balance
}

export const getAmountPercenge = (amountInput: number, balance: number, vehicle: Vehicle) => {
  return (amountInput * (vehicle?.percentage?.percentage / 100 ))
}

export const isValid = (permission: string, permissions: Array<string>) => { return permissions.includes(permission)}

export const getCodeSupervisor = (code: string, userModel: UserModel, isAdmin: boolean) => {
  const index = userModel?.codeSupervisor?.findIndex(c => c === code)
  if(isAdmin) return userModel?.supervisorL1?.code
  return index !== undefined ? userModel?.codeSupervisor[index] : ''
}

export const getOrderStatus = (status: string) => {
  switch (status) {
    case ORDER_STATUS_.canceled.index:
      return {
        status: ORDER_STATUS_.canceled.value,
        color: 'danger'
      }
    case ORDER_STATUS_.created.index:
      return {
        status: ORDER_STATUS_.created.value,
        color: 'primary'
      }
    case ORDER_STATUS_.bidding.index:
      return {
        status: ORDER_STATUS_.bidding.value,
        color: 'warning'
      }
    case ORDER_STATUS_.pending.index:
      return {
        status: ORDER_STATUS_.pending.value,
        color: 'secondary'
      }
    case ORDER_STATUS_.inProcess.index:
      return {
        status: ORDER_STATUS_.inProcess.value,
        color: 'info'
      }
    case ORDER_STATUS_.done.index:
      return {
        status: ORDER_STATUS_.done.value,
        color: 'success'
      }
    case ORDER_STATUS_.alert.index:
      return {
        status: ORDER_STATUS_.alert.value,
        color: 'danger'
      }
  }
}

export const getPriceAuctioneer = (last: boolean, auctioners: Auctioneer) => {
  return last ? Object.values(auctioners)[Object.values(auctioners).length -1] : Object.values(auctioners)[0]
}

export const objToArray = (obj: object) => { return Object.values(obj) }

export const whatsApp = (number: string) => {
  if (number.indexOf("0", 0) != -1) return number.substring(1)
  return number
}

export const validateBtn = (formInvalid: boolean, statusTransf: string) => {
  if (formInvalid) {
    if(statusTransf === STATUS_PAYMENTS.rejected.index){
      return false
    } else if(statusTransf === STATUS_PAYMENTS.approved.index){
      return true
    } else {
      return true
    }
  } else {
    if(statusTransf === STATUS_PAYMENTS.rejected.index){
      return false
    } else if(statusTransf === STATUS_PAYMENTS.pendingToApprove.index){
      return true
    }
  }

}
