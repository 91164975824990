import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { notifyMessage } from '../../../notifications/notifications';
import { AuthenticationService } from '../../../services/authentication.service';
import { onlyLowerCase, notWhiteSpace } from '../../validators/users';

@Component({
  selector: 'app-account-change-password',
  templateUrl: './account-change-password.component.html',
})
export class AccountChangePasswordComponent {

  userChangePasswordForm: FormGroup;
  errors: any[] = [];
  visibilityPassword: boolean = false;
  userChangePasswordFormObs: Observable<any>;


  constructor(private router: Router,
    private userAuth: AuthenticationService,
    private formBuilder: FormBuilder) {
    this.userChangePasswordForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required, onlyLowerCase(), notWhiteSpace()]],
      newpassword: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12), onlyLowerCase(), notWhiteSpace()]],
    });
  }

  async userChangePassword() {
    const oldPassword = this.userChangePasswordForm.get('oldpassword').value;
    const newPassword = this.userChangePasswordForm.get('newpassword').value;

    const { accepted, errorMessage } = await this.userAuth.userPasswordChange(oldPassword, newPassword);
    if (!accepted) {
      this.errors.push({ message: errorMessage });
      return false;
    }
    notifyMessage('success', 'Contraseña actualizada con éxito.', 'top-right');
    this.router.navigate(['/cuenta']);
  }

  get oldPassword() { return this.userChangePasswordForm.get('oldpassword') };
  get newPassword() { return this.userChangePasswordForm.get('newpassword') };
  get errorServer() { return this.userChangePasswordForm.get('errorServer') }
}
