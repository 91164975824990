import { Component, Input, OnInit } from '@angular/core';
import { Geopoint } from '../../../interfaces/geopoint';

@Component({
  selector: 'maps-marker',
  templateUrl: './marker.component.html',
})
export class MarkerComponent implements OnInit {

  @Input() location: Geopoint;
  @Input() time: number;
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
