export interface UserFilterType {
  name: string;
  value: string;
}
export const USER_FILTER_TYPE: UserFilterType[] = [
  { name: "APELLIDOS", value: "lastname" },
  { name: "EMAIL", value: "email" },
  { name: "CÉDULA", value: "identification" },
];

export const USER_PAYMENT_TYPE = {
  driver: {index: 'driver', value: 'Conductor'},
  supervisor: {index: 'supervisor', value: 'Supervisor'},
  client: {index: 'client', value: 'Cliente'},
}

export const TYPE_FILTER = {
  email: {index: 'email', value: 'email'},
  identification: {index: 'identification', value: 'user.user.identification'},
  lastname: {index: 'lastName', value: 'user.user.lastname'},
}