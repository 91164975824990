import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../environments/environment';
import { MarkerComponent } from './marker/marker.component';
import { PolynineComponent } from './polynine/polynine.component';

const components = [
  MarkerComponent,
  PolynineComponent
]

@NgModule({
  declarations: [ components],
  imports: [
    AgmCoreModule.forRoot({ apiKey: environment.googleMapConfig.apiKey }),
    CommonModule
  ],
  exports:[components]
})

export class MapsModule { }
